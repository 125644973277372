<template>
  <div>

    <span v-if="details && details.Features">
      
      <component v-for="feature in details.Features" :is="feature.feature" :key="feature.feature" :feature="feature" :details="details">

      </component>
    </span>

    <v-container class="text-center">
      <div class="mt-1">
        <v-btn
          @click="SelectNode(selection.selected)"
          x-large
          class="my-1 mx-sm-1 w-full w-sm-auto primary"
          >{{ $t("BACK") }}</v-btn
        >
      </div>
    </v-container>
    <!-- <pre>{{data.TableDefinition.Columns}}</pre> -->
  </div>
</template>

<script>
import eventService from "@/services/eventService.js";
import crTools from "@/services/crTools.js";
import genericResultService from "@/services/genericResultService.js";
import appSettingsService from "@/appSettingsService";

import Splits from './Components/Splits.vue';
import Overview from './Components/Overview.vue';
import Laps from './Components/Laps.vue';

import { _ } from "vue-underscore";

export default {
  props: ["reportList", "cms", "selection", "path"],

  components: { Splits, Overview, Laps },

  data() {
    return {
      detailsLoading: false,
      loading: false,
      autoRefresh: false,
      delta: 0,
      details: null,
      highlightedSource: null,
    };
  },
  computed: {
    overview: function () {
      if (this.details == null) return null;
      if (this.details.overview == null && this.details.overview.length == 0)
        return null;
      return this.details.overview[0];
    },
  },

  watch: {
    selection: function (n, o) {
      //console.log("SELECTION CHANGED");
      this.updateAutoRefresh();
    },
  },

  beforeDestroy() {},

  mounted() 
  {
    console.log("details mounted");

    setTimeout(() => {
      this.loadDetails();      
    }, 0);

    this.updateAutoRefresh();
  },
  beforeDestroy() {
    this.stopAutoRefresh();
    clearInterval(this.timerInterval);
  },
  methods: {

    getWidth($event)
    {
      console.log("click ",$event.srcElement.offsetWidth)
    },

    ready() {},
    
    setLocale(lng) {
      this.$i18n.locale = lng;
    },

    updateAutoRefresh() {
      if (
        this.selection &&
        this.selection.selected &&
        this.selection.selected.Settings &&
        this.selection.selected.Settings.LiveDelay &&
        this.selection.selected.Settings.LiveDelay > 0
      ) {
        this.startAutoRefresh(this.selection.selected.Settings.LiveDelay);
      } else this.stopAutoRefresh();
    },

    startAutoRefresh(delay) {
      if (this.autoRefresh) return;

      console.log("AUTOREFRESH ENABLED");
      this.interval = setInterval(() => {
        this.loadDetailsInternal(false);
      }, 30000);

      this.autoRefresh = true;
    },

    stopAutoRefresh() {
      if (!this.autoRefresh) return;

      //console.log("AUTOREFRESH STOPPED");
      clearInterval(this.interval);
      this.autoRefresh = false;
    },

    formatTime(input) {
      return crTools.formatTime(input);
    },

    SelectNode(node) {
      if (!node) return;
      if (node.Route && node.Route != "") {
        var route = JSON.parse(node.Route);
        //console.log("select node route", route, node.Id);

        var params = { ...route.Params, path: node.Id };

        //console.log("route params", params);

        this.$router.push({
          name: route.Route,
          params: params,
        });
        return;
      } else if (node.Link && node.Link != "") {
        //console.log("LINK CLICKED");
        window.location = node.Link;
        return;
      } else if (node.Childs == 0) {
        // console.log("leaf node cliqued", node);
        return;
      }

      //console.log("SELECT NODE ", node.Id, node.Level, node.Folders.length);
      if (node.Level < 2 && node.Folders.length > 0)
        this.SelectNode(node.Folders[0]);
      else {
        //  console.log("LEVEL = ", node.Level);
        // console.log("NODE selection level " + this.level, node);
        setTimeout(() => this.SetPath(node.Id));
      }
    },

    //getObjectDetail(db, type, table, token) {

    loadDetailsInternal(force) {
      eventService

        .getObjectDetail(this.$route.params.db, null, "na", this.$route.params.token)
        .then((details) => {
          console.log(details);
          
          this.details = details;

          this.detailsLoading = false;

        });
    },

    loadDetails(force) {
      if (this.detailsLoading && !force) return;

      this.detailsLoading = true;
      //console.log("load details")

      this.loadDetailsInternal(force);


      /*this.loadTrackModel().then(() => {
        this.loadDetailsInternal(force);
      });*/
    },

    formatDistance(distance) {
      return crTools.formatDistance(distance);
    },

  },
};
</script>

