<template>
  <span v-if="overview">    
    
    <v-container
      class="pt-2 pb-2 results"
      style="max-width: 1150px; xbackground-color: orange"      
      v-if="false"
    >    
      <v-row dense class="pb-0">
        <v-col md="3" sm="4">
          <v-card outlined>
            <span
              style="
                position: absolute;
                width: 4px;
                display: block;
                background: green;
                height: 100%;
              "
            >
            </span>
            <v-card-text>
              <span>{{ $t("CURRENTRANKING") }}</span
              ><br />
              <span>
                <i
                  style="position: absolute; right: 8px"
                  class="fa-solid fa-list-ol fa-xl green--text"
                ></i>
                <span
                  v-if="overview.Status == 'InRace' || overview.Status == 'OK'"
                  class="subtitle-1"
                  >{{ overview.Pos > 0 ? overview.Pos : "NA" }}</span
                >
                <span v-else>-</span>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="3" sm="4" v-if="overview.Distance > 0">
          <v-card outlined>
            <span
              style="
                position: absolute;
                width: 4px;
                display: block;
                background: green;
                height: 100%;
              "
            >
            </span>
            <v-card-text>
              <span>{{
                $t(overview.Status == "InRace" ? "LASTPOINT" : "FINISHPOINT")
              }}</span
              ><br />
              <span>
                <i
                  style="position: absolute; right: 8px"
                  class="fa-solid fa-flag fa-xl green--text"
                ></i>
                <span class="subtitle-1"
                  >{{ formatDistance(overview.Distance) }} /
                  {{ formatTime(overview.Time) }}</span
                >
              </span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          md="3"
          sm="4"
          v-if="overview.Status == 'InRace' && overview.ETA > 0 && false"
        >
          <v-card outlined>
            <span
              style="
                position: absolute;
                width: 4px;
                display: block;
                background: green;
                height: 100%;
              "
            >
            </span>
            <v-card-text>
              <span>{{ $t("ETA") }}</span
              ><br />
              <span>
                <i
                  style="position: absolute; right: 8px"
                  class="fas fa-stopwatch fa-xl green--text"
                ></i>
                <span class="subtitle-1">{{ formatTime(overview.ETA) }}</span>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container
      class="pb-2 pt-2 results"
      style="max-width: 1150px; xbackground-color: orange"
    >
      <v-card class="py-2 px-0">
        <v-card-text>
          <v-row>
            <v-col cols="10">
              <div class="text-h5 font-weight-bold">
                n°{{ overview.Bib }} - {{ overview.DisplayName }}
              </div>
            </v-col>
            <v-col cols="2" class="text-right text-h5">
              <span
                :title="$t('AUTOREFRESH')"
                v-if="autoRefresh"
                class="fas fa-spinner fa-spin"
              ></span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>


  </span>

</template>

<script>

import { _ } from "vue-underscore";

import crTools from "@/services/crTools.js";

export default {
  props: ["feature","details"],
  data() {
    return {
      autoRefresh: false,

    };
  },
  methods: {    
    formatDistance(distance) {
      return crTools.formatDistance(distance);
    },

    formatTime(input) {
      return crTools.formatTime(input);
    },

    formatPos(input) {
      if (input=='-3') return '-';
      if (input=='-1') return 'DSQ';
      if (input>0) return input;
      return '-';
    },

  },
  computed: {
    overview(){
      if (!this.details || !this.details.Overview) return null;
      return  this.details.Overview[0];
    }
  },
  watch: {

  },

  beforeDestroy() {},
  updated() {},
  mounted() {
  },
};
</script>
