<template>
  <div class="pt-1" v-if="folders && folders.length > 1">
    <div      
      :class="level == 1 ? 'primary' : 'white'"
      style="
        xborder-width: 1px !important;
        xborder-color: #999999 !important;
        position: relative;
      "
    >
      <v-container
        class="pt-0 pb-0"
        style="max-width: 1150px; xbackground-color: orange"
      >
        <!-- <v-tabs>
            <v-tab :to="{ name: 'kotkhome',params: { race:k } }">{{ $t("RESULTS") }}</v-tab>
            <v-tab :to="{ name: 'map' }">{{ $t("MAP") }}</v-tab>
            <v-tab :to="{ name: 'kotkcontact' }">{{ $t("CONTACTS") }}</v-tab>
          </v-tabs> -->

        <v-tabs
          :dark="level == 1"
          show-arrows
          v-model="idx"
          :background-color="level == 1 ? 'primary' : ''"
        >
          <v-tab
            @click="SelectNode(folder)"
            v-for="folder in folders"
            :key="folder.Id"
            >{{ $tcond(folder.DisplayName) }}</v-tab
          >
        </v-tabs>

        <!-- <LeafNodes Node="cms"></LeafNodes> -->

        <!-- <v-btn @click="test('20210901_cairo')">cairo</v-btn>
          <v-btn @click="test('20220320_kotk')">kotk</v-btn> -->
      </v-container>
    </div>
  </div>
</template>

<script>

import { _ } from "vue-underscore";

export default {
  props: ["node", "selected","selection","level"],
  components: {
  },
  name: "folder",
  data() {
    return {
      idx: -1,
    };
  },
  computed: {
    folders()
    {
      if (this.node==null) return null;
      return this.node.Folders;
    },
    childs()
    {
      if (this.node==null) return null;
      return this.node.Childs;
    }    
  },
  methods: {
    updateSelectedIdx() {
      var folders = this.folders;
      if (!folders) return;
      var i = 0;
      while (i < folders.length) {
        if (this.selection[this.level] == folders[i].Id) this.idx = i;
        i++;
      }
    },
    updateView() {},
    SelectNode(n) {
      console.log("SELECT NODE IN FOLDER", n);
      console.log("EMITING");
      this.$emit("selected", n);
    },
  },
  watch: {
    node: function (o, n) {
      this.updateSelectedIdx();
    },
    selection: function (o, n) {
      this.updateSelectedIdx();
    },
  },

  beforeDestroy() {},
  updated() {},
  mounted() {
    this.updateSelectedIdx();
  },
};
</script>
