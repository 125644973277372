<template>
  <div>
    <v-main id="main" >
      <v-container style="max-width:1200px" class="results">
        <v-card>
          <v-tabs>
            <v-tab v-for="(v,k) in raceList" :key="k" :to="{ name: 'firstrowrace',params: {race:k} }">{{ v.RaceName }}</v-tab>
          </v-tabs>
          <router-view :race-list="raceList"> </router-view>
        </v-card>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import genericResultService from "@/services/genericResultService.js";

export default {
  components: {},
  data() {
    return {
      raceList:{}
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log("before route enter: ", to.params.db);
    
    genericResultService.Uci(to.params.db,'xco','firstrowriders').then(data=>{      
      console.log("data returned for first row: ",data);      

      next(vm => {
        // access to component instance via `vm`
        vm.setRaceList(data)
        console.log("next");
      });

    });

  },
  computed: {},
  mounted: function () {
    console.log("results  mounted");
  },
  methods: {
    setRaceList(raceList)
    {
      this.raceList=raceList;
    },
    ready() {
      console.log("ready");
    },
  },
};
</script>

<style scoped>
#main {
  background: no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  min-height: 100vh;
}

@media only screen and (max-width: 600px) {
  .container.results {
    padding: 5px !important;
  }
}
@media only screen and (max-width: 500px) {
  .container.results {
    padding: 0px !important;
  }
}

.event-header-img img {
  z-index: 1;
  width: 100%;
  max-height: inherit;
  transform: translateY(-33%);
}
.event-header-master {
  overflow: hidden;
  height: 200px;
}
.event-header {
  padding: 1px 40px;
}

</style>