<template>
  <div>
    <v-main id="main">
      <v-container fluid class="pa-1" v-if="config && config.Stage && context">
        <v-row xno-gutters dense justify="start">
          <v-col cols="12" sm="6" md="4" xl="3" xalign-self="center" style="flex-grow: 0;">
            <v-card xwidth="400px" class="pa-1" style="white-space-collapse: preserve;">
              <v-card-title class="pt-0 pl-1">
                <span class="fa fa-play mr-1"></span>
                <b>{{ config.Stage.StartLocation }}</b><span> - Start</span>
              </v-card-title>
              <v-card-subtitle class="pl-1"><b>{{ context.Started.length }}</b> started - <b>{{ context.ToStart.length
                  }}</b> to go
              </v-card-subtitle>
              <v-divider></v-divider>

              <v-tabs v-model="tab" bg-color="primary">
                <v-tab>Next Riders</v-tab>
                <v-tab>Last started</v-tab>
              </v-tabs>
              <div v-if="tab == 0">
                <table class="data-table mt-1" v-if="context.ToStart.length > 0">
                  <tbody class="table-data">
                    <tr v-for="rider in context.ToStart.slice(0, 14)" :key="rider.RaceNr" class="last-record-line">
                      <td class="text-right">{{ rider.SortOrder }}.</td>
                      <td class="text-bold">{{ rider.Rider.FirstName.substring(0, 1) }}. {{ rider.Rider.LastName }}</td>
                      <td class="text-center"><small>({{ rider.RaceNr }})</small></td>

                      <td class="text-center pb-0"><img
                          :src="'https://chronorace.blob.core.windows.net/webresources/img/flags/' + rider.Rider.NOC.toLowerCase() + '.png'"
                          style="height: 15px"></td>
                      <td class="text-center" :title="rider.Rider.TeamName">{{ rider.Rider.TeamCode }}</td>
                      <td class="text-right"><b>{{ formatTimeHMS(rider.StartTime) }}</b></td>
                    </tr>


                  </tbody>
                </table>
                <v-card-text v-else>All riders started</v-card-text>
              </div>
              <div v-if="tab == 1">

                <table class="data-table mt-1" v-if="context.Started.length > 0">
                  <tbody class="table-data">
                    <tr v-for="rider in context.Started.slice(0, 14)" :key="rider.RaceNr" class="last-record-line">
                      <td class="text-right">{{ rider.SortOrder }}.</td>
                      <td class="text-bold">{{ rider.Rider.FirstName.substring(0, 1) }}. {{ rider.Rider.LastName }}</td>
                      <td class="text-center"><small>({{ rider.RaceNr }})</small></td>

                      <td class="text-center pb-0"><img
                          :src="'https://chronorace.blob.core.windows.net/webresources/img/flags/' + rider.Rider.NOC.toLowerCase() + '.png'"
                          style="height: 15px"></td>
                      <td class="text-center" :title="rider.Rider.TeamName">{{ rider.Rider.TeamCode }}</td>
                      <td class="text-right"><b>{{ formatTimeHMS(rider.StartTime) }}</b></td>
                    </tr>


                  </tbody>
                </table>
                <v-card-text v-else>No rider started yet</v-card-text>

              </div>

              <v-divider></v-divider>
              <v-card-actions class="pb-0 pr-0">
                <v-spacer></v-spacer>
                <v-btn @click="dlgDialog = true" text>Show all</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" md="4" xl="3" style="flex-grow: 0;" v-for="(split, idx) in context.Splits" :key="idx">
            <clm-item :data="split"></clm-item>
          </v-col>
        </v-row>
      </v-container>



      <v-dialog v-model="dlgDialog" v-if="context && context.StartOrder" scrollable max-width="500">
        <v-card>
          <v-card-title class="headline">Start list</v-card-title>
          <v-card-text>
            <table class="data-table mt-1" v-if="context.StartOrder.length > 0">
              <tbody class="table-data">
                <tr v-for="rider in context.StartOrder" :key="rider.RaceNr" class="last-record-line">
                  <td class="text-right">{{ rider.SortOrder }}.</td>
                  <td class="text-bold">{{ rider.Rider.FirstName.substring(0, 1) }}. {{ rider.Rider.LastName }}</td>
                  <td class="text-center"><small>({{ rider.RaceNr }})</small></td>

                  <td class="text-center pb-0"><img
                      :src="'https://chronorace.blob.core.windows.net/webresources/img/flags/' + rider.Rider.NOC.toLowerCase() + '.png'"
                      style="height: 15px"></td>
                  <td class="text-center" :title="rider.Rider.TeamName">{{ rider.Rider.TeamCode }}</td>
                  <td class="text-right">
                    <span v-if="rider.Status == 'DNS'"><b>{{ rider.Status }}</b></span>
                    <b v-else>{{ formatTimeHMS(rider.StartTime) }}</b>
                  </td>
                </tr>


              </tbody>
            </table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dlgClick()">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </div>
</template>

<script>
import genericResultService from "@/services/genericResultService.js";
import crTools from "@/services/crTools.js";
import ClmItem from './components/clmItem.vue';

export default {
  components: { ClmItem },
  data() {
    return {
      config: null,
      dlgDialog: false,
      riders: {},
      context: null,
      busyCnt: 0,
      loading: false,
      tab: 0,
      refreshInterval: null
    }
  },

  beforeDestroy() {
    if (this.refreshInterval) clearInterval(this.refreshInterval)
  },

  beforeRouteEnter(to, from, next) {
    //console.log("before route enter: ", to.params.db, to.params);

    const promises = []
    let config = null
    //let ctx = null

    promises.push(genericResultService.Uci(to.params.db, 'road', 'tt_entries_' + to.params.key).then((data) => {
      config = data

    }))

    /*promises.push(genericResultService.Uci(to.params.db, 'road', 'tt_live_' + to.params.key).then((data) =>
    {
      ctx = data
    }))*/

    Promise.all(promises).then(() => {
      next(vm => {
        // access to component instance via `vm`
        vm.setConfig(config)
        //vm.setContext(ctx)
        console.log("next")

      })

    })
    /*next(vm => {
      console.log("CLM ROUTE WILL BE CALLED")
    })*/

  },

  computed: {},

  mounted: function () {
    console.log("results  mounted");

    this.refreshInterval = setInterval(() => this.loadContext(), 2000)

    this.loadContext()
  },
  methods: {
    dlgClick(btn) {
      this.dlgDialog = false
    },

    formatTimeHMS(time) {
      return crTools.formatTimeHMS(time)
    },
    loadContext() {
      if (this.loading) {

        this.busyCnt++

        return
      }

      this.loading = true
      this.busyCnt = 0

      //console.log(this.$route)

      genericResultService.Uci(this.$route.params.db, 'road', 'tt_live_' + this.$route.params.key).then((data) => {
        this.setContext(data)
        this.loading = false
      }, (error) => {
        console.log("ERROR LOADING DATA")
        this.loading = false
      })
    },
    setConfig(config) {
      console.log("SET CONFIG ", config)
      this.config = config
      this.config.Riders.forEach((r) => {
        this.riders[r.RaceNr] = r
      })
    },

    setContext(ctx) {

      ctx.Started = []
      ctx.ToStart = []

      let riders = 0

      ctx.StartOrder.forEach((so) => {
        so.Rider = this.riders[so.RaceNr] || {}
        if (so.Status === 'Unknown') {
          ctx.ToStart.push(so)
          riders++
        }
        else if (so.Status === 'InRace') {
          ctx.Started.push(so)
          riders++
        }
      })

      ctx.Splits.forEach((split) => {
        let first = 0

        split.Passed = 0

        if (split.Results.length > 0) {
          first = split.Results[0].Time

        }

        split.Last.forEach((l) => {
          l.Rider = this.riders[l.RaceNr]
          l.Gap = l.Pos > 0 ? l.Time - first : 0
        })

        split.Results.forEach((r) => {
          if (r.Pos > 0) {
            split.Passed++
          }

          r.Gap = r.Pos > 0 ? r.Time - first : 0
          r.Rider = this.riders[r.RaceNr]
        })

        split.ToCome = ctx.StartOrder.length - split.Results.length
      })

      ctx.Started = ctx.Started.reverse()
      this.context = ctx

    },

    ready() {
      console.log("ready")

    }
  }
}
</script>

<style scoped>
#main {
  background: no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  min-height: 100vh;
}

@media only screen and (max-width: 600px) {
  .container.results {
    padding: 5px !important;
  }
}

@media only screen and (max-width: 500px) {
  .container.results {
    padding: 0px !important;
  }
}

.event-header-img img {
  z-index: 1;
  width: 100%;
  max-height: inherit;
  transform: translateY(-33%);
}

.event-header-master {
  overflow: hidden;
  height: 200px;
}

.event-header {
  padding: 1px 40px;
}
</style>
