<template>
  <div>
    <Toolbar2 is-fluid />
    <v-sheet>
      <v-container class="py-4 py-lg-8">
        <div class="text-center">          
          <div class="text-uppercase font-weight-bold body-2 primary--text mb-2">Cette politique entre en vigueur le 1er janvier 2021</div>
          <h2 class="text-h3 text-lg-h2">Politique de confidentialité de ACN-TIMING</h2>
          <!-- <v-responsive max-width="1200" class="mx-auto">
            <div class="text-h6 text-lg-h5 mt-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus impedit error labore doloremque fugit! Dolor fugit molestiae vero quos quisquam nobis, eos debitis magni omnis ea incidunt amet voluptate dignissimos!</div>
          </v-responsive> -->
        </div>
        <v-row class="mt-4">
          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Introduction</div>
                <div class="text-h6 mt-1">
                  Notre politique de confidentialité (la « Politique de confidentialité ») décrit les informations que nous recueillons, la manière dont nous les utilisons et les partageons, la manière de gérer vos contrôles de confidentialité et vos droits en rapport avec nos sites Web et nos applications. Le siège de ACN Timing se trouve à Solwaster (Belgique). Si vous résidez dans l’Espace économique européen (« EEE »), ACN Timing est le responsable du traitement de vos données personnelles aux fins de la loi de l’EEE relative à la protection des données.<br/>
                  <br/>
                  <v-divider></v-divider>
                  <br/>
                  <p>
                    <u>ACN-Timing</u><br/>
                    1 chemin des Grands Prés<br/>
                    4845 Jalhay (solwaster)
                  </p>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Informations collectées par ACN-Timing</div>
                <div class="text-h6 mt-1">
                  ACN-Timing recueille des informations à votre sujet, y compris des informations permettant de vous identifier directement ou indirectement. Nous recevons les informations à l’aide des formulaires d'inscriptions, applications (expeSeries / Strava / ...)<br/>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Comptes de tiers</div>
                <div class="text-h6 mt-1">
                  ACN-Timing vous permet de vous inscrire et de vous connecter aux services par l’intermédiaire des comptes que vous créez à partir de produits et services tiers comme Facebook, Google ou Apple (collectivement, les « Comptes de tiers »). Si vous accédez aux Services par l’intermédiaire de Comptes de tiers, nous recueillerons les informations que vous avez accepté de rendre disponibles, comme votre nom, votre adresse e-mail, vos informations de profil et vos préférences. Ces informations sont recueillies par le fournisseur du Compte de tiers et sont fournies à ACN-Timing dans le cadre de sa politique de confidentialité. Vous pouvez généralement contrôler les informations que nous recevons de ces sources grâce aux contrôles de confidentialité dans votre Compte de tiers.
                </div>
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Informations techniques et fichiers journaux</div>
                <div class="text-h6 mt-1">
                  <p>Nous collectons des informations à partir de votre navigateur, de votre ordinateur ou de votre appareil mobile, qui nous fournissent des informations techniques lorsque vous consultez ou utilisez les services. Ces informations techniques comprennent des informations sur l’appareil et le réseau, des cookies, des fichiers journaux et des informations analytiques.</p>
                  <p>Les services utilisent des fichiers journaux. Les informations stockées dans ces fichiers incluent les adresses IP, le type de navigateur, le fournisseur d’accès Internet (FAI), les pages de référence/sortie, le type de plateforme, les horodatages et le nombre de clics. Ces informations sont utilisées pour analyser les tendances, administrer, protéger et sécuriser les Services, suivre le mouvement des membres sous forme agrégée et rassembler des informations démographiques générales pour une utilisation agrégée. Les adresses IP peuvent être associées à des identifiants de session, des identifiants de sportif et des identifiants d’appareil.</p>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>      
      </v-container>
    </v-sheet>        

    <v-sheet>
      <v-container class="py-4 py-lg-8">
        <div class="text-center">          
          <h2 class="text-h3 text-lg-h2">Comment ACN-TIMING utilise les informations</h2>
          <!-- <v-responsive max-width="1200" class="mx-auto">
            <div class="text-h6 text-lg-h5 mt-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus impedit error labore doloremque fugit! Dolor fugit molestiae vero quos quisquam nobis, eos debitis magni omnis ea incidunt amet voluptate dignissimos!</div>
          </v-responsive> -->
        </div>
        <v-row class="mt-4">
          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Pour la fourniture des services</div>
                <div class="text-h6 mt-1">
                  <p>
                    Nous utilisons les informations que nous recueillons et recevons dans le cadre de la fourniture des services, y compris pour vous offrir la capacité de réaliser les actions suivantes :
                    <ul>
                      <li>Voir vos résultats de course.</li>
                      <li>Enregistrer vos activités et analyser vos performances. Par exemple, pour comparer vos efforts précédents, analyser votre entraînement et (avec votre consentement) utiliser vos informations de fréquence cardiaque afin de vous proposer une analyse intéressante et utile de vos performances.</li>
                      <li>Explorer de nouveaux lieux où aller. Par exemple, pour découvrir des itinéraires ou des segments où vous pouvez rouler.</li>
                      <li>Visualiser vos activités de différentes manières inédites, par exemple en créant des cartes d’activité personnelles ou à l’aide de votre journal d’entraînement.</li>
                    </ul>
                  </p>
                  <p>
                    Vos informations, y compris des éléments de votre profil, votre nom d’utilisateur, vos photos, les membres que vous suivez et qui vous suivent, les clubs auxquels vous appartenez, vos activités, les appareils que vous utilisez seront partagés sur ACN-Timing pour vous permettre de participer aux services, par exemple pour afficher votre place dans le classement. Certaines informations (par ex. : votre nom et certaines informations relatives à votre profil) peuvent également être accessibles aux non-membres sur Internet. Vos informations précises de localisation, comme le lieu où vous roulez, peuvent également être partagées sur ACN-Timing ou avec des non-membres, en fonction des contrôles de confidentialité que vous avez définis.
                  </p>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Pour personnaliser votre expérience</div>
                <div class="text-h6 mt-1">
                  Nous utilisons les informations que nous recueillons sur vous, vos abonnés et vos activités pour personnaliser votre expérience. Par exemple, nous pouvons vous suggérer des segments, des itinéraires, des challenges ou des clubs susceptibles de vous intéresser, des athlètes que vous pourriez suivre ou de nouvelles fonctionnalités à tester. Si nous savons que vous aimez courir, nous pouvons vous parler de nouvelles activités de course à pied ou vous montrer du contenu sponsorisé lié à cette pratique sportive. Si nous voyons que vous courez dans une certaine zone, nous pouvons vous suggérer une course qui est organisée dans cette zone.
                </div>
              </div>
            </div>
          </v-col>
                    
          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Pour vous protéger et protéger les Services</div>
                <div class="text-h6 mt-1">
                  Nous utilisons les informations que nous recueillons pour protéger nos membres et promouvoir la sécurité. Par exemple, nous localisons et retirons les contenus qui enfreignent nos Conditions, tels que des propos haineux ou du spam, et nous suspendons ou résilions les comptes qui partagent de tels contenus.
                </div>
              </div>
            </div>
          </v-col>                    

          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Pour améliorer nos Services</div>
                <div class="text-h6 mt-1">
                  Nous utilisons également les informations que nous recueillons pour analyser, développer et améliorer les services. À cette fin, ACN-Timing peut utiliser des prestataires tiers d’analyse pour mieux comprendre de quelle manière nos services sont utilisés et pour nous aider à améliorer les services.
                </div>
              </div>
            </div>
          </v-col>                    
          
          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Pour communiquer avec vous</div>
                <div class="text-h6 mt-1">
                  Nous utilisons les informations que nous recueillons pour vous aider en réponse à vos demandes et commentaires.
                </div>
              </div>
            </div>
          </v-col>                    

          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Pour promouvoir nos services</div>
                <div class="text-h6 mt-1">
                  Nous pouvons également utiliser les informations que nous recueillons pour commercialiser et promouvoir les services, les activités et événements sur ACN-Timing, ainsi que d’autres produits ou services commerciaux. Cela comprend des communications marketing et push.
                </div>
              </div>
            </div>
          </v-col>                    

          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Pour traiter votre abonnement</div>
                <div class="text-h6 mt-1">
                  Nous utilisons les informations que nous recueillons pour traiter votre abonnement.
                </div>
              </div>
            </div>
          </v-col>                    

          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Informations agrégées</div>
                <div class="text-h6 mt-1">
                  Nous ne vendons pas vos informations personnelles. ACN-Timing peut regrouper les informations que vous et d’autres personnes avez mises à disposition dans le cadre des services et les publier publiquement ou les partager avec des tiers. Des exemples du type d’informations que nous pouvons agréger incluent des informations sur l’équipement, l’utilisation, les données démographiques, les itinéraires et les performances. ACN-Timing peut utiliser, vendre, concéder sous licence et partager ces informations agrégées avec des tiers à des fins de recherche, commerciales ou autres pour améliorer la marche, la course ou le vélo en ville ou aider ses partenaires à mieux comprendre les sportifs, notamment les personnes qui utilisent ses produits et services. ACN-Timing utilise également des données agrégées pour générer une carte d’activité mondiale.                    
                </div>
              </div>
            </div>
          </v-col>                    

          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Comment les informations sont partagées</div>
                <div class="text-h6 mt-1">
                  En plus de partager des données agrégées sur nos membres comme indiqué précédemment, nous partageons également des informations personnelles, conformément à vos préférences, dans la mesure du nécessaire pour gérer notre entreprise et fournir les services et lorsque cela est légalement requis, comme indiqué ci-dessous.
                </div>
              </div>
            </div>
          </v-col>                    

          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Fournisseurs de services</div>
                <div class="text-h6 mt-1">
                  Nous pouvons partager vos informations avec des tiers qui fournissent des services à ACN-Timing pour soutenir, améliorer, promouvoir et sécuriser les services, traiter les paiements ou exécuter les commandes. Ces prestataires de services ont uniquement accès aux informations nécessaires pour exécuter ces fonctions limitées en notre nom et ont l’obligation de protéger et sécuriser vos informations. Nous pouvons également solliciter des prestataires pour recueillir des informations sur votre utilisation des services au fil du temps en notre nom, afin de promouvoir ACN-Timing ou d’afficher des informations qui pourraient être pertinentes pour vos intérêts sur les Services ou d’autres sites Internet ou services.
                </div>
              </div>
            </div>
          </v-col>                    

          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Informations accessibles au public</div>
                <div class="text-h6 mt-1">
                  Lorsque vous rejoignez la communauté ACN-Timing, votre profil et vos activités sont définis par sur « visible par tout le monde ». Votre nom et d’autres informations de votre profil sont visibles par les autres membres de ACN-Timing et par le grand public.
                </div>
              </div>
            </div>
          </v-col>                    

          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Partage des informations et activités</div>
                <div class="text-h6 mt-1">
                  En tant que réseau social, nous partageons le résultat de vos activités avec les autres membres de ACN-Timing et le grand public.
                </div>
              </div>
            </div>
          </v-col>                    

          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Affiliés et acquéreurs de notre entreprise ou de nos actifs</div>
                <div class="text-h6 mt-1">
                  Nous pouvons partager vos informations avec des sociétés affiliées sous contrôle commun avec nous, qui sont tenues de respecter les conditions de cette Politique de confidentialité à l’égard de vos informations. Si ACN-Timing est impliqué dans un regroupement d’entreprises, une offre de titres, une faillite, une réorganisation, une dissolution ou une autre transaction similaire, nous pouvons partager ou transférer vos informations dans le cadre de cette transaction.
                </div>
              </div>
            </div>
          </v-col>                    

          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Exigences légales</div>
                <div class="text-h6 mt-1">
                  Nous pouvons conserver et partager vos informations avec des tiers, y compris des organismes d’application de la loi, des organismes publics ou gouvernementaux ou des particuliers plaignants, à l’intérieur ou à l’extérieur de votre pays de résidence, si nous déterminons qu’une telle divulgation est raisonnablement nécessaire pour se conformer à la loi, y compris pour répondre aux ordonnances d’un tribunal, aux mandats, assignations ou autres procédures légales ou réglementaires. Nous pouvons également conserver ou divulguer vos informations si nous déterminons que cela est raisonnablement nécessaire ou approprié pour empêcher qu’une personne décède ou soit gravement blessée, pour des enjeux de sécurité nationale ou d’autres enjeux d’importance publique, pour prévenir ou détecter la fraude ou l’abus à l’encontre de ACN-Timing ou de ses membres, ou pour protéger nos opérations ou nos biens ou d’autres droits légaux, y compris par divulgation à nos conseillers juridiques et autres consultants et tiers en relation avec des litiges réels ou potentiels.
                </div>
              </div>
            </div>
          </v-col>                    

          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Comment nous protégeons les informations</div>
                <div class="text-h6 mt-1">
                  Nous prenons plusieurs mesures pour protéger la collecte, la transmission et le stockage des données que nous recueillons. Nous avons recours à des protections raisonnables pour assurer la sécurité de vos informations, en fonction de leur degré de sensibilité. Les Services utilisent la technologie standard de l’industrie Secure Sockets Layer (SSL) pour permettre le cryptage des informations personnelles et des numéros de carte de crédit. ACN-Timing engage des fournisseurs leaders de la sécurité en ligne, y compris de la vérification des services, pour renforcer la sécurité de ses services. Les services sont enregistrés auprès des autorités d’identification de site afin que votre navigateur puisse confirmer l’identité de ACN-Timing avant d’envoyer des informations personnelles identifiables. De plus, les serveurs sécurisés de ACN-Timing protègent ces informations à l’aide d’une technologie de pare-feu avancée.
                </div>
              </div>
            </div>
          </v-col>                    

          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Transferts</div>
                <div class="text-h6 mt-1">
                  Les services sont exploités depuis les Pays-Bas. Si vous vous trouvez hors de la Belgique et choisissez d’utiliser les services ou de nous fournir des informations, vous reconnaissez et comprenez que vos informations seront transférées, traitées et stockées en Europe (Pays-Bas), dans la mesure où cela est nécessaire pour fournir les services. Les lois de protection de la vie privée en Belgique peuvent ne pas être aussi protectrices que les lois de votre juridiction.
                </div>
              </div>
            </div>
          </v-col>                    
          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Rétention des informations</div>
                <div class="text-h6 mt-1">
                  <p>Nous conservons les informations pendant la durée nécessaire pour vous fournir les services et les fournir aux autres, sous réserve de toute obligation légale de conserver ces informations plus longtemps. Les informations associées à votre compte seront généralement conservées jusqu’à ce qu’il ne soit plus nécessaire de fournir les services ou que votre compte soit supprimé. Après que vous avez supprimé votre compte, un délai de 90 jours peut être nécessaire pour supprimer entièrement vos informations personnelles et les journaux système de nos systèmes. En outre, nous pouvons conserver des informations pour nous conformer à la loi, prévenir la fraude, collecter des frais, résoudre les litiges, résoudre les problèmes, aider aux enquêtes, et prendre d’autres mesures autorisées par la loi. Les informations que nous conservons seront traitées conformément à la présente Politique de confidentialité.</p>
                  <p>Les informations vous concernant et qui ne sont plus nécessaires et pertinentes pour la fourniture des services peuvent être désidentifiées ou agrégées avec d’autres données non personnelles afin de fournir des renseignements ayant une valeur commerciale pour ACN-Timing, comme des statistiques sur l’utilisation des services. Par exemple, nous pouvons conserver les segments ou itinéraires disponibles publiquement ainsi que d’autres informations de géolocalisation dépersonnalisées afin de continuer à améliorer les Services et les utiliser sur notre carte mondiale des activités. Votre nom et autres identifiants seront dissociés de ces informations.</p>
                </div>
              </div>
            </div>
          </v-col>                    
          <v-col cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>mdi-email</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">Informations sur la Politique de confidentialité</div>
                <div class="text-h6 mt-1">
                  ACN-Timing se réserve le droit de modifier cette politique de confidentialité à tout moment. Merci de la consulter de temps en temps. Si ACN-Timing modifie la présente Politique de confidentialité, la Politique de confidentialité mise à jour sera publiée rapidement sur les services et, si nous apportons des modifications importantes, nous fournirons une notification visible. Si vous vous opposez à l’une des modifications apportées à la présente Politique de confidentialité, vous devez cesser d’utiliser les Services et supprimer votre compte.
                </div>
              </div>
            </div>
          </v-col>    
        </v-row>
      </v-container>
    </v-sheet>

    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/ui/landing/footer/Footer8Demo.vue'
import Toolbar2 from '@/components/ui/landing/toolbar/Toolbar2.vue'

export default {
  components: {    
    Footer,
    Toolbar2
    /*Logos2Demo,
    CallToAction5Demo,
    Feature11Demo,
    Footer4Demo,
    Team6Demo*/
  },
  data() {
    return {
      features: [{
        icon: 'mdi-account-check-outline',
        title: 'Introduction',
        description: 'Notre politique de confidentialité (la « Politique de confidentialité ») décrit les informations que nous recueillons, la manière dont nous les utilisons et les partageons, la manière de gérer vos contrôles de confidentialité et vos droits en rapport avec nos sites Web et nos applications. Le siège de ACN Timing se trouve à Solwaster (Belgique). Si vous résidez dans l’Espace économique européen (« EEE »), ACN Timing est le responsable du traitement de vos données personnelles aux fins de la loi de l’EEE relative à la protection des données.'
      }, {
        icon: 'mdi-lifebuoy',
        title: 'Dedicated Support',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse expedita fugit quam aliquam. Autem assumenda'
      }, {
        icon: 'mdi-email-open-multiple-outline',
        title: 'Email Integration',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse expedita fugit quam aliquam. Autem assumenda'
      }, {
        icon: 'mdi-clock-outline',
        title: 'Save Time',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse expedita fugit quam aliquam. Autem assumenda'
      }]
    }
  }  
}
</script>
