<template>
  <v-list v-if="Leafs && Leafs.length > 0" dense>
    <v-list-item-group color="primary">
      <v-list-item
        v-for="(item, i) in Leafs"
        :key="i"
        @click="SelectNode(item)"
      >
        <v-list-item-icon>
          <v-icon v-text="'mdi-clock'"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.DisplayName"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { _ } from "vue-underscore";

export default {
  props: ["node","selected"],
  components: {},
  data() {
    return {};
  },
  computed: {
    Folders: function () {
      if (!this.node) return null;
      return this.node.Folders;
    },

    Leafs: function () {
      if (!this.node) return null;
      return this.node.Leafs;
    },
  },
  methods: {
    HandleClick: function (n) {

      console.log("CLICK ON ", n);
    },
    SelectNode(n){
      this.$emit('selected',n);
    }
  },
  watch: {},

  beforeDestroy() {},
  updated() {},
  mounted() {},
};
</script>
