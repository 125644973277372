<template>
  <cr-cell
    :row="row"
    :column="column"
    :definition="definition"
  ><img
    v-if="row[column.FieldIdx]!=''"
    :src="'https://chronorace.blob.core.windows.net/webresources/img/' +
        row[column.FieldIdx]
    "
    style="height:20px"
  /></cr-cell>
</template>
<script>
export default {
  props: ['row', 'definition', 'column', 'action'],
  data() {
    return {}
  },
  beforeMount: function() {},
  mounted: function() {    
  },
  methods: {
    testClick: function(event) {
      this.$emit('action', { type: 'testaction' })
      event.stopPropagation()
    }
  }
}
</script>
