<template>
  <cr-cell :row="row" :column="column" :definition="definition" ><span v-html="row[column.FieldIdx]"></span></cr-cell>
</template>
<script>
export default {
  data() {
    return {};
  },
  
  props: ["row","definition","column","action"],
  beforeMount: function () {},
  methods: {
    testClick:function(event)
    {
      this.$emit('action',{action:'testaction'});
      event.stopPropagation();
    }
  },
  mounted: function () {},
};
</script>
