<template>
  <span>
    <div v-if="!cms">
      <h1>NOT AVAILABLE</h1>
    </div>    
    
    <div v-if="loading"><h1>Loading</h1></div>

    <router-view v-if="cms" :node='cms'></router-view>
    
    <!-- <v-btn :to="{ name: 'eventcms', params: { db:'20220320_kotk' } }">KOTK</v-btn>
    <v-btn :to="{ name: 'eventcms', params: { db:'20210901_cairo' } }">CAIRO</v-btn>
    <v-btn :to="{ name: 'eventcms', params: { db:'xx' } }">ERR</v-btn> -->

   
  </span>
</template>

<script>
import eventService from "@/services/eventService.js";
import genericResultService from "@/services/genericResultService.js";
import CMSFolders from "./Components/CMSFolders";

import LeafNodesContainer from "./Components/LeafNodesContainer";
import CompetitionList from "./Components/CompetitionList";

import { _ } from "vue-underscore";
import Loading from '../../components/Loading.vue';

export default {
  props: ["node"],

  components: { LeafNodesContainer, CMSFolders, CompetitionList },

  data() {
    return {
      cms: null,
      loading:false,
      path: [],
    };
  },
  computed: {
  },
  beforeRouteEnter(to, from, next) {
    var db = to.params.db;

    console.log("ROUTE MOUNTED");

   // console.log("BIEN... ", to.query);

    genericResultService.Uci(to.params.db, "dh", "cms").then((cms) => {
   //   console.log("lala");
      next((vm) => {
        vm.setCMS(cms);
      });
    });
  },

  beforeRouteUpdate(to, from, next) {

    var db = to.params.db;
    if (db != this.$route.params.db || this.cms==null) {
      this.loading=true;
      genericResultService.Uci(to.params.db, "dh", "cms").then((cms) => {
        this.setCMS(cms);
        next();
        this.loading=false;
      });
    } else next();

    return true;
  },

  methods: {
    ConvertNode(node,level,parent,index,ix)
    {
      if (node==null) return null;
      var toRet=node;
      if (!ix) ix=0;
      if (index==null) {
        toRet.Index={};
        index=toRet.Index;
      }
      toRet.Idx=ix;
      toRet.Folders=[];
      toRet.Leafs=[];
      toRet.Level=level;
      index[node.Id]=node;
      if (parent) toRet.Parent=parent.Id;      

      var folderIx=0;
      var chlIx=0;
      node.Childs.forEach(chl=>{
        if ((chl.Childs != null && chl.Childs.length > 0) || chl.Type.toLowerCase()=='folder') toRet.Folders.push(this.ConvertNode(chl,level+1,node,index,folderIx++));
        else toRet.Leafs.push(this.ConvertNode(chl,level+1,node,index,chlIx++));
      })

      return toRet;
    },

    setCMS(cms) 
    {
      if (!cms) return;

     /* if (cms && cms.Childs)
        cms.Childs.push({ DisplayName: 'CONTACTS'
          , Type:'folder', Name:'CONTACTS', Id: 'C1'
          , Childs:[], Settings:{}
          , Route: "{\"Route\":\"events.contacts\" }" });*/

      console.log("SET CMS",cms.Childs)
      this.cms = this.ConvertNode(cms,0);
    },
  },
  watch: {
  },

  beforeDestroy() {},
  updated() {
    //  console.log("component updated", this.$route);
    
  },
  mounted() {
  },
};
</script>
