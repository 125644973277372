<template>
  <span>    
    <CMSFolder v-if="cms" :selection="selection" :level="1" :node="cms" @selected="SelectNode"></CMSFolder>
    <CMSFolder v-if="cms && selection['1']" :selection="selection" :level="2" @selected="SelectNode" :node="cms.Index[selection['1']]"></CMSFolder>

    <div class="pt-0">
      <div xstyle="background-color:red">
        <v-container
          class="pt-0 pb-0 results"
          style="max-width: 1150px; xbackground-color: orange"
        >
          <v-breadcrumbs
            large
            divider=">"
            class="pt-1 pb-1"
            style="padding-left: 0px; padding-bottom: 0px"
          >
            <span v-for="(item, i) in pathNodes" :key="item.Id">
              <v-breadcrumbs-divider v-if="i > 0">/</v-breadcrumbs-divider>
              <v-breadcrumbs-item
                exact
                :to="{ name: 'eventcms', params: item.Params }"
              >
                {{ $tcond(item.DisplayName) }}
              </v-breadcrumbs-item>
            </span>
            <!-- <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item>KOTK - ddd - Live</v-breadcrumbs-item> -->
          </v-breadcrumbs>
        </v-container>
      </div>
    </div>
  </span>
</template>

<script>
import eventService from "@/services/eventService.js";
import CMSFolder from "./CMSFolder";

import { _ } from "vue-underscore";

export default {
  props: ["cms", "path"],
  components: { CMSFolder },
  methods: {
    SetPath(p) {
      if (this.$route.params.path && this.$route.params.path == p) return;

      console.log("PATH ", p);
      this.$router.push({ name: "eventcms", params: { path: p } }, (err) => {
        console.log("router err");
      });
    },

    SelectNode(node) {
      //console.log("node selected???",node);
      if (!node) return;
      if (node.Route && node.Route != "") {
        var route = JSON.parse(node.Route);
        console.log("select node route", route, node.Id);

        var params = { ...route.Params, path: node.Id };

        console.log("route params", params);

        this.$router.push({
          name: route.Route,
          params: params,
        });
        return;
      } else if (node.Link && node.Link != "") {
        //console.log("LINK CLICKED");
        window.location = node.Link;
        return;
      } else if (node.Childs == 0) {
       // console.log("leaf node cliqued", node);
        return;
      }

      //console.log("SELECT NODE ", node.Id, node.Level, node.Folders.length);
      if (node.Level < 2 && node.Folders.length > 0)
        this.SelectNode(node.Folders[0]);
      else if (node.Level>=2 && node.Childs.length==1)
      {
        this.SelectNode(node.Childs[0]);
      }
      else {
      //  console.log("LEVEL = ", node.Level);
       // console.log("NODE selection level " + this.level, node);
        
        setTimeout(() => this.SetPath(node.Id));

        //this.$router.push()
      }
    },
    buildPathNodes() {
      this.selection = {};

     // console.log("BUILD PATH NODES ", this.path);
      if (!this.cms || !this.path) return;
      this.pathNodes = [];

      //  console.log("galere??? ", this.cms.Index[this.path]);
      var curNode = this.cms.Index[this.path];

      while (curNode && curNode.Id && this.pathNodes.length < 10) {
        this.selection[curNode.Level] = curNode.Id;

        if (curNode.Level <= 2 && curNode.Folders.length > 0) {
          // ignore
        } else {
          this.pathNodes.unshift({
            Id: curNode.Id,
            DisplayName: curNode.DisplayName,
            Params: {
              path: curNode.Id,
            },
          });
        }
        curNode = this.cms.Index[curNode.Parent];
      }

      this.pathNodes.unshift({
        Id: "HOME",
        DisplayName: "HOME",
        Params: {},
      });
    //  console.log("pathnodes", this.pathNodes);
    },
    

  },
  data() {
    return {
      pathNodes: [],
      selection:{}
    };
  },
  computed: {},
  watch: {
    node: function (o, n) {
    //  console.log("node watch called on cmsbreadcrumb");
      this.buildPathNodes();      
    },
    path: function (o, n) {
    //  console.log("selectopn watch called on cmsbreadcrumb");
      this.buildPathNodes();         
    },
  },

  beforeDestroy() {},
  updated() {},
  mounted() {
   // console.log("mounted  cmsbreadcrumb");
    this.buildPathNodes();
  },
};
</script>
