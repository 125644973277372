<template>
  <cr-cell  :row="row" :column="column" :definition="definition">
    <i @click="testClick($event)" :class='[ isChecked?"fas":"far" ]' class="fa-star fa-lg primary--text "></i>
   
  </cr-cell>
</template>
<script>
export default {
  data() {
    return {
      checked: false,
      favorites: {}
    };
  },
  
  props: ["row","definition","column","action","context"],
  beforeMount: function () {},
  computed: {
    value:function()
    {
      return this.row[this.column.FieldIdx];
    },
    isChecked:function()
    {
      //console.log("ischecked? ",this.favorites)
      if (!this.favorites) return;
      return this.favorites.hasOwnProperty(this.value);
      //return true;
    }
  },
  methods: {
    testClick:function(event)
    {
      var k=this.row[this.column.FieldIdx];

      console.log("fav ["+k+"]");
      console.log(this.context);


      this.context.setFavorite(this.row[this.column.FieldIdx],!this.favorites[k]);

      //this.checked=!this.checked;
      //console.log("coool???",this.definition)
      //this.$emit('action',{action:'testaction'});
      event.stopPropagation();
    }
  },
  mounted: function () {
    if (this.context)
    {
      //console.log("getfavorites in ctx: ",this.context.getFavorites())
      this.favorites=this.context.getFavorites();
     // console.log("ok? ",this.favorites)
    } 
  },
};
</script>
