import Vue from 'vue'
import Store from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import regeneratorRuntime from "regenerator-runtime";

Vue.use(VueI18n);
  

/*export default async ({ app, store }) => {
    app.i18n = new VueI18n({
        locale: store.state.locale,
        fallbackLocale: 'en',
        messages: {
            'en': await getMessages('en'),
            'fr': await getMessages('fr')
        }
    });

    app.i18n.path = (link) => {
         if (app.i18n.locale === app.i18n.fallbackLocale) return `/${link}`;

         return `/${app.i18n.locale}/${link}`;
    }
}*/

/*new VueI18n({
  //locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  //fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: loadLocaleMessages
});*/

import appSettingsService from './appSettingsService';
const loadedLanguages = []

export async function loadLocaleMessagesAsync(locale) {
  //console.log("LOAD LOCALE ASYNC ",loadedLanguages,locale,i18n.locale);

  /*if (loadedLanguages.length > 0 && i18n.locale === locale) {
    return Promise.resolve(locale)
  }*/

  // If the language was already loaded
  if (loadedLanguages.includes(locale)) {
    i18n.locale = locale
    return Promise.resolve(locale)
  }

  //console.log("AXIOS .... LOCALE ...")
  
  await axios.get(appSettingsService.get("apiUrlBase")+'translation/i18n/acn/inscriptions/'+locale).then((res) => {
    //return res.data
    i18n.setLocaleMessage(locale, res.data)
    loadedLanguages.push(locale)
    i18n.locale = locale
    return Promise.resolve(locale)

  })  
}


const i18n = new VueI18n({
  locale: 'nl',
  silentTranslationWarn: true,
  fallbackLocale: 'nl',
  messages: {}
});

export default i18n;