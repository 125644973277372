const collectionTools = new (class {

  findFirstIdx(array,obj,cmpFct,low,high)
  {
      if (!low) low=0;
      if (!high) high=array.length-1;

      low--;
      var found = false;
      while (low + 1 < high)
      {
          var mid = ((high - low) >> 1) + low;
          var cur = array[mid];
          //comparer.Compare(cur, o);
          var cmp = cmpFct(cur,obj); // -1 if cur is smaller  // cur.CompareTo(o);
          if (cmp > 0) high = mid;
          else if (cmp == 0)         
          {
              high = mid;
              found = true;
          }
          else low = mid;
      }
      if (found) return high;
      return -(high + 1);
  }

  constructor() {
  }
})();

export default collectionTools;
