<template>
  <span>
    <Loading v-if="loading"></Loading>
    <span v-if="!loading && this.curReport != null">
      <v-breadcrumbs divider="-">
        <v-breadcrumbs-item exact :to="{ name: 'reportList' }">{{
          $t('REPORTLIST')
        }}</v-breadcrumbs-item>
        <v-breadcrumbs-item>{{ $tcond(curGroup.DisplayName) }} -
          {{ $tcond(curReport.DisplayName) }}</v-breadcrumbs-item>
      </v-breadcrumbs>

      <v-card-text v-if="!loading">
        <v-row>
          <v-col md="6" cols="12"> </v-col>
          <v-col md="6" cols="12">
            <v-form @submit="updateSearchResult()">
              <v-text-field
                v-model="search"
                :loading="searching"
                :append-icon="'mdi-magnify'"
                type="text"
                :label="$t('SEARCH')"
                filled
                clearable
                placeholder="nom, dossard,..."
                @click:append="updateSearchResult()"
                @click:clear="updateSearchResult('')"
              ></v-text-field>
            </v-form>
          </v-col>
        </v-row>

        <cr-table v-if="data.Count > 0" @action="rowClick" :data="data"></cr-table>
        <span v-else v-html="$t('NORESULT')"></span>

        <v-pagination
          v-model="page"
          :length="pageCount"
          @input="setPage"
        ></v-pagination>
      </v-card-text>
    </span>
  </span>
</template>

<script>
import table from '@/data/test.json'
import eventService from '@/services/eventService.js'

import { _ } from 'vue-underscore'

export default {
  props: ['reportList'],

  data() {
    return {
      table,
      loading: false,
      curGroup: null,
      searching: false,
      page: 1,
      curReport: null,
      data: null,
      search: '',
      pageSize: 100
    }
  },
  computed: {
    pageCount: function() {
      if (!this.data) return 0

      return Math.trunc((this.data.Count + (this.pageSize - 1)) / this.pageSize)
    }
  },
  watch: {
    reportList: function(o, n) {
      console.log('report list wxatch called')
      this.updateReport()
    }
  },
  mounted() {
    this.updateReport()
  },
  methods: {
    rowClick(evt)
    {
      console.log("rowclick in viewer: ",evt);
      console.log(this.data);
    },
    setPage(page) {
      console.log('PAGE CLICKED ', page, this.page)
      this.searchReport(this.search)
    },
    updateSearchResult(srch) {
      this.page = 1

      if (srch != null) {
        this.search = srch
      }

      console.log('update search result', this.search, '[' + srch + ']')
      this.searchReport(this.search)
    },
    searchReport(srch) {

      this.searching = true

      eventService
        .searchReport(
          this.$route.params.eventId,
          this.curReport.Id,
          (this.page - 1) * this.pageSize,
          this.pageSize,
          srch
        )
        .then((data) => {
          this.data = data
          this.loading = false
          this.searching = false
        })

      /*this.searching = true
      const settings = {
        from: (this.page - 1) * this.pageSize,
        pageSize: this.pageSize
      }

      console.log('search... ', srch)
      eventService
        .searchTable(
          this.curReport.Settings.Db,
          this.curReport.Settings.Table,
          srch,
          settings
        )
        .then((data) => {
          console.log('goood...')
          this.data = data
          this.loading = false
          this.searching = false
        })*/



    },
    sortTest1() {
      this.table.Groups.forEach((g) => {
        g.SlaveRows.sort((a, b) => {
          if (a[1] > b[1]) return 1
          if (a[1] < b[1]) return -1

          return 0
        })
      })
    },
    updateReport() {
      if (!this.reportList || this.reportList.length == 0) return

      this.curGroup = _.find(
        this.reportList,
        (grp) => grp.Id == this.$route.params.group
      )
      this.curReport = _.find(
        this.curGroup.Reports,
        (rpt) => rpt.Id == this.$route.params.report
      )

      this.loading = true
      this.searchReport('')
    }
  }
}
</script>
