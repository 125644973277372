var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.showLaneSelection),callback:function ($$v) {_vm.showLaneSelection=$$v},expression:"showLaneSelection"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showLaneSelection = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Lane selection")]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card-text',[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',_vm._l((_vm.lanes),function(lane){return _c('td',{key:lane.Lane,staticClass:"text-center pa-1 ma-0"},[_c('div',{staticClass:"rounded-lg",style:({
                        'background-color': _vm.getBgColor(lane),
                        'height': '75px',
                        'border': '1px solid black'
                      }),on:{"click":function($event){return _vm.setLane(lane)}}},[_vm._v(" Lane "+_vm._s(lane.Lane)),_c('br'),(lane.OriginalBib != 0)?_c('h3',[_vm._v(" n°"+_vm._s(lane.OriginalBib)+" ")]):_vm._e()])])}),0)])]},proxy:true}])})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{on:{"click":function($event){return _vm.cleanLane()}}},[_vm._v("Clear")]),_c('v-btn',{on:{"click":function($event){_vm.showLaneSelection = false}}},[_vm._v("Close")])],1)],1)],1),_c('v-card-text',[_c('div',{staticClass:"text-h4"},[_vm._v("Lanes")]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',_vm._l((_vm.lanes),function(lane){return _c('td',{key:lane.Lane,staticClass:"text-center pa-1 ma-0"},[_c('div',{staticClass:"rounded-lg",style:({
                    'background-color': _vm.getBgColor(lane),
                    'height': '75px',
                    'border': '1px solid black'
                  })},[_vm._v(" Lane "+_vm._s(lane.Lane)),_c('br'),(lane.OriginalBib != 0)?_c('h3',[_vm._v("n°"+_vm._s(lane.OriginalBib))]):_vm._e()])])}),0)])]},proxy:true}])}),_c('br'),_c('div',{staticClass:"text-h4"},[_vm._v("Selection Order")]),_vm._l((_vm.curRace.Riders),function(rider){return _c('v-row',{key:rider.Bib,staticClass:"ma-0"},[_c('v-col',{staticClass:"ma-0 pa-1",attrs:{"cols":"10"}},[_c('v-btn',{style:({ 'background-color':_vm.getBgColorRider(rider)  }),attrs:{"block":"","outlined":"","asmall":""},on:{"click":function($event){return _vm.selectLane(rider)}}},[_vm._v(" "+_vm._s(rider.OriginalBib)+" - "+_vm._s(rider.LastName)+" ("+_vm._s(rider.IOC)+") ")])],1),_c('v-col',{staticClass:"ma-0 pa-1",attrs:{"cols":"2"}},[_c('v-btn',{style:({ 'background-color':_vm.getBgColorRider(rider)  }),attrs:{"block":"","outlined":"","asmall":""}},[(rider.Lane && rider.Lane!=0)?_c('span',[_vm._v(" "+_vm._s(rider.Lane))]):_c('span',[_vm._v("-")])])],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }