<template>
  <div v-if="node " xclass="pt-1">
    <LeafNodesContainer @selected="SelectNode" :node="node"></LeafNodesContainer>

    <CMSFolder :level="level" :selection="selection" @selected="SelectNode" :node="node"></CMSFolder>

    <CMSFolders v-if="level<2" :node="SelectedNode" :level="parseInt(level)+1" :selection="selection" @selected="SelectNode"></CMSFolders>
  </div>
</template>

<script>
import eventService from "@/services/eventService.js";

import LeafNodes from "./LeafNodes";
import CMSFolder from "./CMSFolder";
import LeafNodesContainer from "./LeafNodesContainer";

import { _ } from "vue-underscore";

export default {
  props: ["node","selection","level","selected"],
  components: { LeafNodesContainer, LeafNodes,CMSFolder,CMSFolders: ()=>import("./CMSFolders")  },
  name: 'folder',
  data() {
    return {
      idx: -1
    };
  },
  computed: {
    SelectedNode: function () {
      var folders = this.Folders;
      if (!folders) return null;
      if (this.idx < folders.length) return folders[this.idx];
      return null;
    },

    Folders: function () {
      if (!this.node) return null;
      return this.node.Folders;
    },

  },
  methods: {
    updateSelectedIdx(){
      var folders=this.Folders;
      if (!folders) return;
      var i=0;
      while (i<folders.length)
      {
        if (this.selection[this.level]==folders[i].Id) this.idx=i;
        i++;
      }      
    },
    updateView() {},
    SelectNode(n)
    {
      console.log("SELECT NODE IN FOLDERS",n);
      console.log("cool???");
      this.$emit('selected',n);
    }
  },
  watch: {
    node:function(o,n)
    {
      this.updateSelectedIdx();
    },
    selection:function(o,n){
      this.updateSelectedIdx();      
    }
  },

  beforeDestroy() {},
  updated() {},
  mounted() {
      this.updateSelectedIdx();
  },
};
</script>
