<template>
  <div>

    <v-dialog  v-model="dlgDialog" v-if="data.Results" scrollable max-width="500">
      <v-card style="white-space-collapse: preserve;">
        <v-card-title class="headline"><b>{{ data.Title }}</b> - Results</v-card-title>
        <v-card-text>
          <table class="data-table mt-1" v-if="data.Results.length > 0">
            <tbody class="table-data">
              <tr v-for="result in data.Results" :key="result.RaceNr" class="last-record-line">
                <td class="text-right">
                  <span v-if="result.Pos > 0">{{ result.Pos }}.</span><span v-else>{{ result.Status }}</span>
                </td>
                <td class="text-bold">{{ result.Rider.FirstName.substring(0, 1) }}. {{ result.Rider.LastName }}</td>
                <td class="text-center"><small>({{ result.RaceNr }})</small></td>

                <td class="text-center pb-0"><img
                    :src="'https://chronorace.blob.core.windows.net/webresources/img/flags/' + result.Rider.NOC.toLowerCase() + '.png'"
                    style="height: 15px"></td>
                <td class="text-center" :title="result.Rider.TeamName">{{ result.Rider.TeamCode }}</td>

                <td class="text-right"><b>{{ result.Gap > 0 ? '+' + formatTime(result.Gap) : formatTime(result.Time) }}</b></td>

              </tr>
            </tbody>
          </table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dlgClick()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card class="pa-1" style="white-space-collapse: preserve;" dense>
      <v-card-title class="pt-0 pl-1">
        <span class="fa mr-1"
          :class="{ 'fa-stopwatch': data.SplitType == 'Intermediate', 'fa-flag-checkered': data.SplitType == 'Finish' }"></span>
        <b>{{ data.Title }} </b><span> - {{ formatDistance(data.Distance) }}</span>
      </v-card-title>
      <v-card-subtitle class="pl-1 pb-1"><b>{{ data.Passed }}</b> riders passed - <b>{{ data.ToCome }}</b> to come
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text class="pb-1">
        <span class="text-subtitle-2">Last passed</span>
      </v-card-text>

      <table class="data-table ">
        <tbody class="table-data">
          <tr v-for="result in data.Last.slice(0, 3)" :key="result.RaceNr" class="last-record-line">
            <td class="text-right"><span v-if="result.Pos > 0">{{ result.Pos }}.</span><span v-else>{{ result.Status
                }}</span>
            </td>
            <td class="text-bold">{{ result.Rider.FirstName.substring(0, 1) }}. {{ result.Rider.LastName }}</td>
            <td class="text-center"><small>({{ result.RaceNr }})</small></td>

            <td class="text-center pb-0"><img
                :src="'https://chronorace.blob.core.windows.net/webresources/img/flags/' + result.Rider.NOC.toLowerCase() + '.png'"
                style="height: 15px"></td>
            <td class="text-center" :title="result.Rider.TeamName">{{ result.Rider.TeamCode }}</td>
            <td class="text-right"><b>{{ result.Gap > 0 ? '+' + formatTime(result.Gap) : formatTime(result.Time) }}</b>
            </td>
          </tr>
        </tbody>
      </table>
      <v-divider></v-divider>
      <v-card-text class="pb-1">
        <span class="text-subtitle-2">Results <span v-if="speed"> - Speed: {{ speed }}</span></span>
      </v-card-text>

      <table class="data-table">
        <tbody class="table-data">
          <tr v-for="result in data.Results.slice(0, 10)" :key="result.RaceNr" class="last-record-line">
            <td class="text-right"><span v-if="result.Pos > 0">{{ result.Pos }}.</span><span v-else>{{ result.Status
                }}</span>
            </td>
            <td class="text-bold">{{ result.Rider.FirstName.substring(0, 1) }}. {{ result.Rider.LastName }}</td>
            <td class="text-center"><small>({{ result.RaceNr }})</small></td>

            <td class="text-center pb-0"><img
                :src="'https://chronorace.blob.core.windows.net/webresources/img/flags/' + result.Rider.NOC.toLowerCase() + '.png'"
                style="height: 15px"></td>
            <td class="text-center" :title="result.Rider.TeamName">{{ result.Rider.TeamCode }}</td>
            <td class="text-right"><b>{{ result.Gap > 0 ? '+' + formatTime(result.Gap) : formatTime(result.Time) }}</b>
            </td>
          </tr>


        </tbody>
      </table>

      <v-divider></v-divider>
      <v-card-actions class="pb-0 pr-0">
        <v-spacer></v-spacer>
        <v-btn @click="dlgDialog=true" text>Show all</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import genericResultService from "@/services/genericResultService.js";
import crTools from "@/services/crTools.js";

export default {
  components: {},

  data() {
    return {
      dlgDialog: false
    }
  },

  props: ['data'],

  computed:
  {
    speed: function()
    {
      if (this.data && this.data.Results && this.data.Results.length>1 && this.data.Results[0].Pos>0)
      {
        const first = this.data.Results[0]

        return (Math.round( this.data.Distance / (first.Time /1000.0) * 3.6 *100) / 100.0).toFixed(2)+'km/h'
      }

      return null
    }
  },

  mounted: function () {

  },
  methods: {
    formatTime(time) {
      return crTools.formatTime(time, true)
    },
    formatDistance(distance) {
      return crTools.formatDistance(distance)
    },

    dlgClick(btn) {
      this.dlgDialog = false
    }

  }
}
</script>

<style scoped></style>
