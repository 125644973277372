<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import config from './configs'

import {loadLocaleMessagesAsync} from './i18n.js';

import  'mapbox-gl/dist/mapbox-gl.css';

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
*/
export default {
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ]
  }
  ,methods:
  {
    loadLocaleMessages(locale) {
      this.isLoading = true
      loadLocaleMessagesAsync(locale).then(() => {
        console.log("load locale done");
        this.isLoading = false
      });
    }
  },
  watch:{
    "$i18n.locale": function (newLocale, oldLocale) {
        if (newLocale == oldLocale) {
            return;
        }
        console.log("Locale set to: " + newLocale + " (" + oldLocale + ")");
        this.loadLocaleMessages(newLocale);
    }

  }
  ,beforeMount()
  {
    const urlParams = new URLSearchParams(window.location.search);
    var lng=urlParams.get('lng');
    if (lng) this.$i18n.locale=lng;
    else this.$i18n.locale='FR';
    
  }
  ,mounted() 
  {
    console.log("LOCALE URL query inmount ",this.$route.query,this.$route.query["lng"])    

    console.log("load locale - "+this.$i18n.locale);
    this.loadLocaleMessages(this.$i18n.locale);
  }  

}
</script>
