<template>
    <div
      id="mapContainer"
      ref="mapContainer"
      style="width: 100%; height: 100vh;"
    >
    </div>
</template>

<script>
import eventService from "@/services/eventService.js";
import eventTrackingService from "@/services/eventTrackingService.js";

import appSettingsService from "@/appSettingsService";

import polyline from "@/services/polyline";
import tracktools from "@/services/trackTools";
import mapboxgl from "mapbox-gl/dist/mapbox-gl.js";



import { _ } from "vue-underscore";

export default {
  props: ["reportList"],

  data() {
    return {
      loading: false,
      cfg:null
    };
  },
  computed: {},

  watch: {},

  beforeDestroy() {
    this.$parent.$parent.fullScreen=false;
    clearInterval(this.timerInterval);
  },
  mounted() {
    mapboxgl.accessToken = appSettingsService.get("mapbox");
    
    this.$parent.$parent.fullScreen=true;
    console.log("parent: ",this.$parent.$parent.eventView)
    
    var eventId = this.$route.params.eventId;

    eventTrackingService.getEventTrackingConfiguration(eventId).then(cfg=>{
      this.cfg=cfg;
      this.buildMap(cfg);

      this.timerInterval = setInterval(() => this.updateTrackerData(), 5000);
    });
   

  /*  setTimeout(() => {
      
    }, 0);*/
  },
  methods: {
    updateTrackerData()
    {
      var eventId = this.$route.params.eventId;
      console.log("update tracker data ",eventId);
      
      eventTrackingService.getTrackersData(eventId).then(data=>{        
        console.log("Tracker data ",data)

        this.trackersSource.features = [];


        for (var k in data)
        {
          var relatedCfg=this.cfg.Trackers[k];
          if (relatedCfg)
          {
            var trackerData=data[k];

            console.log("lala ",trackerData,relatedCfg)

            let toAdd = {
              id: k,
              type: "Feature",
              properties: {
                // "name": (i % 4 == 0 ? pt.distance / 1000.0 + "km" : ""),
                description: k+" - "+relatedCfg.DisplayName,
                icon: relatedCfg.Marker,
                name: relatedCfg.DisplayName,
                xbearing: 0,
              },
              geometry: {
                type: "Point",
                coordinates: [trackerData.Lon, trackerData.Lat],
              },
            };
            this.trackersSource.features.push(toAdd);
          }

        };

        this.map.getSource("trackersSource").setData(this.trackersSource);
      });
    },

    addImage(url, name) {

      return new Promise((resolve, reject) => {
        this.map.loadImage(url, (error, image) => {
          if (error) {
            //console.log("LOAD IMAGE ERRROR");
            console.log(error);
            reject(error);
            throw error;
          }
          //console.log("AddImage success");
          this.map.addImage(name, image);
          //console.log("image "+url+" loaded")
          resolve(url);
        });

      });
    },

    buildTrackData(cfg) 
    {
      console.log("build: ",cfg)

      var bounds = new mapboxgl.LngLatBounds();

      var trackSource = {
        type: "FeatureCollection",
        features: [],
      };          
      
      var poiSource = {
        type: "FeatureCollection",
        features: [],
      };

      this.trackersSource = {
        type: "FeatureCollection",
        features: [],
      };




      var distanceSource = null;

      for (var ix in cfg.Tracks) {
        var track=cfg.Tracks[ix];
        var pts = polyline.decode(track.Polylines[0]);
        var tk = tracktools.pointsAsTrack(pts);      

        pts.forEach(function (pt) {
          
          bounds.extend(pt);
        });

        var trackFeature = {
          type: "Feature",
          properties: {
            color: track.Color,
          },
          geometry: {
            type: "LineString",
            coordinates: pts,
          },
        };

        trackSource.features.push(trackFeature);

        //var tk = tracktools.pointsAsTrack(pts);
        distanceSource = tracktools.buildDistanceSource(
          this.map,
          tk,
          distanceSource
        );


        var trackFeature = {
          type: "Feature",
          properties: {
            color: track.Color,
          },
          geometry: {
            type: "LineString",
            coordinates: pts,
          },
        };

      }



      cfg.POI.forEach(poi=>{
        console.log("POI: ",poi)
        var pt=[poi.Lon, poi.Lat];

        bounds.extend(pt);

        let toAdd = {
          id: poi.Name,
          type: "Feature",
          properties: {
            // "name": (i % 4 == 0 ? pt.distance / 1000.0 + "km" : ""),
            name: poi.Name,
            bearing: 0,
//            icon: poi.Icon,
            icon: poi.Icon,
          },
          geometry: {
            type: "Point",
            coordinates: pt,
          },
        };

        poiSource.features.push(toAdd);
      });


      this.map.addSource("trackSource", {
        type: "geojson",
        data: trackSource,
      });
      
      
      this.map.addSource("poiSource", {
        type: "geojson",
        data: poiSource,
      });

      //console.log("track source added");
      this.map.addLayer({
        id: "track",
        type: "line",
        source: "trackSource",
        paint: {
          "line-color": "white",
          "line-width": 6,
        },
      });
      this.map.addLayer({
        id: "tracksLayer",
        type: "line",
        source: "trackSource",

        paint: {
          "line-color": ["get", "color"],
          "line-dasharray": [3, 1.5],
          "line-width": 2.5,
        },
      });
      
      tracktools.addDistanceLayer(this.map, distanceSource);

      this.map.addLayer({
        id: "poiLayer",
        type: "symbol",
        source: "poiSource",
        xminzoom: 14,
        layout: {
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
          "icon-image": ["get", "icon"],
          "icon-rotation-alignment": "map",
          "icon-size": 0.4,
          "icon-offset": [0, 0],
          "text-field": ["get", "name"],
          "text-size": 14,
          "text-font": ["Arial Unicode MS Bold"],
          "text-offset": [0, -0.65],
          "text-anchor": "top",
        },
        paint: {
          "text-color": "#ffffff",
        },
      });


      this.map.fitBounds(bounds, { duration: 0 });


      this.map.addSource("trackersSource", {
        type: "geojson",
        data: this.trackersSource,
      });

      this.updateTrackerData();


      //this.addControls();

      this.map.addLayer({
        id: "trackersLayer",
        type: "symbol",
        source: "trackersSource",
        xminzoom: 14,
        layout: {
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
          "icon-image": ["get", "icon"],
          "icon-rotate": ["get", "bearing"],
          "icon-rotation-alignment": "map",
          "icon-size": 0.3,
          "icon-offset": [0, -48],
          "text-field": ["get", "name"],
          "text-size": 14,
          "text-font": ["Arial Unicode MS Bold"],
          "text-offset": [0, -2.3],
          "text-anchor": "top",
        },
        paint: {
          "text-color": "black",
        },
      });
    },

    buildMap(cfg) {
      this.map = new mapboxgl.Map({
        container: "mapContainer",
        style: "mapbox://styles/chronorace/ckli3u1v60xaj17k3583i2f42",
        //center: [first.lon, first.lat ],
        zoom: 16,
        /*xmaxBounds: [
                [minLon-0.25, minLat-0.25],
                [maxLon+0.25, maxLat+0.25],
            ],*/
      });

      //this.map.addControl(new mapboxgl.AttributionControl(), 'top-left');
      this.map.addControl(new mapboxgl.FullscreenControl());
      this.map.addControl(new mapboxgl.NavigationControl());

      this.map.on("load", () => {
        this.map.on("styleimagemissing", function (e) {
          var id = e.id; // id of the missing image
          console.log("missing image: " + id);
        });

        var images=[];
        images.push(this.addImage("/markers/check_pending.png", "yellow"));
        images.push(this.addImage("/markers/check_succeeded.png", "green"));
        images.push(this.addImage("/markers/checkpending.png", "pending"));
        images.push(this.addImage("/markers/checkwarning.png", "warning"));
        images.push(this.addImage("/markers/up.png", "up"));
        images.push(this.addImage("/markers/km.png", "km"));
        images.push(this.addImage("/markers/me.png", "me"));
        images.push(this.addImage("/markers/POI.png", "POI"));
        images.push(this.addImage("/markers/s01.png", "s01"));
        images.push(this.addImage("/markers/s02.png", "s02"));
        images.push(this.addImage("/markers/s03.png", "s03"));
        images.push(this.addImage("/markers/s04.png", "s04"));
        images.push(this.addImage("/markers/s05.png", "s05"));
        images.push(this.addImage("/markers/s06.png", "s06"));
        images.push(this.addImage("/markers/s07.png", "s07"));
        images.push(this.addImage("/markers/s08.png", "s08"));
        images.push(this.addImage("/markers/s09.png", "s09"));
        images.push(this.addImage("/markers/s10.png", "s10"));
        images.push(this.addImage("/markers/s11.png", "s11"));
        images.push(this.addImage("/markers/s12.png", "s12"));
        images.push(this.addImage("/markers/s13.png", "s13"));
        images.push(this.addImage("/markers/s14.png", "s14"));
        images.push(this.addImage("/markers/s15.png", "s15"));
        images.push(this.addImage("/markers/s16.png", "s16"));
        images.push(this.addImage("/markers/s17.png", "s17"));
        images.push(this.addImage("/markers/s18.png", "s18"));
        images.push(this.addImage("/markers/s19.png", "s19"));
        images.push(this.addImage("/markers/s20.png", "s20"));

        Promise.all(images).then(success=>{
          console.log("load images success ",success)

          this.buildTrackData(cfg);
          console.log("buildmap",cfg)


        },error=>{
          console.log("Load images failed",error)
        });



        console.log("event tracking config: ",cfg);

        //setTimeout(()=>this.loadDetails(),1500);


      });

    },

    ready() {

    },

  },
};
</script>
