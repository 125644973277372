<template>
  <span v-if="!hideResultHeader">
    <div  id="headerimage" :style="headerStyle" >
      <v-container
        style="max-width: 1150px; xbackground-color: #cccccc; padding: 0"
      >
        <v-responsive :aspect-ratio="16 / 3">
          <img
            v-if="eventView.Parameters.ResultHeaderLogo"
            :src="eventView.Parameters.ResultHeaderLogo"
            style="
              position: absolute;
              xvisibility: hidden;
              top: 50%;
              transform: translate(-50%, -50%);
              left: 50%;
              height: 100%;
              max-width: 100%;
              object-fit: contain;
            "
          />
        </v-responsive>
      </v-container>
    </div>

    <div
      v-if="eventView"
      class="grey lighten-4"
      style="
        xborder-style: solid none solid none;
        xborder-width: 1px !important;
        xborder-color: #999999 !important;
        position: relative;
      "
    >
      <v-container
        class="pt-1 pb-1"
        style="max-width: 1150px; xbackground-color: orange"
      >
        <div class="header">
          <div class="text-h5">{{ eventView.Title }}</div>
          {{ eventView.Location }}, {{ eventView.Date }},
          {{ eventView.Country }}
        </div>

        <v-sheet
          class="header-image hidden-xs-only"
          color="white"
          width="150px"
          height="150px"
          elevation="4"
          rounded="lg"
          v-if="eventView.Parameters.ResultEventLogo"
          style="
            position: absolute;
            xmargin-left: 16px;
            bottom: 8px;
            xleft: 50%;
            xtransform: translate(-50%, 0);
          "
        >
          <v-img
            xcontain            
            :src="eventView.Parameters.ResultEventLogo"
            width="134px"
            height="134px"
            style="margin: 8px"
          ></v-img>
        </v-sheet>
      </v-container>
    </div>

    <!-- <div>
      <pre>{{ eventView }}</pre>
    </div> -->
  </span>
</template>

<script>
import { _ } from "vue-underscore";

export default {
  props: ["eventView"],
  components: {},
  data() {
    return {};
  },
  computed: {
    hideResultHeader:function()
    {
      //console.log("eventview",this.eventView);
      if (!this.eventView || !this.eventView.Parameters) return true;
      if (this.eventView.Parameters.HideResultHeader && this.eventView.Parameters.HideResultHeader=='1') return true;
      return false;
    },
    headerStyle:function() 
    {

      var toRet={};
      //console.log("eventView",this.eventView)
      if (this.eventView && this.eventView.Parameters && this.eventView.Parameters.ResultHeaderImage)
      {
        toRet["background-image"]="url('"+this.eventView.Parameters.ResultHeaderImage+"')"; 
      }
     console.log('HeaderStyle',toRet)
      return toRet;
      //{background-image: url('https://chronorace.blob.core.windows.net/webresources/assets/kotk/kotk_image.jpg')
    }
  },
  methods: {},
  watch: {},

  beforeDestroy() {},
  updated() {},
  mounted() {},
};
</script>

<style scoped>
#headerimage {
  xbackground-image: url("https://chronorace.blob.core.windows.net/webresources/assets/kotk/kotk_image.jpg");
  xbackground: rgb(224, 224, 224);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 24%,
    rgba(150, 150, 150, 1) 100%
  );
  background-position: center;
  background-size: cover;
}

.header {
  border-left: 4px solid #39aade;
  padding-left: 12px;
  margin-left: 174px;
}

@media only screen and (max-width: 600px) {
  .header {
    text-align: center;
    margin-left: 0px;
    padding-left: 0px;
    border-left: 0px;
  }
}
</style>