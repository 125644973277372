<template>
  <v-footer app >
    <v-container>
      <!-- <div class="d-flex flex-wrap align-center justify-center font-weight-bold text-uppercase body-2">
        <router-link to="#" class="text-decoration-none mx-2 mb-1">Services</router-link>
        <router-link to="#" class="text-decoration-none mx-2 mb-1">Products</router-link>
        <router-link to="#" class="text-decoration-none mx-2 mb-1">Pricing</router-link>
        <router-link to="#" class="text-decoration-none mx-2 mb-1">About us</router-link>
      </div> -->
      <div class="d-flex align-center justify-center mt-0">
        <v-icon>mdi-email-outline</v-icon>
        <span class="ml-1">
          <a href="mailto:info@acn-timing.com" class="text-decoration-none">info@acn-timing.com</a>
        </span>

        <!-- <v-btn icon color="secondary lighten-2" class="mx-1"><v-icon>mdi-twitter</v-icon></v-btn>
        <v-btn icon color="secondary lighten-2" class="mx-1"><v-icon>mdi-linkedin</v-icon></v-btn>
        <v-btn icon color="secondary lighten-2" class="mx-1"><v-icon>mdi-google-plus</v-icon></v-btn> -->
      </div>
      <div class="d-flex flex-column flex-sm-row align-center justify-center mt-4 overline">
        <div class="secondary--text">© ACN-TIMING 2021</div>
        <router-link to="/privacy" class="text-decoration-none ml-sm-2 secondary--text text--lighten-3">Politique de confidentialité</router-link>
        <!-- <router-link to="/cgv" class="text-decoration-none ml-sm-2 secondary--text text--lighten-3">Conditions générales de vente</router-link> -->
      </div>
    </v-container>
  </v-footer>
</template>

<script>

export default {
  components: {    
  }
}
</script>
