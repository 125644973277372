<template>
  <span>    


   <v-container
      class="pt-2 pb-2 results"
      style="max-width: 1150px; xbackground-color: orange" >    

      <v-card class="py-2 px-0">
        <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr class="light-blue accent-1">
                    <th>{{ $t("CHECKPOINT") }}</th>
                    <th>{{ $t("DISTANCE") }}</th>
                    <th>{{ $t("HEURE") }}</th>
                    <th>{{ $t("TEMPS") }}</th>
                    <th>{{ $t("POS") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="split in details.Splits" :key="split.Location">
                    <td class="text-right">
                      {{split.DisplayName}}
                    </td>
                    <td class="text-center">
                      <span v-if="split.ShowDistance">{{formatDistance(split.Distance)}}</span>
                      <span v-else>-</span>
                    </td>
                    <td class="text-right">
                      {{formatTime(split.Finish)}}
                    </td>
                    <td class="text-right">
                      <b v-if="split.ShowTime && split.Time>0">{{formatTime(split.Time)}}</b>
                      <span v-else>-</span>
                    </td>
                    <td class="text-right">
                      <span v-if="split.ShowRanking">{{formatPos(split.Pos)}}</span>
                      <span v-else>-</span>
                    </td>
                  </tr>
                  </tbody>  
              </template>
            </v-simple-table> 
          
        </v-card-text>  
      </v-card> 
    </v-container>  

  </span>

</template>

<script>

import { _ } from "vue-underscore";

import crTools from "@/services/crTools.js";

export default {
  props: ["feature","details"],
  methods: {    
    formatDistance(distance) {
      return crTools.formatDistance(distance);
    },

    formatTime(input) {
      return crTools.formatTime(input);
    },

    formatPos(input) {
      if (input=='-3') return '-';
      if (input=='-1') return 'DSQ';
      if (input>0) return input;
      return '-';
    },

  },
  data() {
    return {
    };
  },
  computed: {},
  watch: {

  },

  beforeDestroy() {},
  updated() {},
  mounted() {
  },
};
</script>
