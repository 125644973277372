<template>
  <span>
    <Loading v-if="loading"></Loading>
    <router-view :report-list="reportList"></router-view>
    <v-card-text v-if="!loading && $route.name == 'reportList'">
      <v-row dense>
        <v-col
          v-for="(grp, i) in reportList"
          :key="i"
          cols="12"
          sm="6"
          lg="4"
          xl="4"
        >
          <v-card>
            <v-card-title>{{ grp.DisplayName }}</v-card-title>
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, i) in grp.Reports"
                  :key="i"
                  @click="selectItem(grp, item)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="getIcon(item)"></v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      v-text="$tcond(item.DisplayName)"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </span>
</template>

<script>
import table from '@/data/test.json'
import eventService from '@/services/eventService.js'

export default {
  props: ['eventView'],

  data() {
    return {
      table,
      loading: false,
      reportList: []
    }
  },
  mounted() {
    console.log('report list mounted: ', this.eventView)
    this.loading = true
    eventService.getReportList(this.eventView.EventId).then((lst) => {
      console.log(lst)
      this.loading = false
      this.reportList = lst
    })
  },
  methods: {
    getIcon(report) {
      switch (('' + report.ReportType).toLowerCase()) {
      case 'link':
      case 'url':
        return 'mdi-link'

      case 'file':
        switch (report.Settings.FileType.toLowerCase()) {
        case 'pdf':
          return 'mdi-file-pdf-box'

        case 'xls':
          return 'mdi-file-excel'

        default:
          return 'mdi-file'
        }

      default:
        return 'mdi-format-list-bulleted'
      }
      console.log(report)
    },
    selectItem(grp, item) {
      switch (('' + item.ReportType).toLowerCase()) {
      case 'url':
      case 'link':
        if (item.Settings.Link) window.open(item.Settings.Link)
        break

      case 'file':
        window.location.href =
            'https://crdev.blob.core.windows.net/files/' +
            item.Settings.FileName

        return

      case 'table':
      case '13':
        this.selectedGroup = grp
        this.selectedReport = item
        //report', path: 'group/:group/report/:report
        this.$router.push({
          name: 'table',
          params: { group: grp.Id, report: item.Id }
        })
        break



      case 'time':
      case 'xml':
        this.selectedGroup = grp
        this.selectedReport = item
        //report', path: 'group/:group/report/:report
        this.$router.push({
          name: 'report',
          params: { group: grp.Id, report: item.Id }
        })
        break

      default:
        console.log('select...', grp, item)

        break
      }
    },
    sortTest1() {
      this.table.Groups.forEach((g) => {
        g.SlaveRows.sort((a, b) => {
          if (a[1] > b[1]) return 1
          if (a[1] < b[1]) return -1

          return 0
        })
      })
    },
    sortTest2() {
      this.table.Groups.forEach((g) => {
        g.SlaveRows.sort((a, b) => {
          if (a[4] > b[4]) return 1
          if (a[4] < b[4]) return -1

          return 0
        })
      })
    }
  }
}
</script>
