import settings from './appSettings.json'
import Vue from 'vue';

const appSettingsService =new class
{

    get(setting) {
        if (!this.envSettings) this.setEnv("prod");
        return this.envSettings[setting];
    };

    set(setting,value) {
        if (!this.envSettings) this.setEnv("prod");
        this.envSettings[setting]=value;
    };

    getEnv()
    {
        return this.env;
    }

    setEnv(value)
    {
        console.log("appSettingsService set env to: "+value);
        this.envSettings=settings[value];
        this.env=value;
    };
   
    constructor()
    {
    }    
} 


export default appSettingsService;