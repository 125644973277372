import axios from 'axios'
import appSettingsService from '../appSettingsService'

const eventService = new (class {
  //[Route("details/{db}/{type}/{table?}/{key?}")]
  getDetail(db, type, table, key) {
    if (!type) type = 'details'

    return new Promise((resolve, reject) => {
      const url =
        appSettingsService.get('apiUrlBase') +
        'results/table/details/' +
        encodeURIComponent(db) +
        '/' +
        encodeURIComponent(type) +
        '?table=' +
        encodeURIComponent(table) +
        '&key=' +
        encodeURIComponent(key)

      console.log('url', url)

      axios
        .get(url)
        .then((httpReply) => {
          resolve(httpReply.data)
        })
        .catch((error) => {
          if (error.response) {
            reject({ errorMessage: 'HTTP_' + error.response.status })
          } else {
            reject(error)
          }
        })
    })
  }

  getObjectDetail(db, type, table, token) {
    //if (!type) type = 'details'

    return new Promise((resolve, reject) => {
      var url =
        appSettingsService.get('apiUrlBase') +
        'results/table/objectdetails/' +
        encodeURIComponent(db)+'?';

      if (type) url=url+'type='+encodeURIComponent(type);
      var decodedToken=window.atob(token);

      if (decodedToken) url=url+decodedToken+'&';
      
    /*  var qvars=decodedToken.split('&');
      for (var i in qvars)
      {
        var subtokens=qvars[i].split('=');
        url=url+subtokens[0]+'='+subtokens[1];
        console.log("lala",subtokens[0],subtokens[1])
      }*/

      console.log('decoded token', decodedToken)

      axios
        .get(url)
        .then((httpReply) => {
          resolve(httpReply.data)
        })
        .catch((error) => {
          if (error.response) {
            reject({ errorMessage: 'HTTP_' + error.response.status })
          } else {
            reject(error)
          }
        })
    })
  } 

  searchTable(db, key, srch, opt) {
    return new Promise((resolve, reject) => {
      let url =
        appSettingsService.get('apiUrlBase') +
        'results/table/search/' +
        encodeURIComponent(db) +
        '/' +
        encodeURIComponent(key) +
        '?srch=' +
        encodeURIComponent(srch)

      if (opt && opt.from) {
        url = url + '&fromRecord=' + opt.from
      }
      if (opt && opt.pageSize) {
        url = url + '&pageSize=' + opt.pageSize
      }
      if (opt && opt.favorites && opt.favorites.length>0)
      {
        url=url+'&favorites='+opt.favorites.join(',',opt.favorites);
      }
      if (opt.expanded && opt.expanded.length > 0) {
        const grps = opt.expanded.join('\t')

        url = url + '&expanded=' + encodeURIComponent(grps)
      }
      console.log('url', url)

      axios
        .get(url)
        .then((httpReply) => {
          resolve(httpReply.data)
        })
        .catch((error) => {
          reject(error);
          /*if (error.response) {
            reject({ errorMessage: 'HTTP_' + error.response.status })
          } else {
            reject(error)
          }*/
        })
    })
  }

  searchReport(eventId, reportId, from, cnt, srch) {
    return new Promise((resolve, reject) => {
      const url =
        appSettingsService.get('apiUrlBase') +
        'results/report/search/' +
        eventId +
        '/' +
        reportId +
        '/' +
        from +
        '/' +
        cnt +
        '?compact=true&srch=' +
        encodeURIComponent(srch)

      axios
        .get(url)
        .then((httpReply) => {
          const toRet = {
            TableDefinition: httpReply.data.TableDefinition,
            Count: httpReply.data.Count,
            Groups: [
              {
                Id: '',
                Count: httpReply.data.Count,
                SlaveRows: httpReply.data.CompactMatches
              }
            ]
          }

          resolve(toRet)
        })
        .catch((error) => {
          if (error.response) {
            reject({ errorMessage: 'HTTP_' + error.response.status })
          } else {
            reject(error)
          }
        })
    })
  }

  getReportList(eventId) {
    return new Promise((resolve, reject) => {
      const url =
        appSettingsService.get('apiUrlBase') + 'results/report/list/' + eventId

      axios
        .get(url)
        .then((httpReply) => {
          resolve(httpReply.data)
        })
        .catch((error) => {
          if (error.response) {
            reject({ errorMessage: 'HTTP_' + error.response.status })
          } else {
            reject(error)
          }
        })
    })
  }
  getEventView(eventId) {
    return new Promise((resolve, reject) => {
      const url = appSettingsService.get('apiUrlBase') + 'Event/view/' + eventId

      axios
        .get(url)
        .then((httpReply) => {
          console.log('reply', httpReply)

          resolve(httpReply.data)
        })
        .catch((error) => {
          if (error.response) {
            reject({ errorMessage: 'HTTP_' + error.response.status })
          } else {
            reject(error)
          }
        })
    })
  }

  constructor() {}
})()

export default eventService
