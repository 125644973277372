import Vue from 'vue'
import App from './App.vue'

import VueYoutube from 'vue-youtube'
import i18n from './i18n'

// VUEX - https://vuex.vuejs.org/
import store from './store'

// VUE-ROUTER - https://router.vuejs.org/
import router from './router'

// PLUGINS
import vuetify from './plugins/vuetify'
import './plugins/vue-head'
// import './plugins/vue-gtag'

// FILTERS
import './filters/capitalize'
import './filters/lowercase'
import './filters/uppercase'
import './filters/placeholder'
import './filters/trim'
import './filters/formatDate'

// STYLES
// Main Theme SCSS
import './assets/scss/theme.scss'

import './crComponents.js'

import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader


// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false

/*
|---------------------------------------------------------------------
| Main Vue Instance
|---------------------------------------------------------------------
|
| Render the vue application on the <div id="app"></div> in index.html
|
| https://vuejs.org/v2/guide/instance.html
|
*/
export default new Vue({
  vuetify,
  router,
  i18n,
  store,
  render: (h) => h(App)
}).$mount('#app')

Vue.use(
  {
    install(Vue, options) {
      console.log("install $tcond");
      Vue.prototype.$tcond = (val) => {
        if (!val) return val;
        if (val.startsWith('#')) return i18n.t(val.substring(1));
        return val;
      }
    }
  }
);

Vue.use(VueYoutube);



