<script>
export default {
  functional: true,
  props: ['row', 'definition', 'table-definition', 'context'],
  data() {
    return {}
  },
  beforeMount: function() {},
  mounted: function() {},
  render: (h, { props, data, children, listeners }) => {
    const rowClick = (event) => {
      if (listeners.action) listeners.action({ type: 'row', row: props.row })
    }

    const childs = []

    const styleCol = props.tableDefinition.SortedColumns['sH_RowClass']
    const classes = []

    if (styleCol) {
      const className = props.row[styleCol.FieldIdx]

      if (className && className != '') classes.push(className)
    }

    props.definition.forEach((col) => {
      const colClick = (evt) => {
        const newEvt = { ...evt, column: col, row: props.row }

        if (listeners.action) listeners.action(newEvt)
      }

      //console.log("cool? ",col)
      if (col.Type) {
        const rowData = {
          props: {
            row: props.row,
            column: col,
            context: props.context,
            definition: props.definition
          },
          on: {
            action: colClick
          }
        }

        const outer = h('cr-' + col.Type, rowData, col.type)
        childs.push(outer)
      } else {
        childs.push(h('td', { attrs: { colspan: col.Colspan } }))
      }
    })



    classes.push('last-record-line')
    
    const toRet = [
      h(
        'tr',
        {
          on: {
            click: rowClick
          },
          attrs: {
            class: classes.join(' ')
          }
        },
        childs
      )
    ]

    return toRet
  }
}
</script>
