<template>
  <v-container
    class="pt-2 pb-2"
    style="max-width: 1150px; xbackground-color: orange"
  >
    <v-row dense>
      <v-col cols="7">
        <v-card>
          <v-card-text> hello </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="5">
        <v-card>
          <v-card-title> Organisateur </v-card-title>
          <v-card-text> kljgklfd gjkdf jkl </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import eventService from "@/services/eventService.js";

import appSettingsService from "@/appSettingsService";

import { _ } from "vue-underscore";

export default {
  props: ["reportList"],

  data() {
    return {
      loading: false,
    };
  },
  computed: {},

  watch: {},

  beforeDestroy() {},
  mounted() {},
  methods: {
    ready() {},
  },
};
</script>
