var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"container",staticStyle:{"width":"100%"}},[(_vm.data)?_c('table',{staticClass:"data-table"},[_c('thead',[(_vm.groupCols != null)?_c('tr',_vm._l((_vm.groupCols),function(grp,i){return _c('th',{key:i,class:{
            groupCol: grp.Type != 'Spacer',
            spacerCol: grp.Type == 'Spacer'
          },attrs:{"colspan":grp.Colspan}},[_vm._v(" "+_vm._s(_vm.$tcond(grp.GroupDisplayName))+" ")])}),0):_vm._e(),_c('tr',_vm._l((_vm.definition),function(col,i){return _c('th',{key:i},[_vm._v(" "+_vm._s(_vm.$tcond(col.DisplayName))+" ")])}),0)]),_vm._l((_vm.data.Groups),function(chunk,i){return [(
          _vm.masterDefinition != null &&
            chunk.MasterRows &&
            chunk.MasterRows.length > 0
        )?_c('tbody',{key:'mas_' + i,staticClass:"table-master"},_vm._l((chunk.MasterRows),function(masterRow,masterIdx){return _c('cr-row',{key:masterIdx,attrs:{"row":masterRow,"context":_vm.context,"definition":_vm.masterDefinition,"table-definition":_vm.data.MasterDefinition}})}),1):_vm._e(),(_vm.definition != null)?_c('tbody',{key:'slv_' + i,staticClass:"table-data"},_vm._l((chunk.SlaveRows),function(slaveRow,slaveIdx){return _c('cr-row',{key:slaveIdx,attrs:{"row":slaveRow,"context":_vm.context,"definition":_vm.definition,"table-definition":_vm.data.TableDefinition},on:{"action":_vm.rowAction}})}),1):_vm._e(),(chunk.SlaveRows.length < chunk.Count && !_vm.data.PagingAllowed)?_c('tbody',{key:'stat_' + i},[_c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":_vm.colCount}},[_c('a',{on:{"click":function($event){return _vm.groupAction(chunk, 'grpshow')}}},[_vm._v(_vm._s(_vm.$t('SHOW'))+" "),_c('b',[_vm._v(_vm._s(chunk.Count))]),_vm._v(" "+_vm._s(_vm.$t('ENTRIES')))])])])]):_vm._e(),_c('tbody',{key:'sep_' + i,staticClass:"table-spacer"},[_vm._m(0,true)])]})],2):_vm._e(),_c('v-row',{staticClass:"mb-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"9"}}),_c('v-col',{staticClass:"text-right",attrs:{"cols":"3"}},[_c('b',[_vm._v(_vm._s(_vm.data.Count))]),_vm._v(" "+_vm._s(_vm.$t('ENTRIES')))])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"colspan":"3"}})])}]

export { render, staticRenderFns }