<template>
  <div>
    <v-dialog v-if="details" v-model="dlgDialog" persistent max-width="800px">
      <v-card>
        <span v-if="details.youtube && details.youtube.length > 0">
          <v-card-title>VIDEOS</v-card-title>

          <v-card-text>
            Veuillez cliquer sur 1 des boutons ci-dessous afin de visualiser la
            video au point correspondant
          </v-card-text>
          <v-card-text>
            <v-btn
              v-for="(video, i) in details.youtube"
              :key="i"
              @click="startVideo(video)"
            >{{ video.DisplayName }}</v-btn>
          </v-card-text>
          <div>
            <youtube
              ref="youtube"
              :video-id="currentVideo.VideoId"
              fit-parent
              :resize-delay="200"
              @playing="playing"
              @ready="ready"
            ></youtube>
          </div>
        </span>
        <v-card-title>
          TEMPS DE PASSSAGE
        </v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Lieu</th>
                  <th>Pos</th>
                  <th>Categ</th>
                  <th>Temps</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(split, i) in details.splits" :key="i">
                  <td>
                    <b>{{ split.Location }}</b>
                  </td>
                  <td class="text-center">{{ split.Pos }}</td>
                  <td class="text-center">{{ split.PosCateg }}</td>
                  <td class="text-rght">
                    <b>{{ split.Temps }}</b>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dlgClick('OK')">{{ $t('OK') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <span v-if="curReport != null">
      <v-breadcrumbs divider="-">
        <v-breadcrumbs-item exact :to="{ name: 'reportList' }">{{
          $t('REPORTLIST')
        }}</v-breadcrumbs-item>
        <v-breadcrumbs-item>{{ $tcond(curGroup.DisplayName) }} -
          {{ $tcond(curReport.DisplayName) }}</v-breadcrumbs-item>
      </v-breadcrumbs>

      <v-card-text v-if="tables != null">
        <v-row dense>
          <v-col md="6" cols="12">
            <v-select
              v-if="tables && tables.length > 1"
              v-model="selectedTable"
              :items="tables"
              return-object
              single-line
              filled
              item-text="DisplayName"
            >
            </v-select>
          </v-col>
          <v-col md="6" cols="12">
            <v-form @submit="updateSearchResult(search)">
              <v-text-field
                v-model="search"
                :loading="searching"
                :append-icon="'mdi-magnify'"
                type="text"
                :label="$t('SEARCH')"
                filled
                clearable
                placeholder="nom, dossard,..."
                @click:append="updateSearchResult(search)"
                @click:clear="updateSearchResult('')"
              ></v-text-field>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <span v-if="!loading">
          <cr-table
            v-if="data && data.Count > 0"
            :data="data"
            @action="rowClick"
          ></cr-table>
          <span v-else v-html="$t('NORESULT')"></span>

          <br /><br />
          <v-pagination
            v-if="data && data.Count > 0 && data.PagingAllowed"
            v-model="page"
            :total-visible="7"
            :length="pageCount"
            @input="setPage"
          ></v-pagination>
        </span>
        <Loading v-if="loading"></Loading>
      </v-card-text>
    </span>
  </div>
</template>

<script>
import eventService from '@/services/eventService.js'

import { _ } from 'vue-underscore'

export default {
  props: ['reportList'],

  data() {
    return {
      currentVideo: {},
      seekDone: false,

      tableList: [],
      expandedGroups: [],
      selectedTable: null,

      loading: false,
      curGroup: null,
      searching: false,
      page: 1,
      curReport: null,
      data: null,
      search: '',
      details: null,
      usedSearch: '',
      pageSize: 100,
      interval: null,
      liveEnabled: false,
      dlgDialog: false
    }
  },
  computed: {
    pageCount: function() {
      if (!this.data) return 0

      return Math.trunc((this.data.Count + (this.pageSize - 1)) / this.pageSize)
    },
    player: function() {
      if (this.$refs.youtube) return this.$refs.youtube.player

      return null
    }
  },
  watch: {
    reportList: function(o, n) {
      console.log('report list wxatch called')
      this.updateReport()
    },
    selectedTable: function(o, n) {
      this.loading = true
      console.log('SELECTED TABLE CHANGED: ', o, n)
      this.stopLive()
      this.search = ''
      this.updateSearchResult('')
    }
  },
  beforeDestroy() {
    console.log('UNMOUNTED CALLED')
    this.stopLive()
  },
  mounted() {
    this.updateReport()
  },
  methods: {
    ready() {
      console.log('youtube player ready')
    },
    playing(e) {
      console.log('youtube status', e)
      if (!this.seekDone) {
        //this.player.seekTo(3600, true)
        this.player.seekTo(this.currentVideo.VideoStart, true)
        this.seekDone = true
      }
    },

    startVideo(video) {
      console.log('set current video', video)
      this.currentVideo = video
      this.seekDone = false
      this.player.playVideo().then((done) => {
        console.log('play video done')
        this.player.seekTo(this.currentVideo.VideoStart, true)
      })
    },

    onResize(e) {
      console.log('on resize called in tableViewer', e.offsetWidth)

      this.width = e.offsetWidth
    },
    dlgClick(btn) {
      this.dlgDialog = false
    },
    rowClick(evt) {
      console.log('rowclick in viewer crtable: ', evt)
      //console.log(this.data);

      if (evt.action && evt.action === 'grpshow') {
        console.log('group action action', evt.grp)
        this.expandedGroups.push(evt.grp.Id)
        this.loading = true
        this.searchReport(this.usedSearch)

        return
      }

      const rowAction = this.data.TableDefinition.SortedColumns['sH_RowAction']


      if (rowAction) {
        const action = evt.row[rowAction.FieldIdx]

        //console.log("a gentle row action: ",evt.row[rowAction.FieldIdx]);
        if (action && action != '') {
          if (action.startsWith('detail:')) {
            console.log('detail popup action... ', action)

            const token = action.substring(7)

            //this.curReport.Settings.Db,
            //this.curReport.Settings.Table,
            //getDetail(db,type,table,key)
            eventService
              .getDetail(
                this.curReport.Settings.Db,
                'detail',
                this.selectedTable.Name,
                token
              )
              .then((details) => {
                this.details = details
                this.currentVideo = {}
                this.dlgDialog = true
              })
            console.log(token)
          } else if (action.startsWith('link:')) {
            const link = action.substring(5)

            console.log('link action... ', link)
            window.open(link)
          }
        }
      }
    },
    setPage(page) {
      console.log('PAGE CLICKED ', page, this.page)
      this.loading = true
      this.searchReport(this.usedSearch)
    },
    updateSearchResult(srch) {
      this.page = 1
      this.expandedGroups = []
      this.usedSearch = srch

      console.log('update search result', this.search, '[' + srch + ']')
      this.searchReport(this.usedSearch)
    },
    startLive() {
      console.log('LIVE ENABLED')
      let delay = this.data.Settings.LiveDelay * 1000

      if (delay < 5000) delay = 5000
      if (!this.interval) {
        this.interval = setInterval(() => {
       //   console.log('refresh???')
          if (this.searching) return
          this.searchReport(this.usedSearch)
        }, delay)
        this.liveEnabled = true
      }
    },
    stopLive() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
    searchReport(srch) {
      if (this.searching) return

      this.searching = true
      const settings = {
        from: (this.page - 1) * this.pageSize,
        pageSize: this.pageSize,
        expanded: this.expandedGroups
      }

      console.log('search... ', srch)
      eventService
        .searchTable(
          this.curReport.Settings.Db,
          this.selectedTable.Name,
          srch,
          settings
        )
        .then(
          (data) => {
            this.data = data
            this.loading = false
            this.searching = false

            if (this.data.Settings && this.data.Settings.Live) {
              this.startLive()
            }
            if (this.data.Settings && !this.data.Settings.Live) {
              this.stopLive()
            }
          },
          (error) => {
            this.liveEnabled = false
            this.loading = false
            this.searching = false

            console.log('error occured...', error)
            console.log('loading: ', this.loading)
          }
        )
    },
    sortTest1() {
      this.table.Groups.forEach((g) => {
        g.SlaveRows.sort((a, b) => {
          if (a[1] > b[1]) return 1
          if (a[1] < b[1]) return -1

          return 0
        })
      })
    },
    updateReport() {
      if (!this.reportList || this.reportList.length == 0) return

      this.curGroup = _.find(
        this.reportList,
        (grp) => grp.Id == this.$route.params.group
      )
      this.curReport = _.find(
        this.curGroup.Reports,
        (rpt) => rpt.Id == this.$route.params.report
      )

      this.tables = (
        this.curReport.Settings.Table || this.curReport.Settings.Tables
      )
        .split(',')
        .map((t) => {
          const toRet = {}

          const ix = t.indexOf(':')

          if (ix > 0) {
            toRet.Name = t.substring(0, ix)
            toRet.DisplayName = t.substring(ix + 1)
          } else {
            toRet.Name = t
            toRet.DisplayName = t
          }

          return toRet
        })

      this.selectedTable = this.tables[0]
      /*this.loading = true
      this.searchReport('')*/
    }
  }
}
</script>
