import Loading from './components/Loading.vue';
import Snackbar from './components/Snackbar.vue';

import Vue from 'vue';


import CrText from '@/components/table/crText.vue';
import CrRank from '@/components/table/crText.vue';
import CrImage from '@/components/table/crImage.vue';
import CrTable from '@/components/table/crTable.vue';
import CrCell from '@/components/table/crCell.vue';
import CrRow from '@/components/table/crRow.vue';
import CrTime from '@/components/table/crTime.vue';
import CrFavorite from '@/components/table/crFavorite.vue';
import CrSpacer from '@/components/table/crSpacer.vue';

Vue.component('CrTable',CrTable);
Vue.component('CrText',CrText);
Vue.component('CrRank',CrRank);
Vue.component('CrImage',CrImage);
Vue.component('CrCell',CrCell);
Vue.component('CrRow',CrRow);
Vue.component('CrFavorite',CrFavorite);
Vue.component('CrTime',CrTime);
Vue.component('CrSpacer',CrSpacer);
Vue.component('Loading',Loading);
Vue.component('Snackbar',Snackbar);

console.log("CrRow",CrRow);

export default {
    components: {
      Loading,Snackbar,CrTable,CrText,CrRank,CrImage,CrCell,CrRow,CrTime
    }
  }