import axios from 'axios'
import appSettingsService from '../appSettingsService'

const eventTrackingService = new (class {
  //[Route("details/{db}/{type}/{table?}/{key?}")]
   getEventTrackingConfiguration(eventId) {
    return new Promise((resolve, reject) => {
      const url = appSettingsService.get('apiUrlBase') + 'gps/config/' + eventId

      axios
        .get(url)
        .then((httpReply) => {      
          resolve(httpReply.data)
        })
        .catch((error) => {
          if (error.response) {
            reject({ errorMessage: 'HTTP_' + error.response.status })
          } else {
            reject(error)
          }
        })
    })
  }

  log(tracker,positions)
  {
    return new Promise((resolve, reject) => {
      const url = appSettingsService.get('apiUrlBase') + 'gps/log/' + tracker

      axios
        .post(url,positions)
        .then((httpReply) => {      
          resolve(httpReply.data)
        })
        .catch((error) => {
          if (error.response) {
            reject({ errorMessage: 'HTTP_' + error.response.status })
          } else {
            reject(error)
          }
        })
    })

  }

  getTrackersData(eventId)
  {
    return new Promise((resolve, reject) => {
      const url = appSettingsService.get('apiUrlBase') + 'gps/get/' + eventId

      axios
        .get(url)
        .then((httpReply) => {      
          resolve(httpReply.data)
        })
        .catch((error) => {
          if (error.response) {
            reject({ errorMessage: 'HTTP_' + error.response.status })
          } else {
            reject(error)
          }
        })
    })
  }

  constructor() {}
})()

export default eventTrackingService
