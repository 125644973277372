<template>
  <span>
    <CMSBreadCrumb :cms="node" :path="path"></CMSBreadCrumb>

    <router-view :cms="node" :path="path" :selection="selection"></router-view>

  </span>
</template>

<script>
import eventService from "@/services/eventService.js";
import CompetitionList from "./Components/CompetitionList";
import LeafNodesContainer from "./Components/LeafNodesContainer";
import CMSBreadCrumb from "./Components/CMSBreadCrumb";
import { _ } from "vue-underscore";

export default {
  props: ["node"],
  
  components: { CompetitionList, LeafNodesContainer,CMSBreadCrumb },
  data() {
    return {
      loading: false,
      selection: {}
    };
  },
  computed: {
    path:function(){
      return this.$route.params.path;
    }
  },
  methods: {
    buildPathNodes() {
      this.selection = {
        path:[]
      };

     // console.log("BUILD PATH NODES ", this.path);
      if (!this.node || !this.path) return;
      //this.pathNodes = [];

      //  console.log("galere??? ", this.cms.Index[this.path]);
      var curNode = this.node.Index[this.path];

      while (curNode && curNode.Id && this.selection.path.length < 10) {    // <10 => avoid circular loop bug
        this.selection[curNode.Level] = curNode.Id;
        
        if (!this.selection.selected) this.selection.selected=curNode;

        if (curNode.Level <= 2 && curNode.Folders.length > 0) {
          // ignore
        } else {
          this.selection.path.unshift({
            Id: curNode.Id,
            DisplayName: curNode.DisplayName,
            Params: {
              path: curNode.Id,
            },
          });
        }
        curNode = this.node.Index[curNode.Parent];
      }

      this.selection.path.unshift({
        Id: "HOME",
        DisplayName: "#HOME",
        Params: {},
      });
      console.log("pathnodes", this.pathNodes);
    },

    updateView() {
      //  console.log("update view", this.$route.params.l1);
    }
  
  },
  watch: {
    path: function(o,n)
    {
      this.buildPathNodes();
    },
    node: function(o,n)
    {
      this.buildPathNodes();
    }    
  },

  beforeDestroy() {},
  updated() {
    //   console.log("component updated", this.$route);
  },
  mounted() {
    this.buildPathNodes();
  },
};
</script>
