<template>
  <v-card-text>
    <v-row dense>
      <v-col
        v-for="(stream, i) in streams"
        :key="i"
        cols="12"
        lg="6"
        md="6"
      >
        <v-card>
          <youtube
            ref="youtube"
            :video-id="stream.Key"            
            resize
            fit-parent
            @xplaying="playing"
            @ready="ready"
          ></youtube>
          <v-card-title>{{$tcond(stream.Name)}}</v-card-title>

        </v-card>      
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  props: ["reportList", "cms", "selection", "path"],

  data() {
    return {
      seekDone: false,
    }
  },
  computed: {
    player() {
      return this.$refs.youtube.player
    },
    streams()
    {
      if (this.selection==null || this.selection.selected==null) return [];
      return this.selection.selected.Settings.Streams;
    }
  },
  mounted() {
    //this.streams=JSON.parse(this.eventView.Parameters.Streams)
  },
  methods: {
    playVideo() {
      //  this.player.playVideo()
    },
    ready() {
      console.log('ready')
    },
    playing() {
      console.log('o/ we are watching!!!')
      if (!this.seekDone) {
        this.player.seekTo(3600, true)
        this.seekDone = true
      }
    },
    seek() {
      this.seekDone = false
      this.player.playVideo()
      this.player.seekTo(3600, true)
    }
  }
}
</script>
