<script>
export default {
  functional: true,
  props: ['row', 'definition', 'column'],
  data() {
    return {}
  },
  beforeMount: function() {},
  mounted: function() {},
  render: function(
    h,
    { props, data, children, listeners, slots, scopedSlots }
  ) {
    var data = {}

    data.attrs = {}

    const classes = []

    if (props.column.Colspan > 1) data.attrs.Colspan = props.column.Colspan

    if (props.column.Bold) classes.push('text-bold')
    if (props.column.Italic) classes.push('text-italic')
    switch (props.column.Alignment) {
    case 'Center':
      classes.push('text-center')
      break

    case 'Right':
      classes.push('text-right')
      break
    }

    if (classes.length > 0) data.attrs.class = classes.join(' ')

    //if (props.column.Alignment)  data.class=props.column.Colspan;

    //console.log("format",props,slots);

    /*if (props.format.colspan && prop.format.colspan>1)
    {
      data.attrs.colspan=prop.format.colspan;
    }*/

    let childs = scopedSlots.default ? scopedSlots.default() : ' '

    //console.log("childs",childs)
    const linkClick = (event) => {
      event.stopPropagation();
    }

    if (props.column.UrlColumnIdx && props.row[props.column.UrlColumnIdx]!='') {
      const newChilds = [
        h(
          'a',
          {
            attrs: {
              href: props.row[props.column.UrlColumnIdx],
              target: props.column.Settings['Target']
            },
            on: {
              click: linkClick
            }
          },
          childs
        )
      ]

      childs = newChilds

      //      console.log('LINK COLUMN: ', props.column.UrlColumnIdx)
    }

    const toRet = h('td', data, childs)

    return toRet
  }
}
</script>
