import { _ } from 'core-js';
import collectionTools from './collectionTools.js'

export let earthRadius = 6371008.8;

const epsilon = 2.5;  // in meters

const trackTools = new (class {

  toRad(deg) {
    return deg * Math.PI / 180.0;
  }
  toDeg(rad) {
    return rad * 180.0 / Math.PI;
  }

  lengthToRadians(distance) {
    return distance / earthRadius;
  }

  radiansToLength(radians) {
    return radians * earthRadius;
  }

  /**
   * 
   * @param {*} pt 
   * @param {*} distance in meters
   * @param {*} bearing in degrees
   * @returns 
   */
  pointAtDestination(pt, distance, bearing) {
    const longitude1 = this.toRad(pt.lon);
    const latitude1 = this.toRad(pt.lat);
    const bearingRad = this.toRad(bearing);
    const radians = this.lengthToRadians(distance);

    // Main
    const latitude2 = Math.asin(
      Math.sin(latitude1) * Math.cos(radians) +
      Math.cos(latitude1) * Math.sin(radians) * Math.cos(bearingRad)
    );
    const longitude2 =
      longitude1 +
      Math.atan2(
        Math.sin(bearingRad) * Math.sin(radians) * Math.cos(latitude1),
        Math.cos(radians) - Math.sin(latitude1) * Math.sin(latitude2)
      );
    const lon = this.toDeg(longitude2);
    const lat = this.toDeg(latitude2);

    return { lon: lon, lat: lat };
  }

  trackDistance(track) {
    return track[track.length - 1].distance;
  }
  calcDistanceQuick(orig, dest) {
    //console.log("orig: "+JSON.stringify(orig)+"  -  "+JSON.stringify(dest));
    var coef = 6378137.0 * Math.PI / 180.0;

    var x = coef * (dest.lat - orig.lat);
    var y = coef * (dest.lon - orig.lon) * Math.cos(this.toRad(orig.lat));
    return Math.sqrt(x * x + y * y);
  }

  pointsDistance(pts)
  {
    var toRet=[];
    if (pts.length==0) return;

    toRet.push(0);
    let prv=pts[0];
    let cum=0;
    let i=1;
    while (i<pts.length)
    {
      let cur=pts[i];
      cum+=this.calcDistanceQuick(prv,cur);
      toRet.push(cum);
      cur=prv;
      i++;
    }
    return toRet;
  }

  trackAsPoints(track)
  {
    var toRet=[];
    if (track.length==0) return toRet;

    let i=0;
    while (i<track.length)
    {
      var tkPt=track[i];
      toRet.push([tkPt.lon,tkPt.lat]);
      i++;
    }
    return toRet;
  }

  pointsAsTrack(pts)
  {
    var toRet=[];
    if (pts.length==0) return;

    var pt=pts[0];
    var cum=0;
    var prv={ lat: pt[1], lon: pt[0], distance: 0, idx:0 };
    toRet.push(prv);
    var i=1;
    while (i<pts.length)
    {
      pt=pts[i];
      var cur={ lat: pt[1], lon: pt[0] };

      var dst=this.calcDistanceQuick(prv,cur);      

      cum=cum+dst;
      cur.idx=i;
      cur.distance=cum;
      toRet.push(cur);

      prv=cur;

      i++;      
    }    
    return toRet;
  }

  searchOnTrack(toSearch, track, maxDistance, fromIdx) {
    if (!fromIdx) fromIdx = 0;
    var i = 1;
    var best = null;
    while (i < track.length) {
      //var idx=(fromIdx+i)%track.length;
      //console.log("???: "+i);

      var fromTrack = this.searchOnSegment(toSearch, track[i - 1], track[i]);
      if (fromTrack != null) {
        try {
          if (best == null || fromTrack.distanceFromTrack < best.distanceFromTrack) {
            best = fromTrack;
          }
        } catch (ex) {
          console.log(ex);
        }

      }
      i++;
    }
    //console.log(JSON.stringify(fromTrack));

    return best;
  }

  // search pt on segment [orig-dest] 
  searchOnSegment(toSearch, orig, dest) {
    var d1 = this.calcDistanceQuick(orig, dest);
    var d2 = this.calcDistanceQuick(toSearch, dest);
    var d3 = this.calcDistanceQuick(toSearch, orig);

    if (d1 == 0) return null;

    if (d2 <= epsilon) {
      return {
        segment: { orig: orig, dest: dest },
        intersection: dest,
        //distanceFromStart : dest.distance,
        distanceFromTrack: d2,
        onSegment: true
      }
    }
    if (d3 <= epsilon) {
      return {
        segment: { orig: orig, dest: dest },
        intersection: orig,
        //distanceFromStart : orig.distance,
        distanceFromTrack: d3,
        onSegment: true
      }
    }

    //if (d1==0) throw    
    var toRet = {};
    var m2 = (d1 * d1 + d3 * d3 - d2 * d2) / (2 * d1);

    var inter =
    {
      lat: orig.lat + ((dest.lat - orig.lat) * m2) / d1,
      lon: orig.lon + ((dest.lon - orig.lon) * m2) / d1,
      alt: orig.alt + ((dest.alt - orig.alt) * m2) / d1
    };
    /*        (float)(segment.Orig.Latitude.Degrees + ((segment.Dest.Latitude.Degrees - segment.Orig.Latitude.Degrees) * m2) / d1),
            (float)(segment.Orig.Longitude.Degrees + ((segment.Dest.Longitude.Degrees - segment.Orig.Longitude.Degrees) * m2) / d1),
            segment.Orig.Elevation + ((segment.Dest.Elevation - segment.Orig.Elevation) * m2) / d1);*/

    toRet.onSegment = m2 >= 0 && m2 <= d1;
    if (m2 < 0) {
      inter = orig;
      m2 = 0;
    }
    else if (m2 > d1) {
      inter = dest;
      m2 = d1;
    }

    toRet.intersection = inter;
    toRet.segment = { orig: orig, dest: dest };
    toRet.distance = dest.distance - d1 * (1 - m2 / d1);
    toRet.distanceFromTrack = this.calcDistanceQuick(toSearch, inter);

    return toRet;
  }

  calcBearing(pt1, pt2) {
    var lon1 = this.toRad(pt1.lon);
    var lon2 = this.toRad(pt2.lon);
    var lat1 = this.toRad(pt1.lat);
    var lat2 = this.toRad(pt2.lat);
    var a = Math.sin(lon2 - lon1) * Math.cos(lat2);
    var b = Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);

    var bearing = this.toDeg(Math.atan2(a, b));
    return bearing;
  }

  calcBearingTrackPt(track, idx) {
    if (track.length < 2) return 0;
    if (idx == 0) return this.calcBearing(track[0], track[1]);
    if (idx == track.length - 1) return this.calcBearing(track[track.length - 2], track[track.length - 1]);

    return this.calcBearing(track[idx - 1], track[idx + 1]);
  }

  buildDistanceSource(map,track,distanceSource)
  {
    if (distanceSource==null)
    {
      distanceSource =
      {
        "type": "FeatureCollection",
        "features": [
        ]
      };  
    }

    var i = 0;
    var distance = trackTools.trackDistance(track);

    console.log("TrackDistance: ", distance);
    while (i < distance / 250.0) {
      var pt = this.searchDistanceOnTrack(track, i * 250);

      let toAdd =
      {
        id: i,
        type: 'Feature',
        properties: {
         // "name": (i % 4 == 0 ? pt.distance / 1000.0 + "km" : ""),
          "description": pt.description,
          "icon": "up",
          "bearing": pt.bearing
        },
        geometry: {
          type: 'Point',
          coordinates: [pt.lon, pt.lat]
        }
      };

      //console.log("toadd",distanceSource)

      distanceSource.features.push(toAdd);

      i++;
    }
    
    
    return distanceSource;
  }


  addDistanceLayer(map,distanceSource) 
  {
    map.addSource("distanceSource", { type: "geojson", data: distanceSource });

    map.addLayer(
      {
        "id": "distance",
        "type": "symbol",
        "source": "distanceSource",
        "minzoom": 14,
        "layout": {
          "icon-allow-overlap" : true,
          "text-allow-overlap" : true,
            "icon-image": ['get', "icon"],
          "icon-rotate": ['get', 'bearing'],
          "icon-rotation-alignment": "map",
          "icon-size": 0.15,
          "icon-offset": [0, 0],
          'text-field': ['get', 'name'],
          'text-size': 14,
          'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold'
          ],
          'text-offset': [0, 0.75],
          'text-anchor': 'top'
        }
      }
    );
  }

  normalizeTrack(track,distance)
  {
    var currentDistance=track[track.length - 1].distance;
    var coef=distance/currentDistance;
    track.forEach(item=>item.distance=item.distance*coef);
    return track;
  }

  cutTrack(track,from,to)
  {
    if (track.length<2) return null;
    if (from<=0) from=0;
    if (to>=track[track.length-1].distance) to=track[track.length-1].distance;
    if (from<=0 && to>=track[track.length-1].distance) return track;
    if (to<=from) return null;

    var toRet=[];  
    
    //var strtIdx = collectionTools.findFirstIdx(track, distance, (a, b) => { if (a.distance == b) return 0; if (a.distance < b) return -1; return 1; });
    var fromPt=this.searchDistanceOnTrack(track,from);
    var toPt=this.searchDistanceOnTrack(track,to);
    
    var i=0;
    while (i<track.length && track[i].distance<=from) i++;
    toRet.push(fromPt);
    while (i<track.length && track[i].distance<to) toRet.push(track[i++]);
    toRet.push(toPt);

    return toRet;
  }

  searchDistanceOnTrack(track, distance)       // returns a position
  {
    if (track.length < 2) return null;

    var idx = collectionTools.findFirstIdx(track, distance, (a, b) => { if (a.distance == b) return 0; if (a.distance < b) return -1; return 1; });
    var toRet;
    if (idx >= 0) {
      toRet = track[idx];
      toRet.bearing = this.calcBearingTrackPt(track, idx);
      return toRet;
    }

    idx = -(idx + 1);

    if (idx == 0) {
      toRet=track[0];
      toRet.bearing = this.calcBearingTrackPt(track, 0);
      return track[0];
    }

    if (idx >= track.length) {
      toRet=track[track.length - 1];
      toRet.bearing = this.calcBearingTrackPt(track, track.length - 1);
      return track[track.length - 1];
    }

    var prv = track[idx - 1];
    var cur = track[idx];

    var coef = (distance - prv.distance) / (cur.distance - prv.distance);

    //console.log("prv");console.log(prv);

    toRet =
    {
      idx: idx,
      lat: prv.lat + (cur.lat - prv.lat) * coef,
      lon: prv.lon + (cur.lon - prv.lon) * coef,
      alt: prv.alt + (cur.alt - prv.alt) * coef,
      distance: prv.distance + (cur.distance - prv.distance) * coef,
      bearing: this.calcBearing(prv, cur)
    };

    return toRet;
  }

  constructor() {
  }
})();

export default trackTools;
