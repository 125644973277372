<template>
  <span>
    <div xstyle="background-color:red">
      <v-container
        class="pt-0 pb-0 results"
        style="max-width: 1150px; xbackground-color: orange"
      >
      
        <v-breadcrumbs
          large
          divider=">"
          style="padding-left: 0px; padding-bottom: 0px"
        >
          <v-breadcrumbs-item exact :to="{ name: 'reportList' }">{{
            $t("REPORTLIST")
          }}</v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item>Hello</v-breadcrumbs-item>
        </v-breadcrumbs>
      </v-container>
    </div>

    <v-container
      class="pt-2 pb-2 results"
      style="max-width: 1150px; xbackground-color: orange"
    >
    
      <v-row dense class="pb-2">
        <v-col md="3">
          <v-card outlined>
            <span
              style="
                position: absolute;
                width: 4px;
                display: block;
                background: green;
                height: 100%;
              "
            >
            </span>
            <v-card-text>
              <span>Finishers</span><br />
              <span>
                <i
                  style="position: absolute; right: 8px"
                  class="fas fa-users fa-xl green--text"
                ></i>
                <span class="subtitle-1">430/489</span>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="3">
          <v-card outlined>
            <span
              style="
                position: absolute;
                width: 4px;
                display: block;
                background: green;
                height: 100%;
              "
            >
            </span>
            <v-card-text>
              <span>Race time</span><br />
              <span>
                <i
                  style="position: absolute; right: 8px"
                  class="fas fa-stopwatch fa-xl green--text"
                ></i>
                <span class="subtitle-1">4:25:42</span>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-card>
        <v-tabs background-color="deep-purple accent-4" center-active dark>
          <v-tab>One</v-tab>
        </v-tabs>

        <span xv-if="curReport != null">
          <v-card-title>
            <h3>Hello</h3>
          </v-card-title>
          <v-card-text class="mb-0">
            Click on a row to see details
          </v-card-text>
          <v-card-text xv-if="tables != null" class="xcompact-bloc pb-0">
            <v-form @submit="updateSearchResult(search)">
              <v-text-field
                xbackground-color="grey darken-3"
                xflat
                solo
                v-model="search"
                :loading="searching"
                :append-icon="'mdi-magnify'"
                :label="'Search'"
                placeholder="nom, dossard,..."
                filled
                clearable
                type="text"
                @click:clear="updateSearchResult('')"
              >
                <template #append>
                  <v-divider class="mx-1" vertical></v-divider>
                  <v-icon @click="updateSearchResult(search)"
                    >fas fa-search</v-icon
                  >
                  <v-divider class="mx-1" vertical></v-divider>
                  <v-icon class="primary--text" @click="toggleFavorites()"
                    >{{ favorites ? "fas" : "far" }} fa-star</v-icon
                  >
                </template>
              </v-text-field>
              <v-spacer></v-spacer>
            </v-form>
          </v-card-text>

          <v-card-text class="compact-bloc">
            <span v-if="!loading">
              <cr-table
                v-if="data && data.Count > 0"
                :data="data"
                @action="rowClick"
              ></cr-table>
              <span v-else v-html="$t('NORESULT')"></span>

              <v-pagination
                v-if="data && data.Count > 0 && data.PagingAllowed"
                v-model="page"
                :total-visible="7"
                :length="pageCount"
                @input="setPage"
              ></v-pagination>
            </span>
            <Loading v-if="loading"></Loading>
          </v-card-text>
        </span>
      </v-card>
    </v-container>
  </span>
</template>

<script>
import eventService from "@/services/eventService.js";

import { _ } from "vue-underscore";

export default {
  props: ["reportList"],

  data() {
    return {
      currentVideo: {},
      seekDone: false,

      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "breadcrumbs_dashboard",
        },
        {
          text: "Link 1",
          disabled: false,
          href: "breadcrumbs_link_1",
        },
        {
          text: "Link 2",
          disabled: true,
          href: "breadcrumbs_link_2",
        },
      ],

      tableList: [],
      expandedGroups: [],
      selectedTable: null,

      loading: false,
      curGroup: null,
      searching: false,
      page: 1,
      curReport: null,
      data: null,
      search: "",
      details: null,
      usedSearch: "",
      pageSize: 100,
      interval: null,
      liveEnabled: false,
      dlgDialog: false,
      favorites: false,
    };
  },
  computed: {
    pageCount: function () {
      if (!this.data) return 0;

      return Math.trunc(
        (this.data.Count + (this.pageSize - 1)) / this.pageSize
      );
    },
    player: function () {
      if (this.$refs.youtube) return this.$refs.youtube.player;

      return null;
    },
  },
  watch: {
    reportList: function (o, n) {
      console.log("report list wxatch called");
      this.updateReport();
    },
    selectedTable: function (o, n) {
      this.loading = true;
      console.log("SELECTED TABLE CHANGED: ", o, n);
      this.stopLive();
      this.search = "";
      this.updateSearchResult("");
    },
  },
  beforeDestroy() {
    console.log("UNMOUNTED CALLED");
    this.stopLive();
  },
  mounted() {
    this.updateReport();
  },
  methods: {
    ready() {
      console.log("youtube player ready");
    },
    toggleFavorites() {
      this.favorites = !this.favorites;
      console.log("favorite toggle");
    },
    playing(e) {
      console.log("youtube status", e);
      if (!this.seekDone) {
        //this.player.seekTo(3600, true)
        this.player.seekTo(this.currentVideo.VideoStart, true);
        this.seekDone = true;
      }
    },

    startVideo(video) {
      console.log("set current video", video);
      this.currentVideo = video;
      this.seekDone = false;
      this.player.playVideo().then((done) => {
        console.log("play video done");
        this.player.seekTo(this.currentVideo.VideoStart, true);
      });
    },

    onResize(e) {
      console.log("on resize called in tableViewer", e.offsetWidth);

      this.width = e.offsetWidth;
    },
    dlgClick(btn) {
      this.dlgDialog = false;
    },
    rowClick(evt) {
      console.log("rowclick in viewer: ", evt);

      const rowAction = this.data.TableDefinition.SortedColumns["sH_RowAction"];

      if (rowAction) {
        const action = evt.row[rowAction.FieldIdx];
        console.log(action);

        if (action && action != "") {
          if (action.startsWith("detail:")) {
            console.log("detail popup action... ", action);

            const token = action.substring(7);
            console.log("token: ", token);

            this.$router.push({
              name: "kotkdetail",
              params: { id: token },
            });
          }
        }
      }
    },
    setPage(page) {
      console.log("PAGE CLICKED ", page, this.page);
      this.loading = true;
      this.searchReport(this.usedSearch);
    },
    updateSearchResult(srch) {
      this.page = 1;
      this.expandedGroups = [];
      this.usedSearch = srch;

      console.log("update search result", this.search, "[" + srch + "]");
      this.searchReport(this.usedSearch);
    },
    startLive() {
      console.log("LIVE ENABLED home");
      let delay = this.data.Settings.LiveDelay * 1000;

      if (delay < 5000) delay = 5000;
      if (!this.interval) {
        this.interval = setInterval(() => {
          //console.log("refresh???");
          if (this.searching) return;
          this.searchReport(this.usedSearch);
        }, delay);
        this.liveEnabled = true;
      }
    },
    stopLive() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    searchReport(srch) {
      console.log("SEARCH REPORT");

      if (this.searching) return;

      this.searching = true;
      const settings = {
        from: (this.page - 1) * this.pageSize,
        pageSize: this.pageSize,
        expanded: this.expandedGroups,
      };

      console.log("search... ", srch);
      eventService
        .searchTable("20220320_kotk", this.selectedTable.Name, srch, settings)
        .then(
          (data) => {
            this.data = data;
            this.loading = false;
            this.searching = false;

            //console.log("data",this.data);

            if (this.data.Settings && this.data.Settings.Live) {
              this.startLive();
            }
            if (this.data.Settings && !this.data.Settings.Live) {
              this.stopLive();
            }
          },
          (error) => {
            this.liveEnabled = false;
            this.loading = false;
            this.searching = false;

            console.log("error occured...", error);
            console.log("loading: ", this.loading);
          }
        );
    },
    sortTest1() {
      this.table.Groups.forEach((g) => {
        g.SlaveRows.sort((a, b) => {
          if (a[1] > b[1]) return 1;
          if (a[1] < b[1]) return -1;

          return 0;
        });
      });
    },
    updateReport() {
      console.log("report_list", this.reportList);

      //if (!this.reportList || this.reportList.length == 0) return

      var grp = 24125;
      var rep = 22522;
      console.log("lala");

      /*this.curGroup = _.find(
        this.reportList,
        (grp) => grp.Id == grp
      )
      this.curReport = _.find(
        this.curGroup.Reports,
        (rpt) => rpt.Id == rep
      )*/

      /* this.tables = (
        this.curReport.Settings.Table || this.curReport.Settings.Tables
      )
        .split(',')
        .map((t) => {
          const toRet = {}

          const ix = t.indexOf(':')

          if (ix > 0) {
            toRet.Name = t.substring(0, ix)
            toRet.DisplayName = t.substring(ix + 1)
          } else {
            toRet.Name = t
            toRet.DisplayName = t
          }

          return toRet
        })*/

      this.selectedTable = { Name: "LIVE", DisplayName: "LIVE" };
      /*this.loading = true
      this.searchReport('')*/
    },
  },
};
</script>
