<template>
  <div ref="container" v-resize="onResize" style="width:100%">
    <table v-if="data" class="data-table">
      <thead>
        <tr v-if="groupCols != null">
          <th
            v-for="(grp, i) in groupCols"
            :key="i"
            :colspan="grp.Colspan"
            :class="{
              groupCol: grp.Type != 'Spacer',
              spacerCol: grp.Type == 'Spacer'
            }"
          >
            {{ $tcond(grp.GroupDisplayName) }}
          </th>
        </tr>
        <tr>
          <th v-for="(col, i) in definition" :key="i">
            {{ $tcond(col.DisplayName) }}
          </th>
        </tr>
      </thead>
      <template v-for="(chunk, i) in data.Groups">
        <tbody
          v-if="
            masterDefinition != null &&
              chunk.MasterRows &&
              chunk.MasterRows.length > 0
          "
          :key="'mas_' + i"
          class="table-master"
        >
          <cr-row
            v-for="(masterRow, masterIdx) in chunk.MasterRows"
            :key="masterIdx"
            :row="masterRow"
            :context="context"
            :definition="masterDefinition"
            :table-definition="data.MasterDefinition"
          >
          </cr-row>
        </tbody>
        <tbody v-if="definition != null" :key="'slv_' + i" class="table-data">
          <cr-row
            v-for="(slaveRow, slaveIdx) in chunk.SlaveRows"
            :key="slaveIdx"
            :row="slaveRow"
            :context="context"
            :definition="definition"
            :table-definition="data.TableDefinition"
            @action="rowAction"
          >

          </cr-row>
        </tbody>
        <tbody
          v-if="chunk.SlaveRows.length < chunk.Count && !data.PagingAllowed"
          :key="'stat_' + i"
        >
          <tr>
            <td :colspan="colCount" class="text-right">
              <a
                @click="groupAction(chunk, 'grpshow')"
              >{{ $t('SHOW') }} <b>{{ chunk.Count }}</b>
                {{ $t('ENTRIES') }}</a>
            </td>
          </tr>
        </tbody>
        <tbody :key="'sep_' + i" class="table-spacer">
          <tr>
            <td colspan="3"></td>
          </tr>
        </tbody>
      </template>
    </table>

    <v-row dense class="mb-0">
      <v-col cols="9"></v-col>
      <v-col
        cols="3"
        class="text-right"
      ><b>{{ data.Count }}</b> {{ $t('ENTRIES') }}</v-col>
    </v-row>
  </div>
</template>
<script>
import resize from 'vue-resize-directive'

export default {
  directives: { resize },
  props: ['data', 'action','context'],
  data() {
    return {
      renderCount: 1,
      width: 2000,
      colCount: 0,
      columns: [],
      definition: null,
      masterDefinition: null,
      groupCols: null
    }
  },
  watch: {
    width: function(n, o) {
      console.log('rebuild cols')
      this.buildColumns()
    },
    data: function() {
      this.buildColumns()
    }
  },
  beforeMount: function() {},
  mounted: function() {
    console.log('custom renderer')
    console.log(this.$el.offsetWidth)

    if (this.$el.offsetWidth > 0) this.width = this.$refs.container.clientWidth

    //   this.interval=setInterval(()=>{
    //       console.log(this.$el.offsetWidth);
    //   },100);

    /*this.$el.on('resize',function(s)
        {
            console.log("reisze fnction");
        });*/
    //console.log("definition: ",this.definition);
  },
  methods: {
    rowAction(evt) {
     // console.log('row action', evt)
      this.$emit('action', evt)
    },
    groupAction(grp, action) {
      console.log('group action', action)
      const evt = { action: action, grp: grp }

      this.$emit('action', evt)
    },
    onResize(e) {
      console.log(
        'on resize called',
        e.offsetWidth,
        e.clientWidth,
        this.$refs.container.clientWidth
      )
      //this.$refs.quest.clientWidth
      this.width = this.$refs.container.clientWidth
    },
    updateDefinition(definition) {
      if (!definition) return

      definition.SortedColumns = {}
      definition.Columns.forEach((c) => {
        definition.SortedColumns[c.Name] = c
      })

      definition.Columns.forEach((c) => {
        if (c.UrlColumn) {
          const linkColumn = definition.SortedColumns[c.UrlColumn]

          if (linkColumn) {
            c.UrlColumnIdx = linkColumn.FieldIdx
          }
        }
      })
    },
    splitDefinition(definition) {
      const toRet = []

      if (!definition || !definition.Columns) return toRet

      let k = 0

      // 1 pass, split...
      definition.Columns.forEach((c) => {
        if (!c.Colspan) c.Colspan = 1
        if (!c.Offset) c.Offset = 0
        if (!c.Weight) c.Weight = 0
        if (c.Visible) {
          let i = 0

          while (i < c.Colspan) {
            toRet.push({ RelatedColumn: c, Weight: c.Weight, Idx: k })
            i++
          }
          k++
        }
      })

      return toRet
    },
    normaliseLength(data, lng) {
      if (data == null) return null
      const idx = data.length

      while (data.length < lng) {
        data.push({ Idx: idx, Weight: 0 })
      }
    },
    updateVisibility(data, width) {
      if (data == null) return null
      data.forEach((col) => {
        col.Visible = width >= col.Weight
      })
    },
    buildGroups(cols) {
      let hasGroups = false
      const toRet = []
      let i = 0

      while (i < cols.length) {
        const frstCol = cols[i]
        const frstColIdx = i

        if (frstCol.RelatedColumn.GroupDisplayName) hasGroups = true

        while (
          i < cols.length &&
          cols[i].RelatedColumn.GroupDisplayName ==
            frstCol.RelatedColumn.GroupDisplayName
        ) {
          toRet.push({
            RelatedColumn: frstCol.RelatedColumn.GroupDisplayName
              ? frstCol.RelatedColumn
              : null,
            Weight: 0,
            Idx: frstColIdx
          })
          i++
        }
      }

      return hasGroups ? toRet : null
    },
    // rebuild visible cols based on reference
    rebuild(cols, reference) {
      if (cols == null) return null
      const toRet = []
      let i = 0

      while (i < cols.length) {
        const frstCol = cols[i]
        let refVisibleCols = 0

        while (i < cols.length && cols[i].Idx == frstCol.Idx) {
          if (reference[i].Visible && cols[i].Visible) refVisibleCols++
          i++
        }

        if (refVisibleCols > 0) {
          let newCol = { ...frstCol.RelatedColumn }

          if (!newCol.Type)
            newCol = { Type: 'Spacer', DisplayName: '', Weight: 0 }
          newCol.Colspan = refVisibleCols
          toRet.push(newCol)

          //console.log("refVisibleCols: "+refVisibleCols);
        }
      }

      return toRet
    },
    buildColumns() {
      this.columns = []

      if (this.data.TableDefinition) {
        this.updateDefinition(this.data.TableDefinition)
        this.updateDefinition(this.data.MasterDefinition)

        const splitCols = this.splitDefinition(this.data.TableDefinition)
        const masterCols = this.splitDefinition(this.data.MasterDefinition)
        const groupCols = this.buildGroups(splitCols)
        let maxLength = splitCols.length

        this.colCount = maxLength

        if (masterCols.length > maxLength) maxLength = masterCols.length
        this.normaliseLength(splitCols, maxLength)
        this.normaliseLength(masterCols, maxLength)
        this.updateVisibility(splitCols, this.width)
        this.updateVisibility(masterCols, this.width)
        this.updateVisibility(groupCols, this.width)
        this.definition = this.rebuild(splitCols, splitCols)
        this.masterDefinition = this.rebuild(masterCols, splitCols)
        this.groupCols = this.rebuild(groupCols, splitCols)
      }
    }
  },
  unmounted: function() {
    //  clearInterval(this.interval);
  }
}
</script>
<style>
.table-spacer {
  height: 16px;
}

.groupCol {
  background-color: var(--v-surface-darken3);
  color: white;
}
.spacerCol {
  background-color: white;
}

.table-master {
  background-color: var(--v-primary-base) !important;
  font-weight: bold;
  color: white;
}

.data-table > thead {
  background-color: #f3f3f3;
  position: sticky;
  top: 128px;
}

.data-table > thead > tr:last-child > th {
  border-bottom: #ddd 2px solid;
}

.last-record-line > td {
  border-bottom: #ddd 1px solid;
}

.text-italic {
  font-style: italic;
}

.text-bold {
  font-weight: bold;
}

.cr-pink {
  color: #FF1493;
}

.data-table {
  color: #373737;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  .data-table {
    font-size: 11px
  }
}

.data-table > tbody > tr > td,
.data-table > thead > tr > th {
  padding: 2px 5px 2px 5px;
}

.table-data > tr:hover {
  background-color: #ffc50099;
}

.table-data > tr:nth-child(2n + 1) {
  background-color: #f0f0f0;
}

.table-data > tr:hover {
  background-color: #ffc50099;
}

.table-data > tr > td:hover {
  cursor: pointer;
}
</style>
