<template>
    <span>
        <v-snackbar
            v-model="snackbar"
            :timeout="5000"
            :color="snackbarColor"
            bottom
            >
            {{ $t(text) }}

            <template v-slot:action="{ attrs }">
                <v-btn
                dark
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                {{$t("CLOSE")}}
                </v-btn>
            </template>
        </v-snackbar> 

        <v-dialog v-model="dlgDialog" persistent max-width="400">
            <v-card>
                <v-card-title class="headline">{{$t(dlgText+"_TITLE",dlgTextDetails)}}</v-card-title>
                <v-card-text>{{$t(dlgText+"_HELP",dlgTextDetails)}}</v-card-text>                
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-for="btn in dlgButtons" :key="btn.name" text @click="dlgClick(btn)">{{$t(btn.name,dlgTextDetails)}}</v-btn>                
                </v-card-actions>
            </v-card>
        </v-dialog>            
    </span>  
</template>

<script>
import appSettingsService from '../appSettingsService'

export default {
    props: ["message"],
    data() {
        return {
            snackbar: false,
            snackbarColor: null,
            dlgDialog:false,
            dlgText:null,
            dlgTextDetails:{},
            dlgButtons:[],
            text: null,
        };
    },    
    methods: {
        showSnackBar:function(text,color)
        {
            this.text=text;
            this.snackbar=true;
            this.snackbarColor=color;
        },
        dlgClick:function(btn)
        {
            console.log("dialog click");
            console.log(btn);
            this.$emit("message","dialog",btn);
            this.dlgDialog=false;

            this.promiseResolve(btn.name);
            
        },
        hideDialog:function()
        {
            this.dlgDialog=false;
        },
        showDialog:function(text,buttons,args)
        {
            this.dlgText=text;
            this.dlgButtons=[];
            this.dlgTextDetails=args;

            buttons.split(',').forEach(btn=>{
                this.dlgButtons.push({ name:btn });
            });

            this.dlgDialog=true;

            return new Promise((resolve,reject)=>{
                this.promiseResolve=resolve;
                this.promiseReject=reject;
            });
        }                        
    },
    components: {
    },
    mounted: function () {

    }
}
</script>
