<template>
  <div id="main" :style="backgroundStyle">
    <v-main>
      <v-container v-if="eventView" style="max-width:1200px" class="results">
        <v-card>
          <v-img
            class="white--text align-end"
            height="200px"
            :src="eventView.Parameters.ResultHeaderImage || img"
          >


          </v-img>

            <v-card-title>{{ eventView.Title }}</v-card-title>
            <v-card-subtitle
              xstyle="color: white"
            >{{ eventView.Date }}, {{ eventView.Location }} ({{
              eventView.Country  
            }})</v-card-subtitle>


          <v-tabs>
            <v-tab :to="{ name: 'reportList' }">{{ $t('RESULTS') }}</v-tab>
            <v-tab v-if="showStreaming" :to="{ name: 'youtube' }">{{
              $t('VIDEO')
            }}</v-tab>
          </v-tabs>

          <router-view :event-view="eventView"> </router-view>
        </v-card>
      </v-container>
    </v-main>
  </div>
  </template>

<script>
import eventService from '@/services/eventService.js'

export default {
  components: {},
  data() {
    return {
      ximg:'https://chronorace.blob.core.windows.net/webresources/assets/20210911_anvers/headerimage2.jpg',
      img:      
        'https://chronorace.blob.core.windows.net/webresources/20210728_alpe/headerimg.jpg',
      eventView: null
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log('before route enter: ', to.params.eventId)

    eventService.getEventView(to.params.eventId).then((eventView) => {
      console.log('eventView', eventView)
      next((vm) => {
        // access to component instance via `vm`
        vm.setEventView(eventView)

      })
    })
  },
  computed: {
    backgroundStyle : function()
    {
      let bgImage='https://chronorace.blob.core.windows.net/webresources/20210728_alpe/background.jpg';
      if (this.eventView && this.eventView.Parameters && this.eventView.Parameters.BackgroundImage) bgImage=this.eventView.Parameters.BackgroundImage;

      return {
        "background-image": "url('"+bgImage+"') ",     

      };
    },
    showStreaming: function() {
      if (!this.eventView) return false

      return this.eventView.Parameters.Streams != null
    }
  },
  mounted: function() {
    console.log('results  mounted')
  },
  methods: {
    setEventView(eventView) {
      this.eventView = eventView
      console.log('EVENT VIEW', eventView)
    },

    ready() {
      console.log('ready')
    }
  }
}
</script>

<style scoped>
#main {
  background: no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  min-height: 100vh;
}

@media only screen and (max-width: 600px) {
  .container.results {
    padding: 5px !important;
  }
}
@media only screen and (max-width: 500px) {
  .container.results {
    padding: 0px !important;
  }
}

.event-header-img img {
  z-index: 1;
  width: 100%;
  max-height: inherit;
  transform: translateY(-33%);
}
.event-header-master {
  overflow: hidden;
  height: 200px;
}
.event-header {
  padding: 1px 40px;
}
.home-header-gradient .event-header {
  padding: 0px 40px;
  min-height: 200px;
}
.event-header-title h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: 800;
  margin: 0px;
}

.home-header-gradient {
  xbackground-color: black;
  background: linear-gradient(
    to right,
    #ffffff,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.1)
  );
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 1;
  position: absolute;
  width: 100%;
}

.event-header-master {
  overflow: hidden;
}

.event-header-gradient {
  padding-bottom: 20px;
  background-color: black;
  background: linear-gradient(
    to right,
    #ffffff,
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.1)
  );
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 1;
  position: absolute;
  width: 100%;
}

.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
