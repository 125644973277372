<template>
  <div>
    <Hero5Demo />
    <Footer />
  </div>
</template>

<script>
import Hero5Demo from '@/components/ui/landing/hero/Hero5Demo.vue'
import Footer from '@/components/ui/landing/footer/Footer8Demo.vue'

export default {
  components: {
    Hero5Demo,
    Footer
    /*Logos2Demo,
    CallToAction5Demo,
    Feature11Demo,
    Footer4Demo,
    Team6Demo*/
  }
}
</script>
