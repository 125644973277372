<template>
  <span>
    <!-- <CMSBreadCrumb :cms="node" :path="path"></CMSBreadCrumb> -->

    <router-view></router-view>

    <v-snackbar
          v-model="snackbar"
          timeout="2000"
          color="red accent-2"
        >
      {{$t('THROTTLED')}}

    </v-snackbar>

    <v-container
      class="pt-2 pb-2 results"
      style="max-width: 1150px; xbackground-color: orange"
    >
    
      <!-- <pre>        
        {{selection  }}
      </pre> -->

      <v-row dense class="pb-2" v-if="false">
        <v-col md="3">
          <v-card outlined>
            <span
              style="
                position: absolute;
                width: 4px;
                display: block;
                background: green;
                height: 100%;
              "
            >
            </span>
            <v-card-text>
              <span>Finishers</span><br />
              <span>
                <i
                  style="position: absolute; right: 8px"
                  class="fas fa-users fa-xl green--text"
                ></i>
                <span class="subtitle-1">430/489</span>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="3">
          <v-card outlined>
            <span
              style="
                position: absolute;
                width: 4px;
                display: block;
                background: green;
                height: 100%;
              "
            >
            </span>
            <v-card-text>
              <span>Race time</span><br />
              <span>
                <i
                  style="position: absolute; right: 8px"
                  class="fas fa-stopwatch fa-xl green--text"
                ></i>
                <span class="subtitle-1">4:25:42</span>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-card>
        <span>
          <v-card-text v-if="hasRowAction && false">
            
            <v-banner
              @click:icon="showComplainForm"
              outlined rounded
              elevation="1">
              
              <span v-html="$t('REPORT_CLICK_HELP')"></span>
              
              <v-avatar slot="icon" color="info" size="40">
                <v-icon color="white">fa-solid fa-info</v-icon>
              </v-avatar>
             
            </v-banner>
          </v-card-text>     


        </span>
        <span xv-if="curReport != null">
          <!-- <v-card-text>
            <v-banner
              @click:icon="showComplainForm"
              color="warning"              
              outlined rounded
              elevation="1">
              Hello

              <pre>{{eventView}}</pre>

              <v-avatar
                slot="icon"
                color="error"
                size="40"
              >
                <v-icon
                  icon="fas fa-bug"
                  color="white"
                >
                  fas fa-bug
                </v-icon>
              </v-avatar>
              <template v-slot:actions>               
                <v-btn text>
                  Retry
                </v-btn>
              </template>              
            </v-banner>
          </v-card-text> -->

          

          <v-card-text xv-if="tables != null" class="xcompact-bloc pb-0">
            <v-form v-on:submit.prevent="updateSearchResult(search)" >
              <v-text-field
                xbackground-color="grey darken-3"
                xflat
                solo
                v-model="search"
                :loading="searching"
                :append-icon="'mdi-magnify'"
                :label="$t('SEARCH_LABEL')"
                :placeholder="$t('SEARCH_HELP')"
                filled
                clearable
                type="text"
                @click:clear="updateSearchResult('')"
              >
                <template #append>
                  <v-divider class="mx-1" vertical></v-divider>
                  <v-icon @click="updateSearchResult(search)"
                    >fas fa-search</v-icon
                  >
                  <v-divider class="mx-1" vertical></v-divider>
                  <v-icon class="primary--text" @click="toggleFavorites()"
                    >{{ favoritesFilter ? "fas" : "far" }} fa-star</v-icon
                  >
                </template>
              </v-text-field>
              <v-spacer></v-spacer>
            </v-form>
          </v-card-text>


          <v-card-text class="d-sm-none" v-if="hasRowAction">
            <span v-html="$t('REPORT_MOBILE_HELP')"></span>

            <!-- <v-banner
              @click:icon="showComplainForm"
              outlined rounded
              elevation="1">
              
              <span v-html="$t('REPORT_MOBILE_HELP')"></span>
              
              <v-avatar slot="icon" color="info" size="40">
                <v-icon color="white">fas fa-rotate</v-icon>
              </v-avatar>
             
            </v-banner> -->
          </v-card-text>               
      
          
          <!-- <v-btn
            color="pink"
            dark
            v-if="false"
            absolute
            top
            right
            fab
          >
            <v-icon>fas fa-bug</v-icon>
          </v-btn> -->

          <v-card-text class="compact-bloc">
            <span v-if="!loading">
              <cr-table
                v-if="data && data.Count > 0"
                :data="data"
                :context="context"
                @action="rowClick"
              ></cr-table>
              <span v-else v-html="$t('NORESULT')"></span>

              <v-pagination
                v-if="data && data.Count > 0 && data.PagingAllowed"
                v-model="page"
                :total-visible="7"
                :length="pageCount"
                @input="setPage"
              ></v-pagination>
            </span>
            <Loading v-if="loading"></Loading>
          </v-card-text>
        </span>
      </v-card>
    </v-container>

    <!-- <v-btn @click="goToParent()">HELLO</v-btn> -->
  </span>
</template>

<script>
import eventService from "@/services/eventService.js";
import CompetitionList from "./Components/CompetitionList";
import LeafNodesContainer from "./Components/LeafNodesContainer";
import CMSBreadCrumb from "./Components/CMSBreadCrumb";
import { _ } from "vue-underscore";
import Vue from 'vue';

export default {
  props: ["node", "selection", "path", "eventView"],
  components: { CompetitionList, LeafNodesContainer, CMSBreadCrumb },
  data() {
    return {
      loading: false,
      searching: false,
      pageSize: 100,
      snackbar:false,
      selectedTable: null,
      favoritesFilter:false,
      search: "",
      hasRowAction:false,
      data: [],
      favorites: {},
    };
  },
  computed: {
    /*path: function () {
      return this.$route.params.path;
    },*/

    context: function () {
      //var that=this;
      return {
        getFavorites:()=>{
          //console.log("GET FAVS: ",this);
          return this.favorites
        },
        setFavorite:(id,enable)=>{

          if (enable) this.$set(this.favorites,id,true);
          else delete Vue.delete(this.favorites,id);

          console.log("SET FAV ",id,enable,this.favorites);
          
          this.saveFavorites();

        }
      };
    },
    pageCount: function () {
      if (!this.data) return 0;

      return Math.trunc(
        (this.data.Count + (this.pageSize - 1)) / this.pageSize
      );
    },
    SelectedNode: function () {
      if (!this.node) return null;
      // console.log("SELECTED2 NODE EVALUATED");
      return _.where(this.node.Childs, { Id: this.$route.params.l2 })[0];
    },
  },
  methods: {

    showComplainForm()
    {
      console.log("SALE RALEUR");
    },
    toggleFavorites(){
      console.log("toggle favorites")
      this.favoritesFilter=!this.favoritesFilter;
      this.searchReport(this.usedSearch);
    },
    getFavorites()
    {
      var favorites=[];
      for (var prop in this.favorites)
      {
        favorites.push(prop);            
      }
      return favorites;
    },
    saveFavorites()
    {
      var eventId=this.$route.params.eventId;
      var k=eventId+'_'+favorites;

      var favorites=this.getFavorites();

      if (favorites.length>0)
      {
        localStorage.setItem(k,JSON.stringify(favorites))
      } else localStorage.removeItem(k);
      
    },
    loadFavorites()
    {
      var eventId=this.$route.params.eventId;
      var k=eventId+'_'+favorites;

      var favorites=localStorage.getItem(k);
      if (favorites) {
        var favArray=JSON.parse(favorites);
        this.favorites={};
        for (var f in favArray)
        {
          this.favorites[favArray[f]]=true;
        }

      }
    },

    updateView() {
      //  console.log("update view", this.$route.params.l1);
    },

    rowClick(evt) {
      console.log("rowclick in generic home: ", evt);

      const rowAction = this.data.TableDefinition.SortedColumns["sH_RowAction"];
      console.log("row action ",rowAction)

      if (rowAction) {
        const action = evt.row[rowAction.FieldIdx];
        console.log(action);

        if (action && action != "") {
          if (action.startsWith("objectdetail:")) {
            const token = action.substring(13);
            var encoded=btoa(token);
            console.log("object token: ", token,encoded);

            this.$router.push({
              name: "events.genericdetailv2",
              params: { token: encoded },
            });
          }          
          else if (action.startsWith("detail:")) {
            console.log("detail popup action... ", action);

            const token = action.substring(7);
            console.log("token: ", token);

            this.$router.push({
              name: "events.genericdetail",
              params: { id: token },
            });
          }
          else if (action.startsWith("link:")) {
            console.log("link ", action);

            const link = action.substring(5);

            window.location=link;
          }          
          else if (action.startsWith("LINK:")) {
            console.log("LINK ", action);

            const url = action.substring(5);
            window.open(url, '_blank').focus();
          }          
        }
      }
    },
    setPage(page) {
      console.log("PAGE CLICKED ", page, this.page);
      this.loading = true;
      this.searchReport(this.usedSearch);
    },
    updateSearchResult(srch) {
      this.page = 1;
      this.expandedGroups = [];
      this.usedSearch = srch;

      console.log("update search result", this.search, "[" + srch + "]");
      this.searchReport(this.usedSearch);
    },
    startLive() 
    {
      
      let delay = this.data.Settings.LiveDelay * 1000;

      if (delay < 5000) delay = 5000;
      if (!this.interval) {
        this.interval = setInterval(() => {
        //  console.log("refresh???");
          if (this.searching) return;
          this.searchReport(this.usedSearch);
        }, delay);
        this.liveEnabled = true;
      }
    },
    stopLive() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    searchReport(srch) {
      //console.log("SEARCH REPORT");
      console.log("Search report: ",this.$route.name);
      
      if (this.searching) return;

      this.searching = true;
      const settings = {
        from: (this.page - 1) * this.pageSize,
        pageSize: this.pageSize,
        expanded: this.expandedGroups,
      };

      if (this.favoritesFilter)
      {
        settings.favorites=this.getFavorites();
      }

      var db=this.$route.params.db;

      console.log("search... ", srch,this.context,db);
      eventService
        .searchTable(db, this.selectedTable.Name, srch, settings)
        .then(
          (data) => {
            this.data = data;
            this.loading = false;
            this.searching = false;

            console.log("search result settings",this.data.Settings);

            if (this.data.Settings && this.data.Settings.Live) {
              this.startLive();
            }
            if (this.data.Settings && !this.data.Settings.Live) {
              this.stopLive();
            }
            this.hasRowAction=_.some(this.data.TableDefinition.Columns,(n=>n.Name=='sH_RowAction'));
            
          },
          (error) => {
            this.liveEnabled = false;
            this.loading = false;
            this.searching = false;

            //console.log("error occured...", error);

            if (error.response.status=='409')
            {
              console.log("BE PATIENT")
              this.snackbar=true;
              //console.log("ERROR WHILE SEARCHING ",error,error.response.status)
            }


            console.log("loading: ", this.loading);
          }
        );
    },
    sortTest1() {
      this.table.Groups.forEach((g) => {
        g.SlaveRows.sort((a, b) => {
          if (a[1] > b[1]) return 1;
          if (a[1] < b[1]) return -1;

          return 0;
        });
      });
    },
    updateReport() {
      console.log("report_list", this.reportList);

      //if (!this.reportList || this.reportList.length == 0) return

      this.selectedTable = { Name: this.$route.params.key, DisplayName: "LIVE" };
    },
  },
  watch: {
    "$route.params.l2": function (o, n) {
      this.updateView();
    },
    "selectedTable": function (o, n) {
      console.log("SELECTED TABLE CHANGED: ", o, n);
      this.loading = true;
      this.stopLive();
      this.search = "";
      this.updateSearchResult("");
    },
    cms: function (o, n) {
      console.log("cms setting updated");
    },
  },

  beforeDestroy() {
    this.stopLive();
  },
  updated() {
    //   console.log("component updated", this.$route);
  },
  mounted() {
    this.updateReport();
    this.loadFavorites();
    console.log("selected C%S node",this.selection);
    //console.log("generic home mounted: ",this.$route.params.eventId);
  },
};
</script>
