<template>
  <div
    v-if="Leafs && Leafs.length > 0"
    style="
      xborder-width: 1px !important;
      xborder-color: #999999 !important;
      position: relative;
    "
  >
    <v-container
      class="pt-1 pb-1"
      style="max-width: 1150px; xbackground-color: orange"
    >
      <v-card>
        <v-list dense>
          <v-list-item-group color="primary">
            <v-list-item v-for="(item, i) in Leafs" :key="i" @click="SelectNode(item)">
              <v-list-item-icon>
                <v-icon v-text="'mdi-clock'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.DisplayName"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { _ } from "vue-underscore";

export default {
  props: ["node","selected"],
  components: {},
  data() {
    return {};
  },
  computed: {
    Folders: function () {
      if (!this.node) return null;
      return this.node.Folders;
    },

    Leafs: function () {
      if (!this.node) return null;
      return this.node.Leafs;
    },
    /*
    Files=()=>{
        var curCateg=this.CurrentCategory();
        var toRet=[];
        angular.forEach(curCateg.Childs,chl=>{
            if (chl.Type && chl.Type=='File') toRet.push(chl);
        });
        return toRet;
    },*/
    SelectedNode: function () {
      if (!this.node) return null;
     // console.log("SELECTED2 NODE EVALUATED");
      return _.where(this.node.Childs, { Id: this.$route.params.l2 })[0];
    },
  },
  methods: {
    updateView() {},
    SelectNode(n){
      this.$emit('selected',n);
    }

  },
  watch: {},

  beforeDestroy() {},
  updated() {},
  mounted() {
    this.updateView();
  },
};
</script>
