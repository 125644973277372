<template>
  <cr-cell :row="row" :column="column" :definition="definition">{{
    formatTime(row[column.FieldIdx])
  }}</cr-cell>
</template>
<script>
export default {
  props: ['row', 'definition', 'column', 'action'],
  data() {
    return {}
  },
  beforeMount: function() {},
  mounted: function() {},
  methods: {
    formatTime: (input) => {
      if (!input) {
        return ''
      }

      if (typeof input === 'string') return input
      //if ()

      if (input == 0) return ''
      
      const ms = input % 1000

      input = (input - ms) / 1000
      let secs = (input % 60) + ''

      input = (input - +secs) / 60
      let mins = (input % 60) + ''
      const hrs = (input - +mins) / 60 + ''

      if (+hrs > 0) {
        if (+mins < 10) {
          mins = '0' + mins
        }
        if (+secs < 10) {
          secs = '0' + secs
        }

        return hrs + ':' + mins + ':' + secs
      }
      if (+mins > 0) {
        if (+secs < 10) {
          secs = '0' + secs
        }

        return mins + ':' + secs
      }

      return secs
    }
  }
}
</script>
