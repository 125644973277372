<template>
  <div style="width:100%;height:100vh;display:flex;flex-direction:column;overflow:hidden;">
    <div>
      <v-app-bar
        dense
        style=";overflow:hidden;"
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>CAP 48</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click.stop="drawer = !drawer">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-app-bar>  
    </div>
    <div style="flex:1;overflow:hidden;position:relative">
      <div
        id="mapContainer"
        ref="mapContainer"
        style="width: 100%; height: 100%;"
      >
      </div>
      <div style="position:absolute;left:16px;top:16px">
        <img style="max-width:150px" src="images/acn.png" />
      </div>
    </div>

    <v-dialog
      v-model="detailDialog"      
      width="500"
      transition="dialog-bottom-transition">
      <v-card v-if="details">
        <v-toolbar dense>
          <v-btn 
            icon
            @click="detailDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              text
              @click="detailDialog = false"
            >
              Fermer
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text >
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Tour</th>
                  <th class="text-center">Parcours</th>
                  <th class="hidden-sm-and-down">Départ</th>
                  <th class="text-right">Temps</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(match,idx) in details.Matches" :key="idx" >
                  <td class="text-center">
                    {{idx+1}}
                  </td>
                  <td class="text-center">
                    <b>{{match.TrackName}}</b>
                  </td>
                  <td class="hidden-sm-and-down">
                    {{match.Start}}
                  </td>
                  <td class="text-right">
                    {{formatTime(match.Time)}}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          
        </v-card-text>
      </v-card>
    </v-dialog>    

    <v-dialog
      v-model="drawer"
      fullscreen
      hide-overlay
    >
      <v-card>
        <v-toolbar
          dense
        >
          <v-btn
            icon
            @click="drawer = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Participants</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              text
              @click="drawer = false"
            >
              Fermer
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Nr</th>
                  <th>Team</th>
                  <th>Dst validée</th>
                  <th class="hidden-xs-only">Parcours Actuel</th>
                  <th class="hidden-sm-and-down">Dernier tour</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="sum in summary" :key="sum.Bib" @click.stop="selectParticipant(sum)">
                  <td>
                    n°{{sum.Bib}}
                  </td>
                  <td>
                    <b>{{cfg.Registrations[sum.Bib].Team}}</b>
                  </td>
                  <td class="text-center">
                    <span v-if="sum.Laps>0">{{formatDistance(sum.TotalDistance)}}</span>
                    <span v-else>-</span>
                  </td>
                  <td class="text-center hidden-xs-only">  
                    <span v-if="sum.CurTrack">
                      {{sum.CurTrack}}<br/>
                      {{formatDistance(sum.CurTrackDistance)}}
                    </span>
                    <span v-else>-</span>
                  </td>
                  <td class="text-center hidden-sm-and-down" >
                    <span v-if="sum.LastTrack">
                      {{sum.LastTrack}}<br/>
                      {{formatTime(sum.LastTrackTime)}}
                    </span>
                    <span v-else>-</span>
                  </td>
                  <td>
                    <v-icon v-if="sum.Laps>0" @click.stop="showDetails(sum.Bib)" small>mdi-plus</v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>
      </v-card>
      
    </v-dialog>
    <div style="display:none">
      <div ref="popupContent">
        <div v-if="selected">
          n°<b>{{selected.Bib}} - {{cfg.Registrations[selected.Bib].Team}}</b><br/><br/>
          <p v-if="selected.Laps>0">
            Boucles effectuées: <b>{{selected.Laps}}</b><br/>
            Distance totale parcourue: <b>{{formatDistance(selected.TotalDistance)}}</b><br/>
            Temps total: <b>{{formatTime(selected.TotalTime)}}</b>
            <br/>
            <a @click.stop="showDetails(selected.Bib)" ><b>Voir les tours</b></a>
          </p>
          <p v-if="selected.LastTrack">
            Dernière boucle: <b>{{selected.LastTrack}}</b><br/>
            Temps boucle: <b>{{formatTime(selected.LastTrackTime)}}</b><br/>
            Arrivée: <b>{{selected.LastTrackFinish}}</b>
            <br/>
            <a @click.stop="showDetails(selected.Bib)" ><b>Voir les détails</b></a>
          </p>
          <p v-if="selected.CurTrack">
            Parcours actuel: <b>{{selected.CurTrack}}</b><br/>
            Distance effectuée: <b>{{formatDistance(selected.CurTrackDistance)}}</b><br/>
            Parcours démaré a: <b>{{selected.CurTrackStart}}</b><br/>
            Temps parcours actuel: <b>{{formatTime(selected.CurTrackTime)}}</b>
          </p>
          <small>{{selected.LastLat.toFixed(5)}},{{selected.LastLon.toFixed(5)}}</small>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import eventService from "@/services/eventService.js";
import eventTrackingService from "@/services/eventTrackingService.js";

import appSettingsService from "@/appSettingsService";
import genericResultService from "@/services/genericResultService";

import crTools from "@/services/crTools.js";

import polyline from "@/services/polyline";
import tracktools from "@/services/trackTools";
import mapboxgl from "mapbox-gl/dist/mapbox-gl.js";

import { _ } from "vue-underscore";

export default {
  props: ["reportList"],

  data() {
    return {
      loading: false,
      drawer:false,
      cfg:null,
      details:null,
      detailDialog:false,
      search:'',
      selected:null,
      summary:[]
    };
  },
  computed: {},

  watch: {},

  beforeDestroy() {
    this.$parent.$parent.fullScreen=false;
    clearInterval(this.timerInterval);
  },
  mounted() {
    mapboxgl.accessToken = appSettingsService.get("mapbox");
    
    this.$parent.$parent.fullScreen=true;
    console.log("parent: ",this.$parent.$parent.eventView)
    
    var ctx = this.$route.params.context;
    //Get(customer,db,discipline,key) {
    genericResultService.Get("uci",ctx,"tracking","context").then(cfg=>{      
      //console.log("fuck: ",cfg);
      this.cfg=cfg;
      this.buildMap(cfg);
      
      this.timerInterval = setInterval(() => this.updateTrackerData(), 7500);
      this.updateTrackerData();
    });

    console.log("CTX");

    /*eventTrackingService.getEventTrackingConfiguration(eventId).then(cfg=>{
      this.cfg=cfg;
      this.buildMap(cfg);

      this.timerInterval = setInterval(() => this.updateTrackerData(), 5000);
    });*/


  /*  setTimeout(() => {
      
    }, 0);*/
  },
  methods: {
    showDetails(bib)
    {
      var ctx = this.$route.params.context;
      console.log("show details for ",bib);
      genericResultService.Get("uci",ctx,"tracking",bib).then(details=>{        
        console.log("participant details: ",details);
        this.details=details;     
        this.detailDialog=true;
      });
    },
    selectParticipant(sum)
    {
      console.log("click row: ",sum);
      if (sum.ShowTracker)
      {
        this.map.jumpTo({center: [sum.LastLon, sum.LastLat]});
        this.drawer=false;
        
        /*new mapboxgl.Popup({offset: [0,0]})
          .setLngLat([sum.LastLon, sum.LastLat])
          .setHTML(this.buildDescription(sum))
          .addTo(this.map);*/
        this.showPopup(sum.Bib);
      }
    },
    formatDistance(dst)
    {
      return crTools.formatDistance(dst);
    },
    formatTime(time)
    {
      return crTools.formatTime(time);
    },
    buildDescription(sum)
    {
      var reg=this.cfg.Registrations[sum.Bib];
      //console.log("REG ",reg,sum);

      let toRet="n°<b>"+reg.Bib+" - "+reg.Team+"</b><br/>";
      
      if (sum.Laps>0)
      {
        toRet=toRet+"<p>Boucles effectuées: <b>"+sum.Laps+"</b><br/>"+
          "Distance totale parcourue: <b>"+crTools.formatDistance(sum.TotalDistance)+"</b><br/>"+
          "Temps total: <b>"+crTools.formatTime(sum.TotalTime)+"</b>"+
          "</p>";
      }

      if (sum.LastTrack)
      {
        toRet=toRet+"<p>Dernière boucle: <b>"+crTools.formatDistance(sum.LastTrackDistance)+"</b><br/>"+
            "Temps boucle: <b>"+crTools.formatTime(sum.LastTrackTime)+"</b><br/>"+
            "Arrivée a: <b>"+sum.LastTrackFinish+"</b>"+
            "</p>";
      }

      if (sum.CurTrack)
      {
        toRet=toRet+"<p>Parcours actuel: <b>"+sum.CurTrack+"</b><br/>"+
          "Distance effectuée: <b>"+crTools.formatDistance(sum.CurTrackDistance)+"</b><br/>"+
          "Parcours débuté a: <b>"+sum.CurTrackStart+"</b><br/>"
          "Temps parcours actuel: <b>"+crTools.formatTime(sum.CurTrackTime)+"</b></p>";
      }      

      toRet=toRet+"<br/><small>"+sum.LastLat.toFixed(5)+','+sum.LastLon.toFixed(5)+"</small>";

      return toRet;
    },
    updateTrackerData()
    {
      var ctx = this.$route.params.context;
      genericResultService.Get("uci",ctx,"tracking","summary").then(summary=>{
        //console.log("SUMMARY: ",summary)
        this.summary = summary;

        if (this.trackersSource==null) return;
        this.trackersSource.features = [];     

        summary.forEach(s=>{
          if (s.ShowTracker)
          {
            let toAdd = {
              id: s.Bib,
              type: "Feature",
              properties: {
                // "name": (i % 4 == 0 ? pt.distance / 1000.0 + "km" : ""),
                icon: "green",
                name: s.Bib
              },
              geometry: {
                type: "Point",
                coordinates: [s.LastLon, s.LastLat]
              },
            };

            //console.log("ADD lala: ",toAdd)
            //console.log(this.cfg)

            this.trackersSource.features.push(toAdd);
          }
        })

        this.map.getSource("trackersSource").setData(this.trackersSource);

      });
      /*
      var eventId = this.$route.params.eventId;
      console.log("update tracker data ",eventId);
      
      eventTrackingService.getTrackersData(eventId).then(data=>{        
        console.log("Tracker data ",data)

        this.trackersSource.features = [];

        for (var k in data)
        {
          var relatedCfg=this.cfg.Trackers[k];
          if (relatedCfg)
          {
            var trackerData=data[k];

            console.log("lala ",trackerData,relatedCfg)

            let toAdd = {
              id: k,
              type: "Feature",
              properties: {
                // "name": (i % 4 == 0 ? pt.distance / 1000.0 + "km" : ""),
                description: k+" - "+relatedCfg.DisplayName,
                icon: relatedCfg.Marker,
                name: relatedCfg.DisplayName,
                xbearing: 0,
              },
              geometry: {
                type: "Point",
                coordinates: [trackerData.Lon, trackerData.Lat],
              },
            };
            this.trackersSource.features.push(toAdd);
          }

        };

        this.map.getSource("trackersSource").setData(this.trackersSource);
      });
    */

    },

    addImage(url, name) {

      return new Promise((resolve, reject) => {
        this.map.loadImage(url, (error, image) => {
          if (error) {
            //console.log("LOAD IMAGE ERRROR");
            console.log(error);
            reject(error);
            throw error;
          }
          //console.log("AddImage success");
          this.map.addImage(name, image);
          //console.log("image "+url+" loaded")
          resolve(url);
        });

      });
    },

    buildTrackData(cfg) 
    {
      console.log("build: ",cfg)

      var bounds = new mapboxgl.LngLatBounds();

      var trackSource = {
        type: "FeatureCollection",
        features: [],
      };          
      
      var poiSource = {
        type: "FeatureCollection",
        features: [],
      };

      this.trackersSource = {
        type: "FeatureCollection",
        features: []
      };


      var distanceSource = null;

      for (var ix in cfg.Tracks) {
        var track=cfg.Tracks[ix];
        var pts = polyline.decode(track.Polyline);
        var tk = tracktools.pointsAsTrack(pts);      

        pts.forEach(function (pt) {
          
          bounds.extend(pt);
        });

        var trackFeature = {
          type: "Feature",
          properties: {
            color: track.Color,
          },
          geometry: {
            type: "LineString",
            coordinates: pts,
          },
        };

        trackSource.features.push(trackFeature);

        //var tk = tracktools.pointsAsTrack(pts);
        distanceSource = tracktools.buildDistanceSource(
          this.map,
          tk,
          distanceSource
        );


        var trackFeature = {
          type: "Feature",
          properties: {
            color: track.Color,
          },
          geometry: {
            type: "LineString",
            coordinates: pts,
          },
        };

      }

      if (cfg.POI)
      {
        cfg.POI.forEach(poi=>{
          console.log("POI: ",poi)
          var pt=[poi.Lon, poi.Lat];

          bounds.extend(pt);

          let toAdd = {
            id: poi.Name,
            type: "Feature",
            properties: {
              // "name": (i % 4 == 0 ? pt.distance / 1000.0 + "km" : ""),
              name: poi.Name,
              bearing: 0,
  //            icon: poi.Icon,
              icon: poi.Icon,
            },
            geometry: {
              type: "Point",
              coordinates: pt,
            },
          };

          poiSource.features.push(toAdd);
        });

      }



      this.map.addSource("trackSource", {
        type: "geojson",
        data: trackSource,
      });
      
      
      this.map.addSource("poiSource", {
        type: "geojson",
        data: poiSource,
      });

      //console.log("track source added");
      this.map.addLayer({
        id: "track",
        type: "line",
        source: "trackSource",
        paint: {
          "line-color": "white",
          "line-width": 6,
        },
      });
      this.map.addLayer({
        id: "tracksLayer",
        type: "line",
        source: "trackSource",

        paint: {
          "line-color": ["get", "color"],
          "line-dasharray": [3, 1.5],
          "line-width": 2.5,
        },
      });
      
      tracktools.addDistanceLayer(this.map, distanceSource);

      this.map.addLayer({
        id: "poiLayer",
        type: "symbol",
        source: "poiSource",
        xminzoom: 14,
        layout: {
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
          "icon-image": ["get", "icon"],
          "icon-rotation-alignment": "map",
          "icon-size": 0.4,
          "icon-offset": [0, 0],
          "text-field": ["get", "name"],
          "text-size": 14,
          "text-font": ["Arial Unicode MS Bold"],
          "text-offset": [0, -0.65],
          "text-anchor": "top",
        },
        paint: {
          "text-color": "#ffffff",
        },
      });


      this.map.fitBounds(bounds, { duration: 0 });


      this.map.addSource("trackersSource", {
        type: "geojson",
        data: this.trackersSource,
      });

      this.updateTrackerData();


      //this.addControls();

      this.map.addLayer({
        id: "trackersLayer",
        type: "symbol",
        source: "trackersSource",
        xminzoom: 14,
        layout: {
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
          "icon-image": ["get", "icon"],
          "icon-rotate": ["get", "bearing"],
          "icon-rotation-alignment": "map",
          "icon-size": 0.15,
          "icon-offset": [0, 0],
          "text-field": ["get", "name"],
          "text-size": 14,
          "text-font": ["Arial Unicode MS Bold"],
          "text-offset": [0, -2],
          "text-anchor": "top",
        },
        paint: {
          "text-color": "black",
        },
      });



      // When a click event occurs on a feature in the places layer, open a popup at the
      // location of the feature, with description HTML from its properties.
      this.map.on('click', 'trackersLayer', (e) => {

        console.log("click on trackerslayer",e);


        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates.slice();
        //const description = e.features[0].properties.description;
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        this.showPopup(e.features[0].properties.name);
      }); 


    },

    showPopup(bib)
    {
      console.log("click icon with name: "+bib);
      
      let sum= _.find(this.summary, i=>i.Bib==bib);

      if (sum!=null)
      {
        this.selected=sum;
      } else return;

      console.log("show popup ok ")
      //this.summary

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      
      new mapboxgl.Popup({offset: [0,0]})
        .setLngLat([sum.LastLon,sum.LastLat])
        .setDOMContent(this.$refs.popupContent)
        .addTo(this.map);    
    },

    buildMap(cfg) {
      this.map = new mapboxgl.Map({
        container: "mapContainer",
        style: "mapbox://styles/chronorace/ckli3u1v60xaj17k3583i2f42",
        //center: [first.lon, first.lat ],
        zoom: 16,
        /*xmaxBounds: [
                [minLon-0.25, minLat-0.25],
                [maxLon+0.25, maxLat+0.25],
            ],*/
      });

      var debug = this.$route.query.debug;
      //console.log("DEBUG: ",debug);
      if (debug=='1')
      {
        console.log("MAP CLIC DEBUG ENABLED");
        this.map.on('click', (e) => {
            console.log(JSON.stringify(e.point),JSON.stringify(e.lngLat.wrap()));
            eventTrackingService.log('test',[{ Lat: e.lngLat.lat, Lon: e.lngLat.lng, Time:new Date() }])
        });
      }          


      //this.map.addControl(new mapboxgl.AttributionControl(), 'top-left');
      this.map.addControl(new mapboxgl.FullscreenControl());
      this.map.addControl(new mapboxgl.NavigationControl());

      this.map.on("load", () => {
        this.map.on("styleimagemissing", function (e) {
          var id = e.id; // id of the missing image
          console.log("missing image: " + id);
        });

        var images=[];
        images.push(this.addImage("/markers/round_yellow.png", "yellow"));
        images.push(this.addImage("/markers/round_green.png", "green"));
        images.push(this.addImage("/markers/round_red.png", "red"));
        images.push(this.addImage("/markers/round_orange.png", "orange"));
        images.push(this.addImage("/markers/round_blue.png", "blue"));

        Promise.all(images).then(success=>{
          console.log("load images success ",success)

          this.buildTrackData(cfg);
          console.log("buildmap",cfg)

        },error=>{
          console.log("Load images failed",error)
        });

        console.log("event tracking config: ",cfg);

        //setTimeout(()=>this.loadDetails(),1500);


      });

    },

    ready() {

    },

  },
};
</script>
