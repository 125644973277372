<template>
  <span v-if="node">
    
    <CMSFolders
      :node="node"
      level="1"
      :selection="selection"
      @selected="SelectNode"
    ></CMSFolders>

    <v-container
      class="pt-2 pb-2"
      style="max-width: 1150px; xbackground-color: orange"
    >
    
      <v-row v-if="Folders" dense>
        <v-col md="4" v-for="f in Folders" :key="f.Id">
          <CompetitionList @selected="SelectNode" :node="f"></CompetitionList>
        </v-col>
      </v-row>      
    </v-container>

    <!-- <pre>
      selection= 
      {{selection}}
    </pre> -->
  </span>
</template>

<script>
import CMSFolders from "./Components/CMSFolders";

import LeafNodesContainer from "./Components/LeafNodesContainer";
import CompetitionList from "./Components/CompetitionList";

import { _ } from "vue-underscore";

export default {
  props: ["node"],

  components: { LeafNodesContainer, CMSFolders, CompetitionList },

  data() {
    return {
      path: [],
      selection: {},
    };
  },
  computed: {
    SelectedPath: function () {
      return this.$route.params.path;
    },
    SelectedNode: function () {
      if (this.node == null) return;
      return this.node.Index[this.SelectedPath];
    },
    Folders: function () {
      /*if (this.SelectedNode == null || this.SelectedNode.Level != 2)
        return null;*/
        console.log("FOLDERS ON CMS: ",this.selection)
      if (this.selection==null) return;

      var selected2=this.selection["2"];

      if (!selected2) return null;
      
      return this.node.Index[selected2].Folders;
    },
  },
  beforeRouteEnter(to, from, next) {
    console.log("CMS ROUTE ENTER");
    next();
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },

  methods: {
    SetPath(p) {
      if (this.$route.params.path && this.$route.params.path == p) return;

      console.log("PATH ", p);
      this.$router.push({ name: "eventcms", params: { path: p } }, (err) => {
        console.log("router err");
      });
    },
    SelectNodeId(id) 
    {
      if (this.node == null) return;
      var selectedNode = this.node.Index[id];
      if (selectedNode == null) return;
      this.SelectNode(selectedNode);
    },
    SelectNode(node) {
      console.log("node selected???",node);
      if (!node) return;
      if (node.Route && node.Route != "") {
        var route = JSON.parse(node.Route);
        console.log("select node route", route, node.Id);

        var params = { ...route.Params, path: node.Id };

        console.log("route params", params);

        this.$router.push({
          name: route.Route,
          params: params,
        });
        return;
      } else if (node.Link && node.Link != "") {
        //console.log("LINK CLICKED");
        window.location = node.Link;
        return;
      } else if (node.Childs == 0) {
       // console.log("leaf node cliqued", node);
        return;
      }

      //console.log("SELECT NODE ", node.Id, node.Level, node.Folders.length);
      if (node.Level < 2 && node.Folders.length > 0)
        this.SelectNode(node.Folders[0]);
      else if (node.Level>=2 && node.Childs.length==1)
      {
        this.SelectNode(node.Childs[0]);
      }
      else {
      //  console.log("LEVEL = ", node.Level);
       // console.log("NODE selection level " + this.level, node);
        setTimeout(() => this.SetPath(node.Id));
      }
    },
    updateSelectedNode() {
      //if (!this.SelectedPath && this.node) this.SelectNode(this.node);

      //this.selectedNodes=selectedNodes;
      var selection = {};

      var updateSelectedNodeInt = (n, level) => {
        if (n == null) return false;
        if (n.Id && n.Id == this.$route.params.path) {
          selection[level] = n.Id;
          return true;
        } else {
          for (var chl in n.Childs) {
            var chlNode = n.Childs[chl];
            if (updateSelectedNodeInt(chlNode, level + 1)) {
              selection[level] = n.Id;
              return true;
            }
          }
        }
        return false;
      };
      updateSelectedNodeInt(this.node, 0);
      console.log("SELECTION: ", selection);
      this.selection = selection;
      // console.log("FOLDERS IN SELECT ",this.Folders);
    },

    getRoute(k) {
      if (!k) return;
      //console.log("getroute",k);
      var toRet = { name: "eventcms2", params: { l2: k.Id } };
      return toRet;
    },
  },
  watch: {
    "$route.params.path": function (o, n) {
      console.log("PATH WATCH CALLED", n);
      setTimeout(() => this.updateSelectedNode(), 0);

      //this.level1=2;
    },
    node: function (o, n) {
      console.log("NODE WATCH CALLED IN CMS");
      setTimeout(() => this.updateSelectedNode(), 0);
    },
  },

  beforeDestroy() {},
  updated() {
    //  console.log("component updated", this.$route);
    console.log("CMS updated", this.node);
    if (!this.$route.params.path && this.node) this.SelectNode(this.node);
    else this.SelectNode(this.SelectedNode);
    //this.updateSelectedNode();
  },
  mounted() {
    console.log("CMS mounted  ", this.node);

    setTimeout(() => {
      if (!this.$route.params.path && this.node) this.SelectNode(this.node);
      else this.SelectNode(this.SelectedNode);

      console.log("CMS MOUNTED TIME", this.node);
      this.updateSelectedNode();
    }, 0);
    //this.updateSelectedNode();
  },
};
</script>
