<template>
  <div v-if="settings" ref="container" >    
    <div style="display:flex;flex-direction: column;height: 90px;">
      <div style="background-color: yellowgreen;height: 90px;display:flex;flex-direction: row;align-items: center;text-align: center;">
        <div style="flex:1.5">
          <div style="font-style: Soleil;font-size: 25px;">Tours</div>
          <div style="font-size: 50px;margin-top:-20px;font-style: Soleil;font-weight: bold;">{{settings.Stats.laps}}</div>
        </div>
        <div style="flex:1.5">
          <div style="font-style: Soleil;font-size: 25px;">Montant/Tour</div>
          <div style="font-size: 50px;margin-top:-20px;font-style: Soleil;font-weight: bold;">{{settings.AmountPerLap}}&euro;</div>
        </div>
        <div style="flex:2">
          <div style="font-style: Soleil;font-size: 25px;">Total</div>
          <div style="font-size: 50px;margin-top:-20px;font-style: Soleil;font-weight: bold;">{{(settings.Stats.laps*settings.AmountPerLap).toFixed(2)}}&euro;</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import genericResultService from "@/services/genericResultService";

export default {
  data() {
    return {
      settings:null
    }
  },
  components: {
  },
  methods:{
    refreshData()
    {
      console.log("refresh");
      return genericResultService.Get("uci",this.$route.params.context,"running","crac").then(data=>{
        console.log("remote settings updated: ",data);
        
        this.$set(this,'settings',data);
      });
    }
  },
  beforeDestroy: function () 
  {
    if (this.interval) window.clearInterval(this.interval);
  },
  mounted: function()
  {
    this.interval=setInterval(()=>{
      this.refreshData();
    },30000);
    this.refreshData();
  }
}
</script>
