<template>
  <div>
    <Hero5Demo />
    <v-sheet>
      <v-container class="py-4 py-lg-8">
        <div class="text-center">
          <div class="text-uppercase font-weight-bold body-2 primary--text mb-2">Work with us</div>
          <h2 class="text-h3 text-lg-h2">Get your startup ready for business</h2>
          <v-responsive max-width="1200" class="mx-auto">
            <div class="text-h6 text-lg-h5 mt-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus impedit error labore doloremque fugit! Dolor fugit molestiae vero quos quisquam nobis, eos debitis magni omnis ea incidunt amet voluptate dignissimos!</div>
          </v-responsive>
        </div>
        <v-row class="mt-4">
          <v-col v-for="(item, i) in features" :key="i" cols="12" md="6">
            <div class="d-flex">
              <div class="mr-2">
                <v-sheet color="surface" rounded class="pa-2">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-sheet>
              </div>
              <div>
                <div class="text-h5">{{ item.title }}</div>
                <div class="text-h6 mt-1">{{ item.description }}</div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>        
    <Footer />
  </div>
</template>

<script>
import Hero5Demo from '@/components/ui/landing/hero/Hero5Demo.vue'
import Footer from '@/components/ui/landing/footer/Footer8Demo.vue'

export default {
  components: {
    Hero5Demo,
    Footer
    /*Logos2Demo,
    CallToAction5Demo,
    Feature11Demo,
    Footer4Demo,
    Team6Demo*/
  },
  data() {
    return {
      features: [{
        icon: 'mdi-account-check-outline',
        title: 'Account Verification',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse expedita fugit quam aliquam. Autem assumenda'
      }, {
        icon: 'mdi-lifebuoy',
        title: 'Dedicated Support',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse expedita fugit quam aliquam. Autem assumenda'
      }, {
        icon: 'mdi-email-open-multiple-outline',
        title: 'Email Integration',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse expedita fugit quam aliquam. Autem assumenda'
      }, {
        icon: 'mdi-clock-outline',
        title: 'Save Time',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse expedita fugit quam aliquam. Autem assumenda'
      }]
    }
  }
}
</script>
