<template>
  <span>
    <CMSBreadCrumb :cms="node" :path="path"></CMSBreadCrumb> 

  
    <v-container
      class="pt-2 pb-2 results"
      style="max-width: 1150px; xbackground-color: orange"
    >


    <v-row dense>
      <v-col cols="7">
        <v-card>
          <v-card-text> hello </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="5">
        <v-card>
          <v-card-title> Organisateur </v-card-title>
          <v-card-text> kljgklfd gjkdf jkl </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    </v-container>

    <!-- <v-btn @click="goToParent()">HELLO</v-btn> -->
  </span>
</template>

<script>
import eventService from "@/services/eventService.js";
import CMSBreadCrumb from "./Components/CMSBreadCrumb";
import { _ } from "vue-underscore";
import Vue from 'vue';

export default {
  props: ["node", "selection"],
  components: { CMSBreadCrumb },
  data() {
    return {
    };
  },
  computed: {
    path: function () {
      return this.$route.params.path;
    }

  },
  methods: {
  
  },
  watch: {
  
    cms: function (o, n) {
      console.log("cms setting updated");
    },
  },

  beforeDestroy() {
  },
  updated() {
    //   console.log("component updated", this.$route);
  },
  mounted() {
  },
};
</script>
