<template>
  <span>    
    <v-container
      class="pt-2 pb-2 results"
      style="max-width: 1150px; xbackground-color: orange" >    

      <v-card class="py-2 px-0">
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr class="light-blue accent-1">
                  <th class="text-right">{{ $t("Lap") }}</th>
                  <th class="text-right">{{ $t("Start") }}</th>
                  <th class="text-right">{{ $t("Time") }}</th>
                  <th class="text-center">{{ $t("Pos") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="lap in details.Laps" :key="lap.Lap">
                  <td class="text-right">
                    {{lap.Lap+1}}.
                  </td>
                  <td class="text-right">
                    {{formatTime(lap.LapStartTime)}}
                  </td>
                  <td class="text-right">
                    {{formatTime(lap.LapTime)}}
                  </td>
                  <td class="text-center">
                    {{lap.Pos}}
                  </td>
                </tr>
              </tbody>  
            </template>
          </v-simple-table> 
          
        </v-card-text>  
      </v-card> 
    </v-container>  

  </span>

</template>

<script>

import { _ } from "vue-underscore";

import crTools from "@/services/crTools.js";

export default {
  props: ["feature","details"],
  methods: {    
    formatDistance(distance) {
      return crTools.formatDistance(distance);
    },

    formatTime(input) {
      return crTools.formatTime(input);
    },

    formatPos(input) {
      if (input=='-3') return '-';
      if (input=='-1') return 'DSQ';
      if (input>0) return input;
      return '-';
    },

  },
  data() {
    return {
    };
  },
  computed: {},
  watch: {

  },

  beforeDestroy() {},
  updated() {},
  mounted() {
  },
};
</script>
