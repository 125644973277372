<template>
  <div>
    <v-dialog
      v-model="showLaneSelection"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="primary">
          <v-btn icon  @click="showLaneSelection = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Lane selection</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <!--<v-btn dark text @click="showLaneSelection = false"> Save </v-btn>-->
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td
                    v-for="lane in lanes"
                    :key="lane.Lane"
                    class="text-center pa-1 ma-0"
                  >
                    <div
                      @click="setLane(lane)"
                      class="rounded-lg"
                      :style="
                        {
                          'background-color': getBgColor(lane),
                          'height': '75px',
                          'border': '1px solid black'
                        }
                      "
                    >
                      Lane {{ lane.Lane }}<br />
                      <h3 v-if="lane.OriginalBib != 0">
                        n°{{ lane.OriginalBib }}
                      </h3>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="cleanLane()">Clear</v-btn>
          <v-btn @click="showLaneSelection = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-text>
      <div class="text-h4">Lanes</div>

      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
            <tr>
              <td
                v-for="lane in lanes"
                :key="lane.Lane"
                class="text-center pa-1 ma-0"
              >
                <div
                  class="rounded-lg"
                  :style="
                    {
                      'background-color': getBgColor(lane),
                      'height': '75px',
                      'border': '1px solid black'
                    }
                  "
                >
                  Lane {{ lane.Lane }}<br />
                  <h3 v-if="lane.OriginalBib != 0">n°{{ lane.OriginalBib }}</h3>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <br />
      <div class="text-h4">Selection Order</div>
      <v-row v-for="rider in curRace.Riders" :key="rider.Bib" class="ma-0">
        <v-col cols="10" class="ma-0 pa-1">
          <v-btn block outlined asmall @click="selectLane(rider)" :style="{ 'background-color':getBgColorRider(rider)  }" >
            {{ rider.OriginalBib }} - {{ rider.LastName }} ({{ rider.IOC }})
          </v-btn>
        </v-col>
        <v-col cols="2" class="ma-0 pa-1">
          <v-btn block outlined asmall :style="{ 'background-color':getBgColorRider(rider)  }">
            <span v-if="rider.Lane && rider.Lane!=0"> {{rider.Lane}}</span>
            <span v-else>-</span>
          </v-btn>
        </v-col>
      </v-row>

      <!-- <pre>{{ curRace }}</pre> -->
    </v-card-text>
  </div>
</template>

<script>
import eventService from "@/services/eventService.js";
import genericResultService from "@/services/genericResultService.js"

export default {
  components: {},
  data() {
    return {
      curRace: {},
      raceName: "",
      lanes: [],
      lanesByBib: {},
      showLaneSelection: false,
      currentRider:null
    };
  },
  watch: {
    "$route.params.race": function (v, prev) {
      console.log("route changed?", v);
      this.setRace(v);
    },
    raceList: function (v, prev) {
      console.log("new val: ", v);
      this.setRace(this.raceName);
    },
  },
  beforeRouteEnter(to, from, next) {
    console.log("before route enter: ", to.params.race);
    next();
    /*eventService.getEventView(to.params.eventId).then((eventView) => {
      console.log('eventView', eventView)
      next((vm) => {
        // access to component instance via `vm`
        vm.setEventView(eventView)

      })
    })*/
  },
  computed: {},
  mounted: function () {
    console.log("results  mounted");
    var i = 0;
    while (i < 8) {
      i++;
      this.lanes.push({ Lane: i, Bib: 0, OriginalBib: 0 });
    }

    this.setRace(this.$route.params.race);
  },
  methods: {
    setRace(raceName) {
      this.raceName = raceName;
      console.log("set race", raceName);
      this.curRace = this.raceList[raceName];

      genericResultService.Uci(this.$route.params.db,'xco',raceName).then(data=>{
        if (data!=null)
        {
          //console.log("existing lanes: ",data)
          data.forEach(lane=>{
            console.log("lane ",lane)
            if (lane.Lane>0)  {
              this.$set(this.lanes,lane.Lane-1,lane)
              //this.lanes[lane.Lane-1]=lane
            }
            if (lane.Bib!=0)
            {
              this.curRace.Riders.forEach(cr=>{
                if (cr.Bib==lane.Bib)
                {
                  console.log("related rider found - change lange")
                  cr.Lane=lane.Lane

                }
              })
            }
          })

          this.$forceUpdate();

          //this.curRace=this.curRace

          console.log("local lanes: ",this.lanes)
        }
        else{
          console.log("no existing data")
        }
      },error=>{
        console.log("error while fetching existing data",error)
      })

    },
    getBgColorRider(rider)
    {
      if (rider.Lane && rider.Lane!=0) return "lime";
    },
    getBgColor(lane)
    {
      if (lane.Bib && lane.Bib!=0) return "lime";
    },
    selectLane(rider) {


      console.log("Select lane",rider);
      this.currentRider=rider;
      this.showLaneSelection = true;
    },
    cleanLane()
    {
      this.setLane(null);
    },
    setLane(lane)
    {
      console.log("Set lane:",lane," for rider ",this.currentRider);
      if (this.currentRider.Lane && this.currentRider.Lane!=0)
      {
        this.lanes[this.currentRider.Lane-1].Bib=0;
        this.lanes[this.currentRider.Lane-1].OriginalBib=0;
        this.lanes[this.currentRider.Lane-1].FirstName=null;
        this.lanes[this.currentRider.Lane-1].LastName=null;
      }


      if (lane && lane.Bib && lane.Bib!=0)
      {
        console.log("lane already assigned");
        var i=0;
        while (i < this.curRace.Riders.length)
        {
          if (this.curRace.Riders[i].Bib==lane.Bib)
          {
            console.log("found...");
            this.curRace.Riders[i].Lane=0;
          }
          i++;
        }
      }

      if (lane)
      {
        lane.Bib=this.currentRider.Bib;
        lane.OriginalBib=this.currentRider.OriginalBib;
        lane.FirstName=this.currentRider.FirstName;
        lane.LastName=this.currentRider.LastName;

        this.currentRider.Lane=lane.Lane;
      }
      else
      {
        if (this.currentRider.Lane!=0)
        {
          this.lanes[this.currentRider.Lane-1].Bib=0;
          this.lanes[this.currentRider.Lane-1].OriginalBib=0;
          this.lanes[this.currentRider.Lane-1].FirstName=null;
          this.lanes[this.currentRider.Lane-1].LastName=null;
          this.currentRider.Lane=0;
        }
      }

      this.showLaneSelection = false;
      this.currentRider=null;

      console.log("selection data ",this.lanes);

      genericResultService.Upload(this.$route.params.db,'xco',this.raceName,this.lanes).then(data=>{
        console.log("lanes persisted ",data)
      });
    },
    ready() {
      console.log("ready");
    },
  },

  props: ["race-list"],
};
</script>

<style scoped>
</style>
