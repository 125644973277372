import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const routes = [
  {
    path: '/events/:eventId',
    name: 'events',

    component: () => import(/* webpackChunkName: "results" */ '@/pages/events/template.vue'),
    children: [
      {
        name: 'eventtracking', path: 'tracking/:key?', component: () => import('@/pages/events/tracking.vue'),
      },
      {
        name: 'eventcmsv1', path: 'cms/:db/:key?', component: () => import('@/pages/events/cms.vue'),
        children: [

        ]
      },
      {
        name: 'eventctx', path: 'ctx/:db', component: () => import('@/pages/events/ctx.vue'),
        children: [
          {
            name: 'eventcms', path: 'cms/:path?', component: () => import('@/pages/events/cms.vue'),
            children: [

            ]
          },
          {
            name: 'events.generictemplate', path: 'generic/:path', component: () => import('@/pages/events/generic.vue'),
            children: [
              {
                name: 'events.genericresult', path: 'home/:key', component: () => import('@/pages/events/generichome.vue')
              },
              {
                name: 'events.scroller', path: 'scroller/:key/:size/:delay', component: () => import('@/pages/events/scroller.vue')
              },
              {
                name: 'events.genericdetail', path: 'detail/:id', component: () => import('@/pages/events/detail.vue')
              },
              {
                name: 'events.genericdetailv2', path: 'objectdetail/:token', component: () => import('@/pages/events/objectdetail.vue')
              },
              {
                name: 'events.streaming', path: 'streaming', component: () => import('@/pages/events/streaming.vue')
              }

            ]
          },
          {
            name: 'events.contacts', path: 'contacts/:path', component: () => import('@/pages/events/contacts.vue'),

          }


        ]
      },
      {
        name: 'kotkhome', path: 'home', component: () => import('@/pages/events/home.vue'),
        children: [
        ]
      },
      {
        name: 'kotkcontact', path: 'contact', component: () => import('@/pages/events/contact.vue')
      },
      {
        name: 'favorites', path: 'favorites', component: () => import('@/pages/events/home.vue'),
        children: [

        ]
      },
      {
        name: 'map', path: 'map', component: () => import('@/pages/events/map.vue'),
        children: [

        ]
      },
      {
        name: 'kreportList', path: 'reportlist', component: () => import('@/pages/results/ReportList.vue'),
        children: [
          { name: 'kreport', path: 'group/:group/report/:report', component: () => import('@/pages/results/ReportViewer.vue') },
          { name: 'ktable', path: 'table/:group/report/:report', component: () => import('@/pages/results/TableViewer.vue') }
        ]
      },
      // { path: '', redirect: 'home' }
    ]
  },
  {
    path: '/tracking/cap48/:context/:debug?',
    name: 'cap48',
    component: () => import(/* webpackChunkName: "results" */ '@/pages/tracking/cap48.vue')
  },
  {
    path: '/live/clm/:db/:key',
    name: 'roadtt',
    component: () => import(/* webpackChunkName: "road" */ '@/pages/live/clm.vue')
  },
  {
    path: '/misc/crac/:context',
    name: 'crac',
    component: () => import(/* webpackChunkName: "musc" */ '@/pages/misc/crac.vue')
  },
  {
    path: '/results/:eventId',
    xname: 'results',
    component: () => import(/* webpackChunkName: "results" */ '@/pages/results.vue'),
    children: [
      {
        name: 'reportList', path: 'reportlist', component: () => import('@/pages/results/ReportList.vue'),
        children: [
          { name: 'report', path: 'group/:group/report/:report', component: () => import('@/pages/results/ReportViewer.vue') },
          { name: 'table', path: 'table/:group/report/:report', component: () => import('@/pages/results/TableViewer.vue') }
        ]
      },
      { name: 'youtube', path: 'youtube', component: () => import('@/pages/results/youtube.vue') },
      { path: '', redirect: 'reportList' }
    ]
  },
  {
    path: '/firstrow/:db',
    name: 'firstrow',
    component: () => import(/* webpackChunkName: "results" */ '@/pages/xco/xco.vue'),
    children: [
      { name: 'firstrowrace', path: 'race/:race', component: () => import('@/pages/xco/firstrow.vue') },
    ]
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
    children: [{
      path: '',
      name: 'starter-page',
      component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/StarterPage.vue')
    }, {
      path: 'cgv',
      name: 'cgv',
      component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/cgv.vue')
    }, {
      path: 'privacy',
      name: 'privacy',
      component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/privacy.vue')
    }]
  },

  {
    path: '*',
    component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/ErrorLayout.vue'),
    children: [{
      path: '',
      name: 'error',
      component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue')
    }]
  }
]

const router = new Router({
  //mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  return next()
})

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
