<template>
  <span>
    <v-card>
      <v-card-title>{{ node.DisplayName }}</v-card-title>
      <v-card-text v-if="node.Description && node.Description != ''" v-html="node.Description">
        
      </v-card-text>

      <LeafNodes @selected="SelectNode" :node="node"></LeafNodes>
      <v-expansion-panels>
        <v-expansion-panel v-for="c in Folders" :key="c.Id">
          <v-expansion-panel-header>
            {{ c.DisplayName }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <span v-html="c.Description"></span>
            <LeafNodes @selected="SelectNode" :node="c"></LeafNodes>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </span>
</template>

<script>
import eventService from "@/services/eventService.js";

import LeafNodes from "./LeafNodes";

import { _ } from "vue-underscore";

export default {
  props: ["node","selected"],
  components: { LeafNodes },

  data() {
    return {};
  },
  computed: {
    Folders: function () {
      var curCateg = this.node;
      var toRet = [];
      //console.log("FOLDERS2 EVALUATED");
      if (!curCateg || !curCateg.Childs) return toRet;

      curCateg.Childs.forEach((chl) => {
        if (chl.Childs != null && chl.Childs.length > 0) toRet.push(chl);
        //if (!chl.Type || chl.Type!='File') toRet.push(chl);
      });

      return toRet;
    },

    SelectedNode: function () {
      if (!this.node) return null;
      console.log("SELECTED2 NODE EVALUATED");
      return _.where(this.node.Childs, { Id: this.$route.params.l2 })[0];
    },
  },
  methods: {
    updateView() {},
    SelectNode(n){
      console.log("SELECT NODE IN COMPETITION LIST",n)
      this.$emit('selected',n);
    }

  },
  watch: {},

  beforeDestroy() {},
  updated() {
    
  },
  mounted() {
    this.updateView();
  },
};
</script>
