import axios from "axios";
import appSettingsService from "../appSettingsService";

const genericResultService = new (class {

  Uci(db,discipline,key) {
    return new Promise((resolve, reject) => {
      const url = appSettingsService.get("apiUrlBase") + 'results/generic/uci/'+db+'/'+discipline+ (key?'?key='+encodeURIComponent(key):'');

      axios.get(url).then(httpReply => {
        resolve(httpReply.data);
      }).catch(function (error) {
        if (error.response) {
          reject({ errorMessage: "HTTP_" + error.response.status });
        }
        else {
          reject(error);
        }
      });

    });
  }

  Upload(db,discipline,key,data) {
    console.log("upload data ",data)
    return new Promise((resolve, reject) => {
      const url = appSettingsService.get("apiUrlBase") + 'results/generic/uci/'+db+'/'+discipline+ '/'+encodeURIComponent(key) ;

      console.log("post to ",url)

      axios.post(url,data).then(httpReply => {
        resolve(httpReply.data);
      }).catch(function (error) {
        if (error.response) {
          reject({ errorMessage: "HTTP_" + error.response.status });
        }
        else {
          reject(error);
        }
      });

    });
  }


  Get(customer,db,discipline,key) {
    return new Promise((resolve, reject) => {
      const url = appSettingsService.get("apiUrlBase") + 'results/generic/get/'+customer+'/'+db+'/'+discipline+ (key?'?key='+encodeURIComponent(key):'');

      axios.get(url).then(httpReply => {
        resolve(httpReply.data);
      }).catch(function (error) {
        if (error.response) {
          reject({ errorMessage: "HTTP_" + error.response.status });
        }
        else {
          reject(error);
        }
      });

    });

  }

  getEventView(eventId) {
    return new Promise((resolve, reject) => {
      const url = appSettingsService.get("apiUrlBase") + "Event/view/" + eventId;

      axios.get(url).then(httpReply => {
        console.log("reply", httpReply);

        resolve(httpReply.data);
      }).catch(function (error) {
        if (error.response) {
          reject({ errorMessage: "HTTP_" + error.response.status });
        }
        else {
          reject(error);
        }
      });

    });
  }

  constructor() {

  }
})();

export default genericResultService;
