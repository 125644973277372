<template>
  <cr-cell :row="row" :column="column" :definition="definition"></cr-cell>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["row","definition","column","action"],
  beforeMount: function () {},
  methods: {
  },
  mounted: function () {},
};
</script>
