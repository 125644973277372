<template>
  <div>

    <v-container
      v-if="false"
      class="pt-2 pb-2 results"
      style="max-width: 1150px; xbackground-color: orange"
    >

      <div style="display:flex;flex-direction:column;xwidth:600px;position:relative;margin-top:20px" >

        <div style="min-height:160px;xbackground-color: bisque;display: flex;align-items: stretch;z-index:2">

          <div style="display:flex;xbackground-color:red;align-items: center;flex:0.5;">
            <div style="text-align:right;flex:1">
              0:34:45 <br/>
              (1 seh)
            </div>
          </div>

          <!-- arrow -->
          <div style="display:flex;flex-direction: column; align-items:center">
            <div class="arrow-up"></div>
            <div style="flex:1;width:2px;background-color: var(--v-primary-base);"></div>
            <div class="arrow-down"></div>
          </div>

          <!-- vertical text -->
          <div style="width:30px;text-align: center; xbackground-color: orange;">
            <div class="outer" style="position:relative;display: inline-block;height:100%">
              <div style="text-align: center; position: absolute;top:50%;left:50%;transform:  translateX(-50%) translateY(-50%) rotate(-90deg);"><b>Swim</b></div>
            </div>
          </div>

          <div style="display:flex;flex-direction:row;align-items: stretch;flex:1">

            <div style="xbackground-color: red;position:relative;display:flex;flex-direction: column;align-items: center;width:50px">

              <div style="background-color: var(--v-primary-base);flex:1;width:4px">
              </div>

              <div style="position:absolute;left:50%;top: 0%;transform: translate(-50%,-50%);xbackground-color: orange;">
                <div class="dot"><v-icon>mdi-flag</v-icon></div>

                <div class="arrow-box-left" style="position:absolute;width:120px;left:60px;top:50%;transform: translate(0%,-50%);padding:8px;text-align: center;">
                  <span class="">11:00:00</span>
                </div>
              </div>

              <div style="position:absolute;left:50%;bottom: 0%;transform: translate(-50%,50%);xbackground-color: orange;">
                <div class="dot">Swim</div>

                <div class="arrow-box-left" style="position:absolute;width:120px;left:60px;top:50%;transform: translate(0%,-50%);padding:8px;text-align: center;">
                  <span class="">11:34:45</span><br/>
                  0:34:45 (1seh)
                </div>

              </div>

            </div>
            <div style="flex:1;position:relative">

            </div>
          </div>

        </div>

        <div style="min-height:120px;xbackground-color: bisque;display: flex;align-items: stretch;z-index:1">
          <div style="display:flex;xbackground-color:red;align-items: center;flex:0.5;">
            <div style="text-align:right;flex:1">
              0:34:45<br/>(1 seh)
            </div>
          </div>

          <!-- arrow -->
          <div style="display:flex;flex-direction: column; align-items:center">
            <div class="arrow-up"></div>
            <div style="flex:1;width:2px;background-color: var(--v-primary-base);"></div>
            <div class="arrow-down"></div>
          </div>

          <!-- vertical text -->
          <div style="width:30px;text-align: center; xbackground-color: orange;">
            <div class="outer" style="position:relative;display: inline-block;height:100%">
              <div style="text-align: center; position: absolute;top:50%;left:50%;transform:  translateX(-50%) translateY(-50%) rotate(-90deg);white-space: nowrap;"><b>Transition 1</b></div>
            </div>
          </div>

          <div style="display:flex;flex-direction:row;align-items: stretch;flex:1">
            <div style="xbackground-color: red;position:relative;display:flex;flex-direction: column;align-items: center;width:50px">
              <div style="background-color: var(--v-primary-base);flex:1;width:4px">
              </div>

              <div style="position:absolute;left:50%;bottom: 0%;transform: translate(-50%,50%);xbackground-color: orange;">
                <div class="dot">T1</div>
              </div>
            </div>
            <div style="flex:1;position:relative">
            </div>
          </div>
        </div>

        <div style="min-height:120px;xbackground-color: bisque;display: flex;align-items: stretch;z-index:0">
          <div style="display:flex;xbackground-color:red;align-items: center;flex:0.5;">
            <div style="text-align:right;flex:1">
              0:34:45<br/>(1 seh)
            </div>
          </div>

          <!-- arrow -->
          <div style="display:flex;flex-direction: column; align-items:center">
            <div class="arrow-up"></div>
            <div style="flex:1;width:2px;background-color: var(--v-primary-base);"></div>
            <div class="arrow-down"></div>
          </div>

          <!-- vertical text -->
          <div style="width:30px;text-align: center; xbackground-color: orange;">
            <div class="outer" style="position:relative;display: inline-block;height:100%">
              <div style="text-align: center; position: absolute;top:50%;left:50%;transform:  translateX(-50%) translateY(-50%) rotate(-90deg);white-space: nowrap;"><b>Velo</b></div>
            </div>
          </div>

          <div style="display:flex;flex-direction:row;align-items: stretch;flex:1">
            <div style="xbackground-color: red;position:relative;display:flex;flex-direction: column;align-items: center;width:50px">

              <div style="background-color: var(--v-primary-base);flex:1;width:4px;min-height: 75px;position:relative">

                <div style="position:absolute;left:50%;bottom: 0%;transform: translate(-50%,50%)">
                  <div style="display: flex;">
                  </div>
                  <div class="dot-tiny"></div>
                </div>

                <div style="position:relative;width:100px;height:100%;display:flex;left:35px;">
                  <!-- vertical text -->
                  <div style="width:30px;text-align: center; xbackground-color: orange;">
                    <div class="outer" style="position:relative;display: inline-block;height:100%">
                      <div style="text-align: center; position: absolute;top:50%;left:50%;transform:  translateX(-50%) translateY(-50%) rotate(-90deg);white-space: nowrap;"><b>Tour 1</b></div>
                    </div>
                  </div>

                  <div style="display:flex;flex-direction: column; align-items:center">
                    <div class="arrow-up"></div>
                    <div style="flex:1;width:2px;background-color: var(--v-primary-base);"></div>
                    <div class="arrow-down"></div>
                  </div>

                  <div style="display:flex;xbackground-color:red;align-items: center;flex:0.5;">
                    <div style="text-align:right;flex:1">
                      0:34:45<br/>(1 seh)
                    </div>
                  </div>

                </div>

              </div>

              <!-- <div class="dot dot-small">+</div> -->
              <!-- <div class="dot-tiny" style="position:relative">
                <div style="position:absolute;left:20px;width:220px">
                  <div style="display:flex;align-items: center;">
                    <div class="arrow-left" ></div> <div style="padding-left:6px">Lap 1</div>
                  </div>

                </div>
              </div> -->

              <div style="background-color: var(--v-primary-base);flex:1;width:4px;min-height: 75px;position:relative">

                <div style="position:absolute;left:50%;bottom: 0%;transform: translate(-50%,50%)">
                  <div style="display: flex;">
                  </div>
                  <div class="dot-tiny"></div>
                </div>

                <div style="position:relative;width:100px;height:100%;display:flex;left:35px;">
                  <!-- vertical text -->
                  <div style="width:30px;text-align: center; xbackground-color: orange;">
                    <div class="outer" style="position:relative;display: inline-block;height:100%">
                      <div style="text-align: center; position: absolute;top:50%;left:50%;transform:  translateX(-50%) translateY(-50%) rotate(-90deg);white-space: nowrap;"><b>Tour 2</b></div>
                    </div>
                  </div>

                  <div style="display:flex;flex-direction: column; align-items:center">
                    <div class="arrow-up"></div>
                    <div style="flex:1;width:2px;background-color: var(--v-primary-base);"></div>
                    <div class="arrow-down"></div>
                  </div>

                  <div style="display:flex;xbackground-color:red;align-items: center;flex:0.5;">
                    <div style="text-align:right;flex:1">
                      0:34:45<br/>(1 seh)
                    </div>
                  </div>

                </div>

              </div>

              <div style="position:absolute;left:50%;bottom: 0%;transform: translate(-50%,50%);xbackground-color: orange;">
                <div style="display: flex;">
                </div>
                <div class="dot">Velo</div>
              </div>
            </div>
            <div style="flex:1;position:relative">
            </div>
          </div>
        </div>

      </div>

    </v-container>

    <v-container
      v-if="false"
      class="pt-2 pb-2 results"
      style="max-width: 1150px; xbackground-color: orange"
    >
      <div style="display: flex; xbackground-color: olive;xalign-items: stretch;flex-direction: row;position: relative;">
        <div style="xbackground-color:red;min-width:200px;xflex:1;position: relative;height:200px;z-index: 10;" @click="getWidth($event)">

          <div style="position:absolute;left:0px;top:0%;width:100%;transform: translate: (0,-0%);text-align:center;color:var(--v-textbase-base)">
            <div><b>Swim</b></div>

            <div style="display:flex; align-items:center">
              <div class="arrow-left"></div>
              <div style="flex:1;height:2px;background-color: var(--v-primary-base);"></div>
              <div class="arrow-right"></div>
            </div>

            <div>0:34:45 (1 seh)</div>

          </div>

          <div style="background-color: var(--v-primary-base); position:absolute;margin-top:-2px;height: 4px;left:0px;top:50%;width:100%;transform: translate: (0,-50%);">
          </div>

          <div style="position:absolute;left:0px;top: 50%;transform: translate(-50%,-50%);">
            <div class="dot"><v-icon>mdi-flag</v-icon></div>

            <div class="arrow-box-top" style="position:absolute;width:120px;right:50%;top:55px;transform: translate(50%,0%);padding:8px;text-align: center;">
              <span class="">11:00:00</span>

            </div>

          </div>

          <div style="position:absolute;right:0px;top: 50%;transform: translate(50%,-50%);">
            <div class="dot">Swim</div>

            <div class="arrow-box-top" style="position:absolute;width:120px;right:50%;top:55px;transform: translate(50%,0%);padding:8px;text-align: center;">
              <span class="">11:34:45</span><br/>
              0:34:45<br/>
              (1seh)

            </div>

          </div>

        </div>

        <div style="wbackground-color:yellow;flex:0.5;position: relative;z-index: 9;" @click="getWidth($event)">
          <div style="xbackground-color: bisque;">

          </div>
          <div style="background-color: var(--v-primary-base); position:absolute;margin-top:-2px;height: 4px;left:0px;top:50%;width:100%;transform: translate: (0,-50%);">
          </div>

          <div style="position:absolute;left:0px;top:0%;width:100%;transform: translate: (0,-0%);text-align:center;color:var(--v-textbase-base)">
            <div><b>T1</b></div>

            <div style="display:flex; align-items:center">
              <div class="arrow-left"></div>
              <div
                style="flex:1;height:2px;background-color: var(--v-primary-base)
                ;"
              ></div>
              <div class="arrow-right"></div>
            </div>

            <div>2:20<br/>(1 seh)</div>

          </div>

          <div style="position:absolute;right:0px;top: 50%;transform: translate(50%,-50%);">
            <div class="dot">T1</div>
          </div>

        </div>

        <div style="background-color:white;flex:2;position: relative;z-index: 8;" @click="getWidth($event)">
          <div style="xbackground-color: bisque;">

          </div>

          <!-- <div style="background-color: var(--v-primary-base); position:absolute;margin-top:-2px;height: 4px;left:0px;top:50%;width:100%;transform: translate: (0,-50%);">
            </div> -->
          <div style="position:absolute;margin-top:-2px;xheight: 20px;left:0px;top:50%;width:100%;transform: translate: (0,-50%);display:flex">
            <div style="position: relative;flex:1">
              <div style="height:2px;background-color: var(--v-primary-base);"></div>
              <div style="position:absolute;right:0px;top: 50%;transform: translate(50%,-50%);">
                <div class="dot-tiny"></div>
              </div>
            </div>
            <div style="position: relative;flex:1">
              <div style="height:2px;background-color: var(--v-primary-base);"></div>
              <div style="position:absolute;right:0px;top: 50%;transform: translate(50%,-50%);">
                <div class="dot-tiny"></div>
              </div>
            </div>
            <div style="flex:1;background-color: var(--v-primary-base);">
            </div>
          </div>

          <div style="position:absolute;left:0px;top:0%;width:100%;transform: translate: (0,-0%);text-align:center;color:var(--v-textbase-base)">
            <div><b>Bike</b></div>

            <div style="display:flex; align-items:center">
              <div class="arrow-left"></div>
              <div
                style="flex:1;height:2px;background-color: var(--v-primary-base)
                ;"
              ></div>
              <div class="arrow-right"></div>
            </div>

            <div>3:02:20<br/>(1 seh)</div>

          </div>

          <div style="position:absolute;right:0px;top: 50%;transform: translate(50%,-50%);">
            <div class="dot">Velo</div>
          </div>

        </div>

        <div style="wbackground-color:yellow;flex:0.5;position: relative;z-index: 7;" @click="getWidth($event)">
          <div style="xbackground-color: bisque;">

          </div>
          <div style="background-color: var(--v-primary-base); position:absolute;margin-top:-2px;height: 4px;left:0px;top:50%;width:100%;transform: translate: (0,-50%);">
          </div>

          <div style="position:absolute;left:0px;top:0%;width:100%;transform: translate: (0,-0%);text-align:center;color:var(--v-textbase-base)">
            <div><b>T2</b></div>

            <div style="display:flex; align-items:center">
              <div class="arrow-left"></div>
              <div
                style="flex:1;height:2px;background-color: var(--v-primary-base)
                ;"
              ></div>
              <div class="arrow-right"></div>
            </div>

            <div>4:10<br/>(1 seh)</div>

          </div>

          <div style="position:absolute;right:0px;top: 50%;transform: translate(50%,-50%);">
            <div class="dot">T2</div>
          </div>

        </div>

        <div style="wbackground-color:yellow;flex:1;position: relative;z-index: 6;" @click="getWidth($event)">
          <div style="xbackground-color: bisque;">

          </div>
          <div style="background-color: var(--v-primary-base); position:absolute;margin-top:-2px;height: 4px;left:0px;top:50%;width:100%;transform: translate: (0,-50%);">
          </div>

          <div style="position:absolute;left:0px;top:0%;width:100%;transform: translate: (0,-0%);text-align:center;color:var(--v-textbase-base)">
            <div><b>Run</b></div>

            <div style="display:flex; align-items:center">
              <div class="arrow-left"></div>
              <div
                style="flex:1;height:2px;background-color: var(--v-primary-base)
                ;"
              ></div>
              <div class="arrow-right"></div>
            </div>

            <div>4:10<br/>(1 seh)</div>

          </div>

          <div style="position:absolute;right:0px;top: 50%;transform: translate(50%,-50%);">
            <div class="dot"><v-icon>mdi-flag</v-icon></div>
          </div>

        </div>

      </div>

    </v-container>

    <v-container
      v-if="overview"
      class="pt-2 pb-2 results"
      style="max-width: 1150px; xbackground-color: orange"
    >
      <v-row dense class="pb-0">
        <v-col md="3" sm="4">
          <v-card outlined>
            <span
              style="
                position: absolute;
                width: 4px;
                display: block;
                background: green;
                height: 100%;
              "
            >
            </span>
            <v-card-text>
              <span>{{ $t("CURRENTRANKING") }}</span><br />
              <span>
                <i
                  style="position: absolute; right: 8px"
                  class="fa-solid fa-list-ol fa-xl green--text"
                ></i>
                <span
                  v-if="overview.Status == 'InRace' || overview.Status == 'OK'"
                  class="subtitle-1"
                >{{ overview.Pos > 0 ? overview.Pos : "NA" }}</span>
                <span v-else>-</span>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="overview.Distance > 0" md="3" sm="4">
          <v-card outlined>
            <span
              style="
                position: absolute;
                width: 4px;
                display: block;
                background: green;
                height: 100%;
              "
            >
            </span>
            <v-card-text>
              <span>{{
                $t(overview.Status == "InRace" ? "LASTPOINT" : "FINISHPOINT")
              }}</span><br />
              <span>
                <i
                  style="position: absolute; right: 8px"
                  class="fa-solid fa-flag fa-xl green--text"
                ></i>
                <span
                  class="subtitle-1"
                >{{ formatDistance(overview.Distance) }} /
                  {{ formatTime(overview.Time) }}</span>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-if="overview.Status == 'InRace' && overview.ETA > 0"
          md="3"
          sm="4"
        >
          <v-card outlined>
            <span
              style="
                position: absolute;
                width: 4px;
                display: block;
                background: green;
                height: 100%;
              "
            >
            </span>
            <v-card-text>
              <span>{{ $t("ETA") }}</span><br />
              <span>
                <i
                  style="position: absolute; right: 8px"
                  class="fas fa-stopwatch fa-xl green--text"
                ></i>
                <span class="subtitle-1">{{ formatTime(overview.ETA) }}</span>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container
      class="pb-2 pt-2 results"
      style="max-width: 1150px; xbackground-color: orange"
    >
      <v-card v-if="details && details.overview" class="py-2 px-0">
        <v-card-text>
          <v-row>
            <v-col cols="10">
              <div class="text-h5 font-weight-bold">
                n°{{ overview.Bib }} - {{ overview.DisplayName }}
              </div>
            </v-col>
            <v-col cols="2" class="text-right text-h5">
              <span
                v-if="autoRefresh"
                :title="$t('AUTOREFRESH')"
                class="fas fa-spinner fa-spin"
              ></span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>

    <v-container
      v-if="details && details.PassingTimes"
      class="pt-2 results"
      style="max-width: 1150px; xbackground-color: orange"
    >
      <v-card>
        <v-card-title class="text-h5 font-weight-bold">
          {{ $t("PASSINGS_TITLE") }}
        </v-card-title>
        <v-card-text> </v-card-text>

        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr class="light-blue accent-1">
                <th>{{ $t("CHECKPOINT") }}</th>
                <th colspan="3" class="text-center">Total</th>
                <th colspan="2" class="text-center hidden-sm-and-down">
                  {{ $t("SEGMENT") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="pt in trackModel.TimingPoints" :key="pt.TimingPoint">
                <td>{{ pt.TimingPointDisplayName }}</td>
                <td class="text-right">
                  <span
                    v-if="
                      details.PassingTimes &&
                        details.PassingTimes[pt.TimingPoint.toLowerCase()]
                    "
                  >{{
                    formatTime(
                      details.PassingTimes[pt.TimingPoint.toLowerCase()]
                    )
                  }}</span>
                  <span
                    v-if="
                      overview.Status == 'InRace' &&
                        overview.ETA > 0 &&
                        pt.Distance > overview.Distance &&
                        !details.PassingTimes[pt.TimingPoint.toLowerCase()]
                    "
                    style="color: #cccccc"
                  >
                    <i>({{
                      formatTime(getETA(pt.Distance) + overview.Start)
                    }})</i>
                  </span>
                </td>
                <td class="text-center">{{ formatDistance(pt.Distance) }}</td>
                <td class="text-right">

                  <b
                    v-if="
                      pt.ResultName &&
                        details.SortedSplits[pt.ResultName.toLowerCase()]
                    "
                  >
                    {{
                      formatTime(
                        details.SortedSplits[pt.ResultName.toLowerCase()].Time
                      )
                    }}
                  </b>
                  <span
                    v-if="
                      overview.Status == 'InRace' &&
                        overview.ETA > 0 &&
                        pt.Distance > overview.Distance &&
                        !details.PassingTimes[pt.TimingPoint.toLowerCase()]
                    "
                    style="color: #cccccc"
                  >
                    <i>({{ formatTime(getETA(pt.Distance)) }})</i>
                  </span>
                </td>
                <td class="text-center hidden-sm-and-down">
                  <span v-if="pt.SectionLength > 0">{{
                    formatDistance(pt.SectionLength)
                  }}</span>
                </td>
                <td class="hidden-sm-and-down">
                  <span
                    v-if="
                      pt.SectionResultName &&
                        details.SortedSplits[pt.SectionResultName.toLowerCase()]
                    "
                  >
                    {{
                      formatTime(
                        details.SortedSplits[pt.SectionResultName.toLowerCase()]
                          .Time
                      )
                    }}
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-card-text>
          <div class="text-caption" v-html="$t('PASSINGS_LEGEND')"></div>
        </v-card-text>
      </v-card>
    </v-container>

    <div
      id="mapContainer"
      ref="mapContainer"
      style="width: 100%; height: 75vh;"
    ></div>

    <v-container class="text-center">
      <div class="mt-1">
        <v-btn
          x-large
          class="my-1 mx-sm-1 w-full w-sm-auto primary"
          @click="SelectNode(selection.selected)"
        >{{ $t("BACK") }}</v-btn>
      </div>
    </v-container>
    <!-- <pre>{{data.TableDefinition.Columns}}</pre> -->
  </div>
</template>

<script>
import crTools from '@/services/crTools.js'
import eventService from '@/services/eventService.js'
import genericResultService from '@/services/genericResultService.js'

import appSettingsService from '@/appSettingsService'

import polyline from '@/services/polyline'
import tracktools from '@/services/trackTools'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js'

export default {
  props: ['reportList', 'cms', 'selection', 'path'],

  data() {
    return {
      detailsLoading: false,
      loading: false,
      autoRefresh: false,
      delta: 0,
      raceTime: 0,
      distance: 0,
      trackModel: {
        TimingPoints: []
      },
      details: null,
      highlightedSource: null
    }
  },
  computed: {
    overview: function () {
      if (this.details == null) return null
      if (this.details.overview == null && this.details.overview.length == 0)
        return null

      return this.details.overview[0]
    }
  },

  watch: {
    selection: function (n, o) {
      //console.log("SELECTION CHANGED");
      this.updateAutoRefresh()
    }
  },

  beforeDestroy() {},

  mounted()
  {
    console.log('details mounted')

    mapboxgl.accessToken = appSettingsService.get('mapbox')

    setTimeout(() => {
      //this.buildMap();
      console.log('loadtrackmodel will be called')
      this.loadTrackModel().then(() => {
        console.log('after track model... we\'ll load details')

        this.loadDetails()
      })

    }, 0)

    this.timerInterval = setInterval(() => this.updateRaceTime(), 10000)
    this.updateAutoRefresh()
  },
  beforeDestroy() {
    //console.log("clear interval on detail vue")

    this.stopAutoRefresh()
    clearInterval(this.timerInterval)
  },
  methods: {

    getWidth($event)
    {
      console.log('click ',$event.srcElement.offsetWidth)
    },

    ready() {},

    hasTracks()
    {
      if (!this.trackModel.Tracks || Object.keys(this.trackModel.Tracks).length === 0)
      {
        return false
      }

      return true
    },

    setLocale(lng) {
      this.$i18n.locale = lng
    },

    getETA(distance) {
      if (this.overview == null) return
      if (this.overview.Status != 'InRace') return 0

      const distanceToDo = this.overview.TotalDistance - this.overview.Distance

      if (distanceToDo == 0) return 0

      //Distance=7.5... ov.Distance=5   2.5/35
      const coef = (distance - this.overview.Distance) / distanceToDo

      return (
        (this.overview.ETA - this.overview.Time) * coef + this.overview.Time
      )
    },

    updateRaceTime() {
      if (this.overview == null) return
      if (this.overview.Status != 'InRace') return
      const now = Number(new Date() % 86400000)

      this.raceTime = now + this.delta - this.overview.Start

      if (this.raceTime < this.overview.Time)
        this.raceTime = this.overview.Time
      console.log('RACE TIME ', this.raceTime)

      const distanceToDo = this.overview.TotalDistance - this.overview.Distance

      if (this.overview.ETA == 0 || this.overview.ETA - this.overview.Time == 0)
        return

      const coef =
        (this.raceTime - this.overview.Time) /
        (this.overview.ETA - this.overview.Time)

      this.distance = Math.ceil(distanceToDo * coef + this.overview.Distance)
      if (this.distance > this.overview.TotalDistance)
        this.distance = this.overview.TotalDistance

      this.highlight(this.overview.Distance, this.distance)
      //console.log("DISTANCE TO DO ",distanceToDo,this.overview.ETA-this.overview.Time,coef)
    },

    updateAutoRefresh() {
      if (
        this.selection &&
        this.selection.selected &&
        this.selection.selected.Settings &&
        this.selection.selected.Settings.LiveDelay &&
        this.selection.selected.Settings.LiveDelay > 0
      ) {
        this.startAutoRefresh(this.selection.selected.Settings.LiveDelay)
      } else this.stopAutoRefresh()
    },

    startAutoRefresh(delay) {
      if (this.autoRefresh) return

      //console.log("AUTOREFRESH ENABLED");
      this.interval = setInterval(() => {
        this.loadDetailsInternal(false)
      }, 30000)

      this.autoRefresh = true
    },

    stopAutoRefresh() {
      if (!this.autoRefresh) return

      //console.log("AUTOREFRESH STOPPED");
      clearInterval(this.interval)
      this.autoRefresh = false
    },

    formatTime(input) {
      return crTools.formatTime(input)
    },

    searchPoint(distance) {
      let i = 0

      // skip hidden sections
      while (i < this.trackModel.TimingPoints.length) {
        const tp = this.trackModel.TimingPoints[i]

        if (
          tp.TrackSectionKey != null &&
          distance >= tp.SectionStart &&
          distance <= tp.Distance
        ) {
          //console.log("point in section ",tp)
          const relatedSection = this.trackModel.Sections[tp.TrackSectionKey]

          const pt = tracktools.searchDistanceOnTrack(
            relatedSection.Data,
            distance - tp.SectionStart
          )

          //console.log("related pt: ",pt);
          return pt
        }
        i++
      }

      return null
    },

    updateTrackerSource(to) {
      const pt = this.searchPoint(to)

      //console.log("update tracker source: ", to, pt);

      if (
        this.overview &&
        /*this.overview.Status=='InRace' && */ pt &&
        to > 0
      ) {
        this.trackersSource.features = []
        const toAdd = {
          id: this.overview.Bib,
          type: 'Feature',
          properties: {
            // "name": (i % 4 == 0 ? pt.distance / 1000.0 + "km" : ""),
            description: pt.description,
            icon: 'me',
            name: this.overview.Bib,
            xbearing: pt.bearing
          },
          geometry: {
            type: 'Point',
            coordinates: [pt.lon, pt.lat]
          }
        }

        this.trackersSource.features.push(toAdd)
        this.map.getSource('trackersSource').setData(this.trackersSource)
      }
    },

    highlight(to, interpolate) {
      if (this.highlightedSource == null) return
      this.highlightedSource.features = []

      //console.log("highlight ",to,interpolate)
      this.highlightInternal(0, to, 'green', 'lime')
      if (interpolate && interpolate > to)
        this.highlightInternal(to, interpolate, 'orange', 'orange')

      this.map.getSource('highlightedSource').setData(this.highlightedSource)
      this.updateTrackerSource(
        interpolate && interpolate > to ? interpolate : to
      )
    },

    highlightInternal(from, to, color1, color2) {
      if (to <= from) return

      let i = 0
      const featCnt = this.highlightedSource.features.length
      const sctCount = {}

      // skip hidden sections
      while (i < this.trackModel.TimingPoints.length) {
        const tp = this.trackModel.TimingPoints[i]
        //console.log("highlight ",tp)

        if (tp.TrackSectionKey != null) {
          var relatedSection = this.trackModel.Sections[tp.TrackSectionKey]

          const cut = tracktools.cutTrack(
            relatedSection.Data,
            from - tp.SectionStart,
            to - tp.SectionStart
          )

          if (cut != null) {
            const cutPts = tracktools.trackAsPoints(cut)

            if (sctCount[tp.TrackSectionKey])
              sctCount[tp.TrackSectionKey] = sctCount[tp.TrackSectionKey] + 1
            else sctCount[tp.TrackSectionKey] = 1

            //console.log("related section= ",from-tp.SectionStart,to-tp.SectionStart, relatedSection)
            this.highlightedSource.features.push({
              type: 'Feature',
              properties: {
                color: color1,
                opacity: 1,
                sectionKey: tp.TrackSectionKey,
                instance: sctCount[tp.TrackSectionKey]
              },
              geometry: {
                type: 'LineString',
                coordinates: cutPts
              }
            })
          }
        }
        i++
      }

      i = featCnt
      while (i < this.highlightedSource.features.length) {
        const featProps = this.highlightedSource.features[i].properties

        var relatedSection = this.trackModel.Sections[featProps.sectionKey]

        if (
          featProps.instance ==
          /*sctCount[featProps.sectionKey]*/ relatedSection.Passings
        ) {
          featProps.color = color1
        } else {
          featProps.color = color2
        }
        i++
      }
    },

    SelectNode(node) {
      if (!node) return
      if (node.Route && node.Route != '') {
        const route = JSON.parse(node.Route)
        //console.log("select node route", route, node.Id);

        const params = { ...route.Params, path: node.Id }

        //console.log("route params", params);

        this.$router.push({
          name: route.Route,
          params: params
        })

        return
      } else if (node.Link && node.Link != '') {
        //console.log("LINK CLICKED");
        window.location = node.Link

        return
      } else if (node.Childs == 0) {
        // console.log("leaf node cliqued", node);
        return
      }

      //console.log("SELECT NODE ", node.Id, node.Level, node.Folders.length);
      if (node.Level < 2 && node.Folders.length > 0)
        this.SelectNode(node.Folders[0])
      else {
        //  console.log("LEVEL = ", node.Level);
        // console.log("NODE selection level " + this.level, node);
        setTimeout(() => this.SetPath(node.Id))
      }
    },

    loadDetailsInternal(force) {
      const tokens = this.$route.params.id.split('_')
      let { id } = this.$route.params

      if (tokens.length > 1) id = tokens[0]

      console.log('id in load internal: ',id)

      eventService
        .getDetail(this.$route.params.db, 'detail', 'na', id)
        .then((details) => {
          console.log(details.overview[0])
          details.SortedSplits = {}
          details.PassingTimes = {}

          if (details.overview && details.overview.length > 0) {
            const now = Number(new Date() % 86400000)

            this.delta = details.overview[0].ServerTime - now
            //var raceTime=now+this.delta-details.overview[0].Start;
            //console.log("Now relative server ",this.formatTime(now+delta),this.formatTime(raceTime));
          }

          this.details = details
          for (const k in details.splits) {
            const relatedSplit = details.splits[k]

            details.SortedSplits[relatedSplit.Location] = relatedSplit
            details.PassingTimes[relatedSplit.StartTimingPoint.toLowerCase()] =
              relatedSplit.Start
            details.PassingTimes[relatedSplit.EndTimingPoint.toLowerCase()] =
              relatedSplit.Finish
          }
          this.detailsLoading = false

          if (this.overview.Status == 'OK') {
            console.log('race finished...')
            this.highlight(this.overview.Distance)
          }

          this.updateRaceTime()
        })
    },

    loadDetails(force) {
      if (this.detailsLoading && !force) return

      this.detailsLoading = true
      //console.log("load details")

      this.loadDetailsInternal(force)

      /*this.loadTrackModel().then(() => {
        this.loadDetailsInternal(force);
      });*/
    },

    formatDistance(distance) {
      return crTools.formatDistance(distance)
    },

    buildTrackData() {
      if (!this.trackModel.Tracks || Object.keys(this.trackModel.Tracks).length === 0)
      {
        return
      }
      //  this.map.on("load", () => {
      //console.log("BUILD TRACK DATA")
      const bounds = new mapboxgl.LngLatBounds()

      const trackSource = {
        type: 'FeatureCollection',
        features: []
      }

      this.highlightedSource = {
        type: 'FeatureCollection',
        features: []
      }

      this.trackersSource = {
        type: 'FeatureCollection',
        features: []
      }

      let distanceSource = null

      for (var k in this.trackModel.Sections) {
        const relatedSection = this.trackModel.Sections[k]

        var pts = polyline.decode(relatedSection.PolyLine)
        var tk = tracktools.normalizeTrack(
          tracktools.pointsAsTrack(pts),
          relatedSection.SectionLength
        )
        relatedSection.Data = tk
      }

      for (var k in this.trackModel.Tracks) {
        const relatedTrack = this.trackModel.Tracks[k]

        var pts = polyline.decode(relatedTrack.PolyLine)

        var tk = tracktools.normalizeTrack(
          tracktools.pointsAsTrack(pts),
          relatedTrack.Length
        )
        relatedTrack.Data = tk

        pts.forEach((pt) => {
          bounds.extend(pt)
        })

        this.map.fitBounds(bounds, { duration: 0 })

        //console.log("track model: ",relatedTrack)

        const trackFeature = {
          type: 'Feature',
          properties: {
            color: relatedTrack.Color
          },
          geometry: {
            type: 'LineString',
            coordinates: pts
          }
        }

        trackSource.features.push(trackFeature)

        //var tk = tracktools.pointsAsTrack(pts);
        distanceSource = tracktools.buildDistanceSource(
          this.map,
          tk,
          distanceSource
        )
      }

      this.trackModel.DistanceMarkers.forEach((m) => {
        const toAdd = {
          id: m.Distance,
          type: 'Feature',
          properties: {
            // "name": (i % 4 == 0 ? pt.distance / 1000.0 + "km" : ""),
            name: m.DisplayName,
            bearing: 0,
            icon: 'km'
          },
          geometry: {
            type: 'Point',
            coordinates: [m.Lon, m.Lat]
          }
        }

        distanceSource.features.push(toAdd)
      })
      //trackSource.Distance

      console.log('TRACKSOURCE: ', trackSource)

      this.map.addSource('trackSource', {
        type: 'geojson',
        data: trackSource
      })
      //console.log("track source added");
      this.map.addLayer({
        id: 'track',
        type: 'line',
        source: 'trackSource',
        paint: {
          'line-color': 'white',
          'line-width': 6
        }
      })
      this.map.addLayer({
        id: 'tracksLayer',
        type: 'line',
        source: 'trackSource',

        paint: {
          'line-color': ['get', 'color'],
          'line-dasharray': [3, 1.5],
          'line-width': 2.5
        }
      })

      this.map.addSource('highlightedSource', {
        type: 'geojson',
        data: this.highlightedSource
      })

      this.map.addLayer({
        id: 'highlighted',
        type: 'line',
        source: 'highlightedSource',
        paint: {
          'line-color': ['get', 'color'],
          'line-opacity': ['get', 'opacity'],
          'line-width': 6
        }
      })

      tracktools.addDistanceLayer(this.map, distanceSource)

      this.map.addSource('trackersSource', {
        type: 'geojson',
        data: this.trackersSource
      })

      this.addControls()

      this.map.addLayer({
        id: 'trackersLayer',
        type: 'symbol',
        source: 'trackersSource',
        xminzoom: 14,
        layout: {
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
          'icon-image': ['get', 'icon'],
          'icon-rotate': ['get', 'bearing'],
          'icon-rotation-alignment': 'map',
          'icon-size': 0.5,
          'icon-offset': [0, -48],
          'text-field': ['get', 'name'],
          'text-size': 16,
          'text-font': ['Arial Unicode MS Bold'],
          'text-offset': [0, -2.2],
          'text-anchor': 'top'
        },
        paint: {
          'text-color': '#ffffff'
        }
      })
    },

    loadTrackModel()
    {
      const tokens = this.$route.params.id.split('_')

      //Get(customer,db,discipline,key) {
      let k = this.selection.selected.Settings.Group

      if (!k) k = '1'

      if (tokens.length > 1) k = tokens[1]

      console.log('load track model ',this.selection)

      return genericResultService
        .Get('db', this.$route.params.db, 'tracking', k)
        .then((data) => {
          //console.log("GET TRACKING CONFIG",data);
          this.trackModel = data

          console.log('TRACK MODEL LOADED: ',this.hasTracks())
          if (this.hasTracks())
          {
            this.buildMap()
          }

        })
    },

    addImage(url, name) {

      return new Promise((resolve, reject) => {
        this.map.loadImage(url, (error, image) => {
          if (error) {
            //console.log("LOAD IMAGE ERRROR");
            console.log(error)
            reject(error)
            throw error
          }
          //console.log("AddImage success");
          this.map.addImage(name, image)
          console.log('image ' + url + ' loaded')
          resolve(url)
        })

      })
    },

    buildControlSource: function (hover) {
      const ctrlPointsData = {
        type: 'FeatureCollection',
        features: []
      }

      const i = 0

      //console.log("okok? ",this.trackModel.PhysicalLocations);
      for (const k in this.trackModel.PhysicalLocations) {
        const pt = this.trackModel.PhysicalLocations[k]
        //console.log("physicallocation",pt);
        const toAdd = {
          id: k,
          type: 'Feature',
          properties: {
            name: pt.DisplayName,
            //description: pt.description,
            id: k,
            icon: 'yellow',
            color: 'brown'
          },
          geometry: {
            type: 'Point',
            coordinates: [pt.Lon, pt.Lat]
          }
        }

        ctrlPointsData.features.push(toAdd)

        //console.log("timing pt ",k,toAdd);
      }

      /*      trackSegments.forEach((sgt) => {
        var decoded = polyline.decode(sgt);
        var pt = decoded[0];

        //console.log("LALA? ",decoded[0]);

        let toAdd = {
          id: pt.id,
          type: "Feature",
          properties: {
            name: pt.name,
            description: pt.description,
            id: pt.id,
            icon: i < 3 ? "green" : "yellow",
            color: "brown",
          },
          geometry: {
            type: "Point",
            coordinates: [pt[0], pt[1]],
          },
        };

        i++;
        ctrlPointsData.features.push(toAdd);
      });*/

      /*      this.expedition.expedition.controlPoints.forEach((pt) => {
        let toAdd = {
          id: pt.id,
          type: "Feature",
          properties: {
            name: pt.name,
            description: pt.description,
            id: pt.id,
            icon: hover && pt.id == hover ? "green" : "yellow",
            color: "brown",
          },
          geometry: {
            type: "Point",
            coordinates: [pt.lon, pt.lat],
          },
        };

        ctrlPointsData.features.push(toAdd);

      });*/

      console.log('controls', ctrlPointsData)

      return ctrlPointsData
    },

    addControls: function () {
      const ctrlSource = this.buildControlSource()

      this.map.addSource('ctrlSource', {
        type: 'geojson',
        data: ctrlSource
      })

      this.map.addLayer({
        id: 'ctrls',
        type: 'symbol',
        source: 'ctrlSource',
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-size': 0.3,
          'icon-offset': [0, 0],
          'text-field': ['get', 'name'],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-offset': [0, -2],
          'text-size': 12,
          'text-anchor': 'top'
        }
      })

      console.log('ADD CONTROLS', ctrlSource)

      /*this.map.on('click',e=>{
                    var features=this.map.queryRenderedFeatures(e.point,{layers: [ 'ctrls' ] });
                    if (features.length)
                    {
                        console.log(features);

                        var clickedCtrl=features[0];
                        this.flyTo(clickedCtrl);
                        this.createPopUp(clickedCtrl);
                        console.log(clickedCtrl.properties.id+" => "+clickedCtrl.properties.name);
                    }
                });
                this.map.on('click',e=>{
                    console.log("an other click event");
                });*/

      console.log('controle added to geojson')
    },

    buildSegment(feature, data, color) {
      if (feature == null)
        feature = {
          type: 'FeatureCollection',
          features: []
        }

      feature.features.push({
        type: 'Feature',
        properties: {
          color: color
        },
        geometry: {
          type: 'LineString',
          coordinates: polyline.decode(data)
        }
      })

      return feature
    },

    buildMap() {
      this.map = new mapboxgl.Map({
        container: 'mapContainer',
        style: 'mapbox://styles/chronorace/ckli3u1v60xaj17k3583i2f42',
        //center: [first.lon, first.lat ],
        zoom: 16
        /*xmaxBounds: [
                [minLon-0.25, minLat-0.25],
                [maxLon+0.25, maxLat+0.25],
            ],*/
      })

      //this.map.addControl(new mapboxgl.AttributionControl(), 'top-left');
      this.map.addControl(new mapboxgl.FullscreenControl())
      this.map.addControl(new mapboxgl.NavigationControl())

      this.map.on('load', () => {
        this.map.on('styleimagemissing', (e) => {
          const { id } = e // id of the missing image

          console.log('missing image: ' + id)
        })

        const images = []

        images.push(this.addImage('/markers/check_pending.png', 'yellow'))
        images.push(this.addImage('/markers/check_succeeded.png', 'green'))
        images.push(this.addImage('/markers/checkpending.png', 'pending'))
        images.push(this.addImage('/markers/checkwarning.png', 'warning'))
        images.push(this.addImage('/markers/up.png', 'up'))
        images.push(this.addImage('/markers/km.png', 'km'))
        images.push(this.addImage('/markers/me.png', 'me'))

        Promise.all(images).then((success) => {
          console.log('load images success ',success)

          this.buildTrackData()

          if (this.overview && this.overview.Status == 'OK') {
            console.log('race finished...')
            this.highlight(this.overview.Distance)
          }

        },(error) => {
          console.log('Load images failed',error)
        })

        setTimeout(() => this.loadDetails(),1500)

      })
    }
  }
}
</script>

<style lang="scss">
  .dot {
  height: 50px;
  width: 50px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid var(--v-primary-base);
  display: inline-block;
  display:flex;align-items: center;justify-content: center;
  z-index: 4;
}
.dot-small {
  height: 30px;
  width: 30px;
}

.dot-tiny {
  height: 15px;
  width: 15px;
  background-color: var(--v-primary-base);
  border-radius: 50%;
  border: 2px solid var(--v-primary-base);
  display: inline-block;
  display:flex;align-items: center;justify-content: center;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;

    border-bottom: 10px solid var(--v-primary-base);
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;

  border-left: 7px solid var(--v-primary-base);
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;

  border-right:7px solid var(--v-primary-base);
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;

  border-top: 10px solid var(--v-primary-base);
}

.arrow-box-top {
	position: relative;
	background: white;
	border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}
.arrow-box-top:after, .arrow-box-top:before {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrow-box-top:after {
	border-color: rgba(136, 183, 213, 0);
	border-bottom-color: white;
	border-width: 6px;
	margin-left: -6px;
}
.arrow-box-top:before {
	border-color: rgba(194, 225, 245, 0);
	border-bottom-color: rgba(0, 0, 0, 0.1);
	border-width: 7px;
	margin-left: -7px;
}

.arrow-box-left {
	position: relative;
	background: white;
	border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}
.arrow-box-left:after, .arrow-box-left:before {
	right: 100%;
	top: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrow-box-left:after {
	border-color: rgba(0, 0, 0, 0);;
	border-right-color: white;
	border-width: 6px;
	margin-top: -6px;
}
.arrow-box-left:before {
	border-color: rgba(194, 225, 245, 0);
	border-right-color: rgba(0, 0, 0, 0.1);;
	border-width: 7px;
	margin-top: -7px;
}
</style>
