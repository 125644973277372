const crTools = new (class {

  formatDistance(dst) {
    if (dst<1000) return dst+'m';
    if (dst<100000) return (dst/1000.0)+'km';
    return Math.round(dst/100.0)/10.0+'km';
  }

  formatTimeHMS(millis)
  {
    var sec= ~~(millis/1000);
    var day=~~(sec/86400);
    var h=~~((sec%86400)/3600);
    var m=~~ ((sec%3600)/60);
    var s=sec%60;
    if (day>0) return day+'j'+String(h).padStart(2,'0')+':'+String(m).padStart(2,'0')+':'+String(s).padStart(2,'0');
    if (h>0) return h+':'+String(m).padStart(2,'0')+':'+String(s).padStart(2,'0');
    if (m>0) return m+':'+String(s).padStart(2,'0');
    return s;
  }

  formatTime(input,millis)
  {
    if (!input) {
      return ''
    }

    if (typeof input === 'string') return input
    //if ()

    if (input === 0) return ''

    let toRet = ''

    const ms = (+input) % 1000

    input = (input - ms) / 1000
    let secs = (input % 60) + ''

    input = (input - +secs) / 60
    let mins = (input % 60) + ''
    const hrs = (input - +mins) / 60 + ''

    if (+hrs > 0) {
      if (+mins < 10) {
        mins = '0' + mins
      }
      if (+secs < 10) {
        secs = '0' + secs
      }

      toRet = hrs + ':' + mins + ':' + secs
    }
    else if (+mins > 0) {
      if (+secs < 10) {
        secs = '0' + secs
      }

      toRet = mins + ':' + secs
    }
    else toRet=secs;

    if (millis)
    {
      let strMillis=(ms+1000)+''
      toRet=toRet+'.'+strMillis.substring(1)
    }

    return toRet
  }

  formatDate(strDte)
  {
    var dte=new Date(strDte);
    var dd = String(dte.getDate()).padStart(2, '0');
    var mm = String(dte.getMonth() + 1).padStart(2, '0');
    var yyyy = dte.getFullYear();

    return dd + '/' + mm + '/' + yyyy
  }

  formatDateTimeHHMM(strDte)
  {
    var dte=new Date(strDte);
    var dd = String(dte.getDate()).padStart(2, '0');
    var mm = String(dte.getMonth() + 1).padStart(2, '0');
    var yyyy = String(dte.getFullYear()%100).padStart(2,'0');

    var hh = String(dte.getHours()).padStart(2, '0');
    var MM = String(dte.getMinutes()).padStart(2, '0');


    return dd+'/'+mm+' '+hh+':'+MM;
  }

  constructor() {
  }
})();

export default crTools;
