<template>
  <div id="main" xstyle="background-image: url('/images/gravel.jpg')">
    <v-navigation-drawer app v-model="drawer" fixed temporary v-if="false">
      <!-- -->
     
    </v-navigation-drawer>

    <!-- <v-app-bar app color="primary">
      <span @click="drawer = !drawer">gfgfdgdf</span>
    </v-app-bar> -->
    <Toolbar2 v-if="false" is-fluid />

    <!-- Sizes your content based upon application components -->
    <v-main>
      <EventHeader v-if="!fullScreen" :event-view="eventView"></EventHeader>

      <router-view :event-view="eventView"> </router-view>

      <!-- Provides the application the proper gutter -->
      <!-- <v-container fluid>
        <router-view></router-view>
      </v-container> -->
    </v-main>

    <Footer v-if="false"  />
    <!-- <v-footer app dark>
      hello
    </v-footer> -->
  </div>
</template>

<script>
import eventService from "@/services/eventService.js";
import genericResultService from "@/services/genericResultService.js";
import Toolbar2 from "@/components/ui/landing/toolbar/Toolbar2.vue";
import Footer from "@/components/ui/landing/footer/Footer8Demo.vue";
import EventHeader from "./Components/EventHeader";

export default {
  components: { Toolbar2, Footer, EventHeader },
  data() {
    return {
      drawer: false,
      loading: false,
      cms: [],
      fullScreen: false,
      eventView: null,
    };
  },
  watch: {},
  async beforeRouteUpdate(to, from, next) {
    //console.log("change route to", to.params);
    if (to.params.eventId != this.$route.params.eventId) {
      console.log("EVENT ID UPDATE");
      var eventId = to.params.eventId;
      //console.log("details: ", to);

      eventService.getEventView(eventId).then((eventView) => {
        this.setEventView(eventView);
        next();
      });
    } else next();
  },
  beforeRouteEnter(to, from, next) {
  //  console.log("before route enter: ", to.params);

    var eventId = to.params.eventId;
    //console.log("details: ", to);

    eventService.getEventView(eventId).then((eventView) => {
      //console.log("eventView", eventView);
      next((vm) => {
        // access to component instance via `vm`
        vm.setEventView(eventView);
        //vm.checkRoute();
      });

      // genericResultService.Uci(to.params.db, "dh", "cms").then((cms) => {
      //   //console.log("CMS = ", cms);
      //   var eventId = cms.Settings ? cms.Settings.EventId : null;

      //   if (eventId) {
      //     eventService.getEventView(eventId).then((eventView) => {
      //       //console.log("eventView", eventView);
      //       next((vm) => {
      //         // access to component instance via `vm`
      //         vm.setCMS(cms);
      //         vm.setEventView(eventView);
      //         vm.checkRoute();
      //       });
      //     });
      //   } else {
      //     next((vm) => {
      //       // access to component instance via `vm`
      //       vm.setCMS(cms);
      //       vm.checkRoute();
      //     });
      //   }

      //console.log("EVENT ID=", eventId);
      //vm.setCMS(eventView);
    });

    /*    eventService.getEventView(to.params.eventId).then((eventView) => {
      console.log("eventView", eventView);
      next((vm) => {
        // access to component instance via `vm`
        vm.setEventView(eventView);
      });
    });*/
  },
  computed: {   
  },
  mounted: function () {
    console.log("results  mounted");
  },
  methods: {
    test(db) {
      this.$router.push({ name: "events", params: { db: db } });
    },
    setEventView(eventView) {
      this.eventView = eventView;
      console.log("SET EVENT VIEW", eventView);
    },
  },
};
</script>

<style>
@media only screen and (max-width: 600px) {
  .container.results {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
@media only screen and (max-width: 500px) {
  .container.results {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .compact-bloc {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>

<style scoped>
#main {
  background: no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  min-height: 100vh;
}
</style>
